import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TopBar from '../../components/TopBar';
import FoundPersonByPhotoContainer from './FoundPersonByPhotoContainer';

const SearchPersonByPhoto = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      <TopBar title={t('Поиск по фото')} onBack={handleBack} />
       <FoundPersonByPhotoContainer />
    </>
  );
};

export default SearchPersonByPhoto;
