import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ConfiscatedForm.module.scss';
import InputAutoCompleteMultiple from '../../../components/InputAutoCompleteMultiple/Adapter';
import { useAutocompleteItems } from '../../catalogs/catalogsSlice';

const ConfiscatedForm = () => {
  const [t] = useTranslation();
  const getConfiscatedCategories = useAutocompleteItems('confiscatedCategories');
  return (
    <div className={styles.confiscated}>
      <InputAutoCompleteMultiple
        label={t('Изъятые материалы')}
        name="confiscatedCategoriesId"
        placeholder={t('Выберите материалы')}
        options={getConfiscatedCategories()}
      />
    </div>
  );
};

export default ConfiscatedForm;
