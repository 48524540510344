import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TopBar from '../../components/TopBar';
import MainForm from './MainForm';
import PersonList from './PersonList';
import PersonDialog from './PersonDialog';

const PersonSearch = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <>
      <TopBar title={t('Поиск нарушителя')} onBack={handleClickBack} />
      <MainForm />
      <PersonList />
      <PersonDialog />
    </>
  );
};

export default PersonSearch;
