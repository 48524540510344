import React from 'react';
import cn from 'classnames';

import styles from './index.module.scss';
import { identity } from 'ramda';

const Tabs = ({
  tabs = [],
  activeTab = '',
  onTabClick = identity,
}) => {
  const getTabItemClasses = (key) => cn({
    [styles.tabsItem]: true,
    [styles.tabsActive]: key === activeTab,
  });

  return (
    <div className={styles.tabs}>
      {
        tabs.map((tab) => (
          <div
            key={tab.key}
            className={getTabItemClasses(tab.key)}
            onClick={() => onTabClick(tab.key)}
          >
            {tab.title}
          </div>
        ))
      }
    </div>
  );
};

export default Tabs;
