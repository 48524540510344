import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';
import getError from '../../app/api/getError';
import buildRequestStates from '../../app/buildRequestStates';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';

const initialState = {
  ui: {},
  myProtocols: {
    list: [],
    pagination: {},
  },
};

export const getMyProtocolList = createAsyncThunk(
  'protocolList/getMyProtocolList',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.myProtocols(), { params });

      return response.data;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const protocolListSlice = createSlice({
  name: 'protocolList',
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyProtocolList.fulfilled, (state, { payload: data }) => {
        state.myProtocols.list = data.objects;
        state.myProtocols.pagination = data.pagination;
      });
    buildRequestStates(builder, getMyProtocolList);
  },
});

export const useProtocolList = (params) => {
  const dispatch = useDispatch();

  const getMyProtocols = useDecoratedHandle(async (param) => {
    await dispatch(getMyProtocolList(param)).unwrap();
  });

  const list = useSelector((state) => state.protocolList.myProtocols);
  const requestState = useSelector((state) => state.protocolList.ui[getMyProtocolList.typePrefix]);

  useEffect(() => {
    getMyProtocols(params);
  }, [params.page, params.userId, params.size]);

  return [list, requestState === 'pending'];
};

export default protocolListSlice.reducer;
