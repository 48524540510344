import React from 'react';
// import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { identity, uniq } from 'ramda';
import { format } from 'date-fns';
import styles from './ValidationErrorDialog.module.scss';
import Dialog from '../../../components/Dialog';
import { closeValidationErrorDialog, useGetErrorName, useValidationErrorDialogState } from '../catalogsSlice';
import { ReactComponent as WarningIcon } from '../../../icons/warning.svg';
import { useGetMe } from '../../authData/authDataSlice';
import Button from '../../../components/Button';

const ValidationErrorDialog = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const getErrorName = useGetErrorName();
  const { open, messages } = useValidationErrorDialogState();
  // const open = false;
  // const messages = [];
  const [user] = useGetMe();

  const handleClose = () => {
    dispatch(closeValidationErrorDialog());
  };

  const date = new Date();

  return (
    <Dialog
      open={open}
      title={t('Исправьте ошибки в полях')}
      onClose={handleClose}
    >
      <div className={styles.validationErrors}>
        <div className={styles.validationErrorsIcon}>
          <WarningIcon />
        </div>
        <div className={styles.validationErrorsBody}>
          <div className={styles.validationErrorDate}>
            {format(date, 'dd.MM.yyyy (HH:mm)')}
          </div>
          <div className={styles.validationErrorUser}>
            {user.username}
          </div>
          <ul className={styles.errorList}>
            {
              uniq(messages)
                .map((message) => (
                  <li className={styles.errorListItem} key={message}>
                    {getErrorName(message)}
                    <br />
                    <span className={styles.errorListSub}>
                      ({message})
                    </span>
                  </li>
                ))
            }
          </ul>
        </div>
        <div className={styles.validationErrorsFooter}>
          <div className={styles.validationErrorsFooterItem}>
            <Button
              type="button"
              variant="purple"
              fullWidth
              size="medium"
              onClick={handleClose}
            >
              {t('Закрыть')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ValidationErrorDialog;
