import { useMemo } from 'react';
import { find, includes } from 'ramda';

export default () => {
  const chipTypes = useMemo(() => ([
    {
      check: (type) => includes(type, ['REGISTERED', 'SENT_TO_ORGAN', 'SENT_TO_COURT', 'SEND_TO_MIB']),
      color: 'primary',
    },
    {
      check: (type) => includes(type, ['IN_EXECUTION_PROCESS', 'CONSIDERING', 'DECISION_MADE']),
      color: 'warning',
    },
    {
      check: (type) => includes(type, ['ANNULLED', 'CANCELLED', 'MERGED']),
      color: 'danger',
    },
    {
      check: (type) => includes(type, ['RETURN_FROM_ORGAN', 'RETURN_FROM_COURT']),
      color: 'info',
    },
    {
      check: (type) => includes(type, ['EXECUTED']),
      color: 'success',
    },
  ]), []);

  return (alias) => {
    const { color } = find(({ check }) => check(alias), chipTypes) ?? {};

    if (!color) return 'primary';
    return color;
  };
};
