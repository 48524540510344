import {
  compose, createAsyncThunk, createSelector, createSlice,
} from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  find, identity, isEmpty, isNil, prop, propEq, sort, sortBy, toLower,
} from 'ramda';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';
import buildRequestStates from '../../app/buildRequestStates';
import { useCatalogsName } from '../catalogs/catalogsSlice';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';

const initialState = {
  ui: {},
  lists: {},
};

export const getRegisteredArticleTypeViolations = createAsyncThunk(
  'catalogs/getRegisteredArticleTypeViolations',
  async () => {
    const response = await httpClient.get(routes.articleTypeViolationTypes(), { allPages: true });
    return response.data.objects;
  },
);

const dependentCatalogs = createSlice({
  name: 'dependentCatalogs',
  initialState,
  reducers: {
    setAutocompleteRegisterArticlePartViolations: (state, { payload: data }) => {
      state.lists.autocompletedRegisteredArticlePartViolations = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRegisteredArticleTypeViolations.fulfilled, (state, { payload: objects }) => {
        state.lists.registeredArticlePartViolations = objects;
      });
    buildRequestStates(builder, getRegisteredArticleTypeViolations);
  },
});

export const {
  setAutocompleteRegisterArticlePartViolations,
} = dependentCatalogs.actions;

export const useRegisteredArticleTypeViolationsState = () => useSelector((state) => state.dependentCatalogs.ui[getRegisteredArticleTypeViolations.typePrefix]);

const registeredArticleViolationTypeSelector = createSelector(
  (state) => state.dependentCatalogs.lists.registeredArticlePartViolations,
  (state) => state.dependentCatalogs.lists.autocompletedRegisteredArticlePartViolations,
  (_, getArticlePartName) => getArticlePartName,
  (_, __, getViolationTypeName) => getViolationTypeName,
  (articlePartsViolations, autocompletedRegisteredArticlePartViolations, getArticlePartName, getViolationTypeName) => {
    if (isEmpty(articlePartsViolations) || isNil(articlePartsViolations)) return [];

    if (autocompletedRegisteredArticlePartViolations) return autocompletedRegisteredArticlePartViolations;
    const sortArticlePartAsc = sortBy(compose(parseInt, (catalog) => getArticlePartName(catalog?.articlePartId)) || '');
    return sortArticlePartAsc(articlePartsViolations).map((catalog) => ({
      id: catalog.id,
      label: [getArticlePartName(catalog.articlePartId), getViolationTypeName(catalog.violationTypeId)].filter(identity).join(' - '),
    }));
  },
);

export const useRegisteredArticlePartViolationsToAutocomplete = () => {
  const dispatch = useDispatch();
  const getArticlePartName = useCatalogsName('articleParts');
  const getViolationTypeName = useCatalogsName('violationTypes');
  const articlePartsViolations = useSelector(
    (state) => registeredArticleViolationTypeSelector(state, getArticlePartName, getViolationTypeName),
  );
  const registeredArticleViolationTypesState = useRegisteredArticleTypeViolationsState();

  const getRegisteredArticleTypeViolationsDecorated = useDecoratedHandle(async () => {
    await dispatch(getRegisteredArticleTypeViolations()).unwrap();
  });

  useEffect(() => {
    if (registeredArticleViolationTypesState === 'pending' || registeredArticleViolationTypesState === 'fulfilled') {
      return;
    }
    getRegisteredArticleTypeViolationsDecorated();
  }, [registeredArticleViolationTypesState]);

  useEffect(() => {
    if (registeredArticleViolationTypesState === 'fulfilled') {
      dispatch(setAutocompleteRegisterArticlePartViolations(articlePartsViolations));
    }
  }, [registeredArticleViolationTypesState]);

  return articlePartsViolations;
};

export const useRegisteredArticlePartViolationsSplitById = () => {
  const catalog = useSelector((state) => state.dependentCatalogs.lists?.registeredArticlePartViolations);

  return (id) => {
    if (isEmpty(catalog) || isNil(catalog)) return [];
    const item = find(propEq('id', id))(catalog);
    if (!item) return [];

    return [item.articlePartId, item.violationTypeId];
  };
};

export default dependentCatalogs.reducer;
