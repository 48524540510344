import React from 'react';
import PunishmentDialog from './features/punishments/PunishmentDialog';
import ValidationErrorDialog from './features/catalogs/ValidationErrorDialog/ValidationErrorDialog';

const DialogsWrapper = ({ children }) => (
    <>
      {children}
      <PunishmentDialog />
      <ValidationErrorDialog />
    </>
);

export default DialogsWrapper;
