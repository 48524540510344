import React from 'react';
// import cn from 'classnames';

import styles from './ConfirmDialog.module.scss';
import Dialog from '../Dialog';
import useToggler from '../../hooks/useToggler';
import { ReactComponent as WarningIcon } from '../../icons/warning.svg';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Portal from '../Portal';

const ConfirmDialog = ({
  renderButton,
  confirmText = 'Подтвердите действие',
  onConfirm,
  loading,
}) => {
  const [t] = useTranslation();
  const [open, handleOpen, handleClose] = useToggler();

  const handleConfirm = async () => {
    if (onConfirm) {
      await onConfirm();
    }
    handleClose();
  };

  return renderButton && (
    <>
      {renderButton(handleOpen)}
      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          title={t('Требуется подтверждение')}
        >
          <div className={styles.confirm}>
            <div className={styles.confirmIcon}>
              <WarningIcon />
            </div>
            <div className={styles.confirmTitle}>
              {confirmText}
            </div>
            <div className={styles.confirmFooter}>
              <div className={styles.confirmReject}>
                <Button
                  type="button"
                  variant="grey"
                  size="medium"
                  disabled={loading}
                  fullWidth
                >
                  {t('Отмена')}
                </Button>
              </div>
              <div className={styles.confirmSubmit}>
                <Button
                  type="button"
                  variant="purple"
                  size="medium"
                  onClick={handleConfirm}
                  loading={loading}
                  fullWidth
                >
                  {t('Подтвердить')}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      </Portal>
    </>
  );
};

export default ConfirmDialog;
