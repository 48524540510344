import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import getError from '../../app/api/getError';
import { sleep } from '../../utils';
import buildRequestStates from '../../app/buildRequestStates';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { getTechPassport, useTechPassportState, useTransportData } from '../transport/transportSlice';

const initialState = {
  ui: {},
  info: [],
};

export const getWantedInfo = createAsyncThunk(
  'transport/getWantedInfo',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.wantedInfo(), { params });
      return response.data.objects;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const wantedSlice = createSlice({
  name: 'wanted',
  initialState,
  reducers: {
    resetWanted: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWantedInfo.fulfilled, (state, { payload: data }) => {
        state.info = data;
      });
    buildRequestStates(builder, getWantedInfo);
  },
});

export const useWantedState = () => useSelector(
  (state) => state.wanted.ui[getWantedInfo.typePrefix],
);

export const useWantedInfo = () => useSelector((state) => state.wanted.info);

export const {
  resetWanted,
} = wantedSlice.actions;

export default wantedSlice.reducer;
