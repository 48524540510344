import React from 'react';
// import cn from 'classnames';
import { Form } from 'react-final-form';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import styles from './DialogAttorneyCreate.module.scss';
import Dialog from '../../../components/Dialog';
import { ReactComponent as TaskSmIcon } from '../../../icons/task-sm.svg';
import Button from '../../../components/Button';
import useToggler from '../../../hooks/useToggler';
import Portal from '../../../components/Portal';
import InputText from '../../../components/InputText';
import PlainInputText from '../../../components/InputPlainText';
import InputDate from '../../../components/InputDate';
import Text from '../../../components/Text';
import { createHandyAttorney } from '../attorneysSlice';
import useDecoratedHandle from '../../../hooks/useDecoratedHandle';
import { useSelectedTexPass } from '../../transport/transportSlice';

const DialogAttorneyCreate = () => {
  const [t] = useTranslation();
  const [open, handleOpen, handleClose] = useToggler();
  const dispatch = useDispatch();
  const techPassport = useSelectedTexPass();

  const handleSubmit = useDecoratedHandle((values) => {
    const normalizedValues = {
      ...values,
      id: nanoid(),
      members: [{
        ...values.members,
        memberTypeId: 4,
      }],
      subjects: [{
        vehicleNumber: techPassport.vehicleNumber,
        texPassSeries: techPassport.texPassSeries,
        texPassNumber: techPassport.texPassNumber,
      }],
    };
    dispatch(createHandyAttorney(normalizedValues));
    handleClose();
  });

  const validate = () => ({});

  return (
    <>
      <Button
        type="button"
        variant="lightBlue"
        size="small"
        prependIcon={<TaskSmIcon />}
        style={{ borderRadius: 3 }}
        onClick={handleOpen}
        disabled={!techPassport?.vehicleNumber}
      >
        {t('Добавить доверенность')}
      </Button>
      <Portal>
        <Dialog
          open={open}
          title={t('Добавить доверенность')}
          onClose={handleClose}
        >
          <Form
            onSubmit={handleSubmit}
            validate={validate}
          >
            {({ handleSubmit: submit }) => (
              <form onSubmit={submit} className={styles.attorneyDialogForm}>
                <InputText name="members.lastName" label={t('Фамилия')} placeholder={t('Фамилия')} />
                <InputText name="members.firstName" label={t('Имя')} placeholder={t('Имя')} />
                <InputText name="members.secondName" label={t('Отчество')} placeholder={t('Отчество')} />
                <div className={styles.attorneyDialogInputContainer}>
                  <div className={styles.attorneyDialogSerial}>
                    <Text type="subtitle2">{t('Номер доверенности')}</Text>
                    <PlainInputText name="number" placeholder={t('Номер')} />
                  </div>
                  <div className={styles.attorneyDialogSerial}>
                    <InputDate name="fromDate" label={t('Дата выдачи')} placeholder={t('Дата')} />
                  </div>
                  <div className={styles.attorneyDialogSerial}>
                    <InputDate name="toDate" label={t('Действителен до')} placeholder={t('Дата')} />
                  </div>
                </div>
                <div className={styles.attorneyDialogFooter}>
                  <div className={styles.attorneyDialogCancel}>
                    <Button
                      type="reset"
                      variant="grey"
                      size="medium"
                      onClick={handleClose}
                      fullWidth
                    >
                      {t('Отмена')}
                    </Button>
                  </div>
                  <div className={styles.attorneyDialogSubmit}>
                    <Button
                      type="submit"
                      variant="purple"
                      size="medium"
                      fullWidth
                    >
                      {t('Добавить')}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Form>
        </Dialog>
      </Portal>
    </>
  );
};

export default DialogAttorneyCreate;
