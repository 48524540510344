import React from 'react';

import { Field } from 'react-final-form';
import PlainTextInput from './PlainTextInput';

const Adapter = ({ name, parse, ...rest }) => (
    <Field name={name} parse={parse}>
      {({ input, meta }) => (
        <PlainTextInput
          error={meta.touched && meta.error}
          onChange={input.onChange}
          value={input.value}
          {...rest}
        />
      )}
    </Field>
);

export default Adapter;
