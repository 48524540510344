import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import getError from '../../app/api/getError';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';
import buildRequestStates from '../../app/buildRequestStates';

const initialState = {
  ui: {},
  gps: {},
};

export const getLocationAddress = createAsyncThunk(
  'services/getLocationAddress',
  async ({ lat, long }, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.gps(), {
        params: {
          latitude: lat,
          longitude: long,
        },
      });
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocationAddress.fulfilled, (state, { payload: data }) => {
        state.gps = data;
      });
    buildRequestStates(builder, getLocationAddress);
  },
});

export const useGPSData = () => useSelector((state) => state.services.gps);

export const useGPSState = () => useSelector((state) => state.services.ui[getLocationAddress.typePrefix]);

export default servicesSlice.reducer;
