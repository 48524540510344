import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-app-polyfill/stable';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tiny-fab/dist/styles.css';
import './index.css';
import './index.scss';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import './localizations/i18n';

const persistor = persistStore(store);

// persistor.purge();

const root = createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
  // </React.StrictMode>,
);

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
