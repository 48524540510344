import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import getError from '../../app/api/getError';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';
import buildRequestStates from '../../app/buildRequestStates';

const initialState = {
  ui: {
    dialogOpen: false,
  },
  list: [],
  errorByPlateNumber: {},
  errorByPinpp: {},
  errorByRadar: {},
  radarPunishments: [],
  queryType: '',
};

export const getPunishmentByPlateNumber = createAsyncThunk(
  'punishments/getPunishmentByPlateNumber',
  async (plateNumber, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.getPunishmentsByPlateNumber(plateNumber), { allPages: true });
      return response.data.objects;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const getPunishmentByPinpp = createAsyncThunk(
  'punishments/getPunishmentByPinpp',
  async (pinpp, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.getPunishmentsByPinpp(pinpp), { allPages: true });
      return response.data.objects;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const getRadarPunishments = createAsyncThunk(
  'punishments/getRadarPunishment',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.getRadarPunishments(), { params, allPages: true });
      return response.data.objects;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const punishmentSlice = createSlice({
  name: 'punishments',
  initialState,
  reducers: {
    openPunishmentDialog: (state) => {
      state.ui.dialogOpen = true;
    },
    closePunishmentDialog: (state) => {
      state.ui.dialogOpen = false;
    },
    clearPunishmentErrorByPlateNumber: (state) => {
      state.errorByPlateNumber = {};
    },
    clearPunishmentErrorByPinpp: (state) => {
      state.errorByPinpp = {};
    },
    clearPunishmentErrorByRadar: (state) => {
      state.errorByRadar = {};
    },
    setPunishmentQueryType: (state, { payload: data }) => {
      state.queryType = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPunishmentByPlateNumber.fulfilled, (state, { payload: data }) => {
        state.list = data;
      })
      .addCase(getPunishmentByPlateNumber.rejected, (state, { payload: data }) => {
        state.errorByPlateNumber = data;
      })
      .addCase(getPunishmentByPinpp.fulfilled, (state, { payload: data }) => {
        state.list = data;
      })
      .addCase(getPunishmentByPinpp.rejected, (state, { payload: data }) => {
        state.errorByPinpp = data;
      })
      .addCase(getRadarPunishments.fulfilled, (state, { payload: data }) => {
        state.radarPunishments = data;
      })
      .addCase(getRadarPunishments.rejected, (state, { payload: data }) => {
        state.errorByRadar = data;
      });
    buildRequestStates(builder, getPunishmentByPlateNumber);
    buildRequestStates(builder, getPunishmentByPinpp);
    buildRequestStates(builder, getRadarPunishments);
  },
});

export const {
  openPunishmentDialog,
  closePunishmentDialog,
  clearPunishmentErrorByPlateNumber,
  clearPunishmentErrorByPinpp,
  clearPunishmentErrorByRadar,
  setPunishmentQueryType,
} = punishmentSlice.actions;

export const usePunishmentDialogState = () => useSelector((state) => state.punishments.ui.dialogOpen);

export const usePunishments = () => useSelector((state) => state.punishments.list);
export const useRadarPunishments = () => useSelector((state) => state.punishments.radarPunishments);

export const usePunishmentQueryType = () => useSelector((state) => state.punishments.queryType);

export const useErrorPunishmentByPlateNumber = () => useSelector((state) => state.punishments.errorByPlateNumber);
export const useErrorPunishmentByPinpp = () => useSelector((state) => state.punishments.errorByPinpp);
export const useErrorPunishmentByRadar = () => useSelector((state) => state.punishments.errorByRadar);

export const usePunishmentsState = () => useSelector((state) => state.punishments.ui[getPunishmentByPlateNumber.typePrefix]);
export const usePunishmentsByPinppState = () => useSelector((state) => state.punishments.ui[getPunishmentByPinpp.typePrefix]);
export const useRadarPunishmentsState = () => useSelector((state) => state.punishments.ui[getRadarPunishments.typePrefix]);

export default punishmentSlice.reducer;
