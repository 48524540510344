import { isBefore } from 'date-fns';
import i18n from './localizations/i18n';

export const composeValidators = (...validators) => (
  (value, allValues) => validators.reduce(
    (error, validator) => error || validator(value, allValues),
    undefined,
  )
);

export const validateIf = (bool) => (validate) => (bool ? validate : () => {});

export const required = (value) => (value ? undefined : i18n.t('Пожалуйста, заполните это поле'));

export const dateGreaterThan1900 = (value) => {
  if (!value) return undefined;
  const minimal = new Date('01.01.1900');
  const checkDate = new Date(value);
  return isBefore(checkDate, minimal) ? i18n.t('Нельзя выбрать дату меньше 01-01-1900') : undefined;
};

export const dateLessThanNow = (value) => {
  if (!value) return undefined;
  const checkDate = new Date(value);
  const now = new Date();
  return isBefore(checkDate, now) ? undefined : i18n.t('Нельзя выбрать дату больше текущей');
};
