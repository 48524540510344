import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { find, propEq } from 'ramda';
import getError from '../../app/api/getError';
import { sleep } from '../../utils';
import buildRequestStates from '../../app/buildRequestStates';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';

const initialState = {
  ui: {},
  list: [],
  selectedAttorney: null,
};

export const getAttorneys = createAsyncThunk(
  'attorneys/getAttorneys',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.attorneys(), { params });
      return response.data.objects;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const attorneysSlice = createSlice({
  name: 'attorneys',
  initialState,
  reducers: {
    selectAttorney: (state, { payload: attorney }) => {
      state.selectedAttorney = attorney;
    },
    resetSelectedAttorney: (state) => {
      state.selectedAttorney = null;
    },
    createHandyAttorney: (state, { payload: data }) => {
      state.list.push(data);
    },
    resetAttorneys: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttorneys.fulfilled, (state, { payload: data }) => {
        state.list = data.map((attorney) => ({ ...attorney, id: nanoid() }));
      });
    buildRequestStates(builder, getAttorneys);
  },
});

export const useAttorneysState = () => useSelector(
  (state) => state.attorneys.ui[getAttorneys.typePrefix],
);

export const useAttorneys = () => useSelector((state) => state.attorneys.list);

export const useSelectedAttorney = () => useSelector((state) => state.attorneys.selectedAttorney);

export const {
  resetAttorneys,
  selectAttorney,
  createHandyAttorney,
  resetSelectedAttorney,
} = attorneysSlice.actions;

export default attorneysSlice.reducer;
