import React from 'react';
import NumberFormat from 'react-number-format';
import TextInput from './TextInput';
import { Field } from 'react-final-form';

const NumberFormatAdapter = ({
  inputFormat, name, ...rest
}) => (
  <Field name={name}>
    {({ input, meta }) => (
      <NumberFormat
        {...input}
        {...rest}
        format={inputFormat}
        mask="_"
        customInput={TextInput}
        error={meta.touched && meta.error}
      />
    )}
  </Field>
);

export default NumberFormatAdapter;
