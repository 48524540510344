import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openValidationErrorDialog, useGetErrorName } from '../features/catalogs/catalogsSlice';
import { formatDate } from '../utils';
import { hideLoader, showLoader } from '../features/loaderSlice';

const useDecoratedHandle = (cb, shouldShowLoader = false) => {
  const dispatch = useDispatch();
  const getErrorName = useGetErrorName();
  const [t] = useTranslation();
  const user = useSelector((state) => state.authData.user);

  return async (...values) => {
    if (shouldShowLoader) {
      dispatch(showLoader({ label: t('Подождите') }));
    }
    try {
      const result = await cb(...values);
      if (shouldShowLoader) {
        dispatch(hideLoader());
      }
      return result;
    } catch (e) {
      if (shouldShowLoader) {
        dispatch(hideLoader());
      }
      if (typeof e === 'string') {
        console.log(e);
        toast.error(e);
        return null;
      }
      if (!e) {
        toast.error(t('Что-то непредвиденное'));
        return null;
      }
      if (e.status && e.status === 500) {
        toast.error(t('Нет связи с сервером (Ошибка 500)'));
        return null;
      }
      if (!e.data) {
        console.log(e);
        toast.error(e.message);
        return null;
      }
      if (e.data.validationCodes) {
        dispatch(openValidationErrorDialog(e.data.validationCodes));
        console.log(e);
        return null;
      }
      console.log(e);
      toast.error(`${getErrorName(e.data.code)} (${e.data.code}) (${formatDate(e.data.timestamp, true)}) (${user.id})`);
      return null;
    }
  };
};

export default useDecoratedHandle;
