import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
// import { Fab, Action } from 'react-tiny-fab';
// import { FiSettings } from 'react-icons/fi';
import views, { appRoutes } from './views';
import { initialize, useActiveLang } from './features/app/appSlice';
import RequireAuth from './components/RequireAuth';
import {
  getMessages,
  useCatalogsState,
  useGetMessagesState,
  useIsCatalogsAlreadyFetched,
} from './features/catalogs/catalogsSlice';
import CatalogsWrapper from './features/catalogs/CatalogsWrapper';
import DialogsWrapper from './DialogsWrapper';
import ECPPasswordDialog from './components/ECPPasswordDialog';
import LoaderContainer from './components/LoaderContainer';
import { hideLoader, showLoader } from './features/loaderSlice';
import { removeMemoFileUrl } from './features/files/filesSlice';
import { removeUserData } from './features/authData/authDataSlice';

function App() {
  const dispatch = useDispatch();
  const isCatalogsAlreadyFetched = useIsCatalogsAlreadyFetched();
  const getMessagesState = useGetMessagesState();
  const catalogsState = useCatalogsState();
  const lang = useActiveLang();
  const [t, i18n] = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    const getMessage = async () => {
      dispatch(showLoader({ label: t('Загрузка справочников') }));
      await dispatch(initialize({ routes: appRoutes }));
      await dispatch(getMessages());
      dispatch(hideLoader());
    };
    getMessage();
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          {views.map(({ View, pathname }) => (
            <Route
              key={pathname}
              path={pathname}
              element={
                pathname === '/'
                  ? <View />
                  : (
                    <RequireAuth>
                      <CatalogsWrapper>
                        <DialogsWrapper>
                          <View />
                          <ECPPasswordDialog />
                        </DialogsWrapper>
                      </CatalogsWrapper>
                    </RequireAuth>
                  )
              }
            />
          ))}
        </Routes>
      </Router>
       <LoaderContainer />
      <ToastContainer
        position="top-center"
        autoClose={9000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        limit={3}
        progress={undefined}
        theme="colored"
      />
    </div>
  );
}

export default App;
