import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UsernameIcon } from '../../icons/username.svg';
import { ReactComponent as LockIcon } from '../../icons/lock.svg';

import styles from './InputUsername.module.scss';
import RFFValidationError from '../RFFValidationError';

const InputUsername = ({ disabled }) => {
  const [t] = useTranslation();
  const options = useMemo(() => [
    { id: 'AN', label: 'AN' },
    { id: 'BX', label: 'BX' },
    { id: 'FA', label: 'FA' },
    { id: 'JZ', label: 'JZ' },
    { id: 'NA', label: 'NA' },
    { id: 'NV', label: 'NV' },
    { id: 'QD', label: 'QD' },
    { id: 'QR', label: 'QR' },
    { id: 'SI', label: 'SI' },
    { id: 'SN', label: 'SN' },
    { id: 'TN', label: 'TN' },
    { id: 'TV', label: 'TV' },
    { id: 'XZ', label: 'XZ' },
    { id: 'SD', label: 'SD' },
  ], []);

  return (
    <>
      <div className={styles.label}>{t('Логин')}</div>
      <div className={styles.usernameWrapper}>
        <div className={styles.icon}>
          <UsernameIcon />
        </div>
        <Field name="workCertificateSn">
          {({ input, meta }) => (
            <select
              value={input.value}
              onChange={input.onChange}
              className={styles.input}
              disabled={disabled}
            >
              {options.map((opt) => (
                <option
                  value={opt.id}
                  key={opt.id}
                >
                  {opt.label}
                </option>
              ))}
            </select>
          )}
        </Field>
        <Field name="workCertificateNo">
          {({ input, meta }) => (
            <input
              type="text"
              className={styles.input}
              onChange={input.onChange} value={input.value}
              placeholder={t('Номер')}
              disabled={disabled}
            />
          )}
        </Field>
      </div>
      <RFFValidationError name="workCertificateNo" />
      <div className={styles.label}>{t('Пароль')}</div>
      <div className={styles.passwordWrapper}>
        <div className={styles.icon}>
          <LockIcon />
        </div>
        <Field name="password">
          {({ input, meta }) => (
            <input
              type="password"
              className={styles.inputPassword}
              onChange={input.onChange}
              value={input.value}
              placeholder={t('Пароль')}
              disabled={disabled}
            />
          )}
        </Field>
      </div>
      <RFFValidationError name="password" />
    </>
  );
};

export default InputUsername;
