export const outerServiceRoutes = {
  faceId: () =>
    ['http://faceid.emi.mvd/api/face-identification/match-v1'].join('/'),
}

export default {
  /*  Auth Data  */
  auth: () => ['/auth/login/gai'].join('/'),
  authByUsername: () => ['/auth/login'].join('/'),
  getMe: () => ['/v0/api/accounts/get-me'].join('/'),

  /* ECP */
  myECPKey: () => ['/v0/api/accounts/certificates'].join('/'),
  myECPPrivateKey: () =>
    ['/v0/api/accounts/certificates/private-key'].join('/'),

  /* Protocol List */
  myProtocols: () => ['/v0/api/protocols/global-search'].join('/'),

  /* Decision List */
  myDecisions: () => ['/v0/api/decisions/global-search'].join('/'),

  /* PDFs */
  protocolPDF: (id) => ['/v0/api/pdf/protocol', id].join('/'),
  decisionPDF: (id) => ['/v0/api/pdf/decision', id].join('/'),

  /* Services */
  // form1: () => ['/v0/gcp-api/f1/search'].join('/'),
  form1: () => ['/v0/api/f1/search'].join('/'),
  manzil: (pinpp) =>
    ['/v0/manzil-api/person', pinpp, 'registrations'].join('/'),
  gps: () => ['/v0/gps-api/address'].join('/'),
  searchPersonByIdChip: () => ['/v0/api/tex-pass/person-by-chip'].join('/'),
  searchOnePersonByIdChip: () =>
    ['v0/api/tex-pass/one-person-by-chip'].join('/'),
  searchTexPassByChip: () => ['/v0/ubdd-data-api/texpass/by-chip'].join('/'),
  searchPersonByDriverLicenseChip: () =>
    ['v0/ubdd-data-api/driving-license/by-chip'].join('/'),
  insurance: () => ['/v0/insurance-api/policies/search-lite'].join('/'),
  vehicleInspection: () => ['/v0/vehicle-inspections-api/search'].join('/'),
  driverLicense: () => ['/v0/ubdd-data-api/driving-license/search'].join('/'),
  tinting: () => ['/v0/ubdd-data-api/tinting/search'].join('/'),
  taxiLicense: () => ['/v0/taxi-license-api/license/search'].join('/'),
  texpass: () => ['/v0/ubdd-data-api/texpass/search'].join('/'),
  attorneys: () => ['/v0/attornies-api/search'].join('/'),
  wantedInfo: () => ['/v0/vehicle-wanted-api/search/wanted-cards'].join('/'),
  wantedInfoForProtocol: () =>
    ['/v0/vehicle-wanted-api/search/wanted-cards-for-protocol/'].join('/'),
  wantedPersonInfo: () => ['/v0/person-wanted-api/search'].join('/'),
  createArrest: () => ['/v0/vehicle-wanted-api/arrests/'].join('/'),
  /* Files */
  saveFile: () => ['/v0/api/files'].join('/'),

  /* ADM */
  createMainProtocol: () => ['/v0/api/adm-cases/protocol'].join('/'),
  createMainRaidProtocol: () => ['/v0/api/adm-cases/raid-protocol'].join('/'),
  createTexPass: () => ['/v0/api/ubdd-data/texpass'].join('/'),
  createTinting: () => ['/v0/api/ubdd-data/tinting'].join('/'),
  createVehicleInspection: () =>
    ['/v0/api/ubdd-data/vehicle-inspection'].join('/'),
  createInsurance: () => ['/v0/api/ubdd-data/insurance'].join('/'),
  createDocument: () => ['/v0/api/documents'].join('/'),
  createDriverLicense: () => ['/v0/api/ubdd-data/driving-license'].join('/'),
  createAttorney: () => ['/v0/api/ubdd-data/attorney-letter'].join('/'),
  setGaiDataService: () => ['/v0/api/ubdd-data/bind-to-protocol'].join('/'),
  calculateCaseMovement: (id) =>
    ['/v0/api/calculating-movements/calculate-ubdd-tablet', id].join('/'),
  createSimplifiedResolution: () =>
    ['/v0/api/resolutions/simplified'].join('/'),
  /* Punishments */
  getPunishmentsByPlateNumber: (number) =>
    ['/v0/api/gai-punishments/by-car', number].join('/'),
  getPunishmentsByPinpp: (pinpp) =>
    ['/v0/api/gai-punishments/by-pinpp', pinpp].join('/'),
  getRadarPunishments: () => ['v0/radar-violation-api/decisions'].join('/'),

  /* Catalogs */
  organs: () => ['/v0/api/catalogs/organs'].join('/'),
  regions: () => ['/v0/api/catalogs/regions'].join('/'),
  districts: () => ['/v0/api/catalogs/districts'].join('/'),
  articles: () => ['/v0/api/catalogs/articles'].join('/'),
  articleParts: () => ['/v0/api/catalogs/article-parts'].join('/'),
  availableParts: () =>
    ['/v0/api/article-settings/registered-articles'].join('/'),
  violationTypes: () => ['/v0/api/catalogs/article-violation-types'].join('/'),
  punishmentTypes: () => ['/v0/api/catalogs/punishment-types'].join('/'),
  admStatuses: () => ['/v0/api/catalogs/adm-statuses'].join('/'),
  genders: () => ['/v0/api/catalogs/genders'].join('/'),
  countries: () => ['/v0/api/catalogs/countries'].join('/'),
  occupations: () => ['/v0/api/catalogs/occupations'].join('/'),
  intoxicationTypes: () => ['/v0/api/catalogs/intoxication-types'].join('/'),
  nationalities: () => ['/v0/api/catalogs/nationalities'].join('/'),
  vehicleOwnerships: () =>
    ['/v0/api/catalogs/ubdd/vehicle-owner-type'].join('/'),
  vehicleColorTypes: () =>
    ['/v0/api/catalogs/ubdd/vehicle-color-type'].join('/'),
  vehicleBodyTypes: () => ['/v0/api/catalogs/ubdd/vehicle-body-type'].join('/'),
  articleTypeViolationTypes: () =>
    ['/v0/api/article-settings/registered-article-part-violation-types'].join(
      '/'
    ),
  messages: () => ['/v0/api/catalogs/message-localizations'].join('/'),
  citizenshipTypes: () => ['/v0/api/catalogs/citizenship-types'].join('/'),
  personDocumentTypes: () =>
    ['/v0/api/catalogs/person-document-types'].join('/'),
  departments: () => ['/v0/api/catalogs/departments'].join('/'),
  vehicleArrestPlaces: () =>
    ['/v0/vehicle-wanted-api/catalogs/vehicle-arrest-places'].join('/'),
  vehicleArrestReasons: () =>
    ['/v0/vehicle-wanted-api/catalogs/vehicle-arrest-reasons'].join('/'),
  documentTypes: () => ['/v0/api/catalogs/document-types'].join('/'),
  confiscatedCategories: () =>
    ['/v0/api/catalogs/ubdd/confiscated-category'].join('/'),
  wantedReasons: () =>
    ['/v0/vehicle-wanted-api/catalogs/wanted-reasons'].join('/'),
  tintingCategories: () =>
    ['/v0/api/catalogs/ubdd/tinting-categories'].join('/'),
  wantedOrgans: () =>
    ['/v0/vehicle-wanted-api/catalogs/wanted-organs'].join('/'),
}
