import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty } from 'ramda';
import { ReactComponent as BadgeIcon } from '../../icons/badge.svg';
import { ReactComponent as SourceIcon } from '../../icons/source.svg';
import { ReactComponent as CarIcon } from '../../icons/car-purple.svg';
import { ReactComponent as Edit2Icon } from '../../icons/edit2.svg';
import { ReactComponent as PersonSearchIcon } from '../../icons/person-search.svg';
import TopBar from '../../components/TopBar';
import Button from '../../components/Button';
import { ReactComponent as Trash } from '../../icons/trash.svg';
import Card from '../../components/Card';
import SearchActions from '../../components/SearchActions';
import Divider from '../../components/Divider';
import ViolatorDetailsCardContainer from './ViolatorDetailsCardContainer';
import AdditionalPersonForm from './AdditionalPersonForm';
import {
  resetSelectedPerson,
  searchByForm1, searchOnePersonByIdChip,
  searchPersonByDriverLicenseChip,
  searchPersonByIdChip,
  selectPerson,
  sendPhoto,
  useSelectedPerson,
  useSendPhotoState,
} from './personSlice';
import ConfirmDialog from '../../components/ConfirmDialog';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { useGetMe } from '../authData/authDataSlice';
import styles from '../transport/TransportRouter.module.scss';
import config from '../../config';
import { hideLoader, showLoader } from '../loaderSlice';

const ViolatorForm = ({ onChange }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [{ username, faceIdToken }] = useGetMe();
  const sendPhotoState = useSendPhotoState();
  const selectedPerson = useSelectedPerson();
  const [search, setSearch] = useSearchParams();
  const hideFields = search.get('hideFields');

  const setMrzDecorated = useDecoratedHandle(async (data) => {
    const strLen = data.length;
    const pinpp = data.substr(strLen - 18, 14);
    if (pinpp && pinpp !== 'null') {
      const { objects: persons } = await dispatch(searchByForm1({ pinpp })).unwrap();
      dispatch(selectPerson(persons[0]));
      return;
    }
    toast.error(t('Не удалось прочитать, попытайтесь еще раз'));
  }, true);

  useEffect(() => {
    window.setMrz = (data) => setMrzDecorated(data);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handleReset = () => {
    if (formRef && !hideFields) {
      formRef.current.reset();
    }
    dispatch(resetSelectedPerson());
  };

  const handleViolatorHandy = () => {
    navigate('handy');
  };

  const handleSearchByPhoto = useDecoratedHandle(async (files) => {
    if (!files[0]) return;
    await dispatch(sendPhoto({ files, username, faceIdToken })).unwrap();
    await navigate('search-person-by-photo');
  }, true);

  const handleScanPassport = () => {
    if (!window.EmiInterface) {
      alert('Нет подходящего оборудования');
      return;
    }
    window.EmiInterface.scanDocument();
  };

  const setAutoLicenseDecorated = useDecoratedHandle(async (serial, nfcDocType) => {
    if (!serial || serial === 'null') {
      toast.error(t('Не удалось прочитать, попытайтесь еще раз'));
      return;
    }
    if (nfcDocType === 'idCard') {
      await dispatch(searchOnePersonByIdChip({ number: serial })).unwrap();
    }
    if (nfcDocType === 'driverLicense') {
      await dispatch(searchPersonByDriverLicenseChip({ number: serial })).unwrap();
    }
  }, true);

  const handleReadChipNumber = (nfcDocType) => () => {
    window.setAutoLicense = (serial) => setAutoLicenseDecorated(serial, nfcDocType);
    if (!window.EmiInterface) {
      alert('Нет подходящего оборудования');
      return;
    }
    window.EmiInterface.readAutoLicense();
  };

  const handleClickPedistrian = () => {
    navigate('/protocol-creation-form/pedestrian/violator');
  };

  const handleClickCreateProtocol = () => {
    navigate('/protocol-creation-form/violator');
  };

  const actions = useMemo(() => ([
    {
      id: 'id-card',
      label: t('ID КАРТА (NFC)'),
      onClick: handleReadChipNumber('idCard'),
      Icon: BadgeIcon,
      disabled: false,
      type: 'button',
    },
    {
      id: 'tech-pass',
      label: t('ПРАВА (NFC)'),
      onClick: handleReadChipNumber('driverLicense'),
      Icon: SourceIcon,
      disabled: false,
      type: 'button',
    },
    // {
    //   id: 'plate',
    //   label: t('ПАСПОРТ'),
    //   onClick: handleScanPassport,
    //   Icon: CarIcon,
    //   disabled: true,
    //   type: 'button',
    // },
    {
      id: 'fullName',
      label: t('ПОИСК ПО ФИО'),
      onClick: () => {
        navigate('form1');
      },
      Icon: PersonSearchIcon,
      disabled: false,
      type: 'button',
    },
    {
      id: 'photo',
      label: t('ПОИСК ПО ФОТО'),
      onClick: handleSearchByPhoto,
      loading: (sendPhotoState === 'pending'),
      Icon: PersonSearchIcon,
      disabled: false,
      type: 'file',
    },
    Boolean(!hideFields) && {
      id: 'handy',
      label: t('РУЧНОЙ ВВОД'),
      onClick: handleViolatorHandy,
      Icon: Edit2Icon,
      disabled: Boolean(hideFields),
      type: 'button',
    },
  ]), [t, sendPhotoState]);

  return (
    <>
      <TopBar
        title={t('Правонарушитель')}
        onBack={handleBack}
        extra={() => (
          <ConfirmDialog
            confirmText={t('Вы действительно хотите очистить?')}
            onConfirm={handleReset}
            renderButton={(onClick) => (
              <Button
                type="button"
                variant="textWhite"
                prependIcon={<Trash />}
                onClick={onClick}
              >
                {t('Очистить')}
              </Button>
            )}
          />
        )}
      />
      <Card>
        <SearchActions actions={actions} />
        <Divider mt={5} mb={5} color="#E1F0FF" />
        <ViolatorDetailsCardContainer />
      </Card>
      {
        !hideFields ? (
          <AdditionalPersonForm onChange={onChange} formRef={formRef} />
        ) : (
          <div className={styles.viewActions}>
            <div className={styles.viewActionsItem}>
              <Button
                type="button"
                variant="purple"
                fullWidth
                size='large'
                onClick={handleClickPedistrian}
                appendIcon={<PersonSearchIcon />}
                disabled={isEmpty(selectedPerson) || config.disableProtocolCreation}
              >
                {t('Пешеход (без транспорта)')}
              </Button>
            </div>
            <div className={styles.viewActionsItem}>
              <Button
                type="button"
                variant="purple"
                fullWidth
                size="large"
                onClick={handleClickCreateProtocol}
                appendIcon={<CarIcon />}
                disabled={isEmpty(selectedPerson) || config.disableProtocolCreation}
              >
                {t('Водитель (с транспортом)')}
              </Button>
            </div>
          </div>
        )
      }
    </>
  );
};

export default ViolatorForm;
