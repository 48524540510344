import React from 'react';
// import cn from 'classnames';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';

import styles from './Dialog.module.scss';

const Dialog = ({
  open = true,
  title = 'test test',
  onClose,
  children,
}) => (open ? (
    <div className={styles.dialog}>
      <div className={styles.content}>
        <button
          type="button"
          onClick={onClose}
          className={styles.close}
        >
          <CloseIcon />
        </button>
        <div className={styles.title}>{title}</div>
        <div className={styles.body}>
          {children}
        </div>
      </div>
    </div>
) : null);

export default Dialog;
