import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { MdReportProblem } from 'react-icons/md';
import img from '../../assets/profile.png';
import { ReactComponent as GavelIcon } from '../../icons/gavel2.svg';
import styles from './ViolatorDetailsCard.module.scss';
import Text from '../Text';
import Button from '../Button';
import LabeledText from '../LabeledText';
import Img from '../../features/files/Img';
import number from '../../assets/number.png';

const ViolatorDetailsCard = ({
  photo,
  fullName = '—',
  birthDate = '—',
  documentSn = '—',
  givenDate = '—',
  givenPlace = '—',
  pinpp = '—',
  gender = '—',
  citizenship = '—',
  driverLicenseDialogButton,
  onViolationHistoryClick,
  violationHistoryLoading,
  isWanted,
}) => {
  const [t] = useTranslation();

  const violatorCardClasses = cn({
    [styles.violatorCard]: true,
    [styles.wanted]: isWanted,
  });

  return (
    <div className={violatorCardClasses}>
      {
        isWanted && (
          <div className={styles.alertHeader}>
            <MdReportProblem />
            <span style={{ marginLeft: '10px' }}>{t('Diqqat shahs qidiruvda | Диққат шаҳс қидирувда')}</span>
          </div>
        )
      }
      <div className={styles.violatorCardHeader}>
        <div className={styles.photo}>
          {
            photo ? (
              <Img src={photo} placeholder={photo} skeleton={{ width: 104, height: 95.95 }} />
            ) : (
              <img src={img} alt="" style={{ width: '100%' }} />
            )
          }
        </div>
        <div className={styles.profile}>
          <div className={styles.profileItem}>
            <Text type="body1">{fullName}</Text>
          </div>
          <div className={styles.profileItem}>
            <Text type="body1">{birthDate}</Text>
          </div>
          <div className={styles.profileItem}>
            <Button
              type="button"
              variant="transparent"
              size="medium"
              prependIcon={<GavelIcon style={{ width: 12, height: 12, margin: '0 2px' }} />}
              onClick={() => { onViolationHistoryClick(pinpp); }}
              style={{ padding: '8px 5px', fontSize: 10 }}
              loading={violationHistoryLoading}
            >
              {t('История правонарушений')}
            </Button>
            {driverLicenseDialogButton}
          </div>
        </div>
      </div>
      <div className={styles.violatorCardBody}>
        <div className={styles.violatorCardBodyRow1}>
          <div className={styles.violatorCardBodyRow1Item}>
            <LabeledText label={t('Серия и номер док')} text={documentSn} />
          </div>
          <div className={styles.violatorCardBodyRow1Item}>
            <LabeledText label={t('Дата выдачи')} text={givenDate} />
          </div>
          <div className={styles.violatorCardBodyRow1Item}>
            <LabeledText label={t('ПИНФЛ')} text={pinpp} />
          </div>
        </div>

        <div className={styles.violatorCardBodyRow2}>
          <div className={styles.violatorCardBodyRow2Item}>
            <LabeledText label={t('Выдан')} text={givenPlace} />
          </div>
        </div>

        <div className={styles.violatorCardBodyRow3}>
          <div className={styles.violatorCardBodyRow3Item}>
            <LabeledText label={t('Пол')} text={gender} />
          </div>
          <div className={styles.violatorCardBodyRow3Item}>
            <LabeledText label={t('Гражданство')} text={citizenship} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViolatorDetailsCard;
