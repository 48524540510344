import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  data: {
    isShow: 0,
    label: '',
  },
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,

  reducers: {
    showLoader(state, { payload: { label } }) {
      state.data = {
        isShow: state.data.isShow + 1,
        label,
      };
    },
    hideLoader(state) {
      state.data = {
        isShow: state.data.isShow - 1,
        label: '',
      };
    },
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;

export const useShowLoader = () => useSelector((state) => state.loader.data);

export default loaderSlice.reducer;
