import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import getError from '../../app/api/getError';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';
import buildRequestStates from '../../app/buildRequestStates';

const initialState = {
  ui: {},
  qualification: {},
  createdProtocol: {},
  createdTexPass: {},
  caseMovement: {},
};

export const createMainProtocol = createAsyncThunk(
  'protocolCreation/createMainProtocol',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.createMainProtocol(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const createMainRaidProtocol = createAsyncThunk(
  'protocolCreation/createMainRaidProtocol',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.createMainRaidProtocol(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const createTexPass = createAsyncThunk(
  'protocolCreation/createTexPass',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.createTexPass(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const createTinting = createAsyncThunk(
  'protocolCreation/createTinting',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.createTinting(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const createVehicleInspection = createAsyncThunk(
  'protocolCreation/createVehicleInspection',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.createVehicleInspection(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const createInsurance = createAsyncThunk(
  'protocolCreation/createInsurance',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.createInsurance(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const createDriverLicense = createAsyncThunk(
  'protocolCreation/createDriverLicense',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.createDriverLicense(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const createAttorney = createAsyncThunk(
  'protocolCreation/createAttorney',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.createAttorney(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const setGaiDataService = createAsyncThunk(
  'protocolCreation/setGaiDataService',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.put(routes.setGaiDataService(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const appendDocumentsToAdmCase = createAsyncThunk(
  'protocolCreation/appendDocumentsToAdmCase',
  async ({ admCase, files }, { rejectWithValue }) => {
    try {
      const promises = files.map((file) => new Promise((resolve, reject) => {
        httpClient.post(routes.createDocument(), {
          admCaseId: admCase,
          documentTypeId: 26, // PHOTO
          uri: file.path,
          description: 'FOTO',
        }).then(resolve).catch(reject);
      }));

      await Promise.all(promises);
      return null;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const appendExplanatoryToAdmCase = createAsyncThunk(
  'protocolCreation/appendExplanatoryToAdmCase',
  async ({ admCase, file }, { rejectWithValue }) => {
    try {
      await httpClient.post(routes.createDocument(), {
        admCaseId: admCase,
        documentTypeId: 3, // VIOLATOR_EXPLANATORY
        uri: file.path,
        description: 'Explanatory',
      });
      return null;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const appendWantedInfoToProtocol = createAsyncThunk(
  'protocolCreation/appendWantedInfoToProtocol',
  async ({ params, body }, { rejectWithValue }) => {
    try {
      await httpClient.post(routes.wantedInfoForProtocol(), body, params);
      return null;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const calculateCaseMovement = createAsyncThunk(
  'protocolCreation/setGaiDataService',
  async ({ admCase }, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.calculateCaseMovement(admCase));
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const createSimplifiedResolution = createAsyncThunk(
  'protocolCreation/createSimplifiedResolution',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.createSimplifiedResolution(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const protocolCreation = createSlice({
  name: 'protocolCreation',
  initialState,
  reducers: {
    setQualification: (state, { payload: data }) => {
      state.qualification = data;
    },
    resetQualification: (state) => {
      state.qualification = {};
    },
    resetCreatedProtocol: (state) => {
      state.createdProtocol = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMainProtocol.fulfilled, (state, { payload: data }) => {
        state.createdProtocol = data;
      })
      .addCase(createMainRaidProtocol.fulfilled, (state, { payload: data }) => {
        state.createdProtocol = data;
      })
      .addCase(calculateCaseMovement.fulfilled, (state, { payload: data }) => {
        state.caseMovement = data;
      });
    buildRequestStates(builder, createMainProtocol);
    buildRequestStates(builder, createMainRaidProtocol);
  },
});

export const {
  resetQualification,
  setQualificationFile,
  resetCreatedProtocol,
} = protocolCreation.actions;

export const useQualificationFiles = () => useSelector((state) => state.protocolCreation.qualification.files);

export const useCreateMainProtocolState = () => useSelector((state) => state.protocolCreation.ui[createMainProtocol.typePrefix]);

export const useCreatedProtocol = () => useSelector((state) => state.protocolCreation.createdProtocol);

export const useCaseMovement = () => useSelector((state) => state.protocolCreation.caseMovement);

export default protocolCreation.reducer;
