import React from 'react';
// import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import styles from './index.module.scss';
import Card from '../Card';
import LabeledText from '../LabeledText';
import Divider from '../Divider';
import Button from '../Button';
import { formatDate, formatTime } from '../../utils';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { downloadFileGeneratedPDF, useFileRequestState } from '../../features/files/filesSlice';
import routes from '../../app/api/routes';
import { useCatalogsName } from '../../features/catalogs/catalogsSlice';

const ProtocolCard = ({
  protocol = {},
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const fileDownloadState = useFileRequestState();

  const getRegionName = useCatalogsName('regions');
  const getDistrictName = useCatalogsName('districts');
  const getArticlePartName = useCatalogsName('articleParts');
  const getViolationTypeName = useCatalogsName('violationTypes');
  const getDepartmentName = useCatalogsName('departments');

  const handleDownloadProtocol = useDecoratedHandle(async () => {
    await dispatch(downloadFileGeneratedPDF({ src: routes.protocolPDF(protocol.id) })).unwrap();
  });

  return (
    <Card style={{ marginBottom: 10 }}>
      <div className={styles.header}>
        <div className={styles.headerItem}>
          <LabeledText
            label={t('Серия и номер протокола')}
            text={[protocol.series, protocol.number].join('-')}
          />
        </div>
        <div className={styles.headerItem}>
          <LabeledText
            label={t('Дата составления')}
            text={formatDate(protocol.registrationTime)}
          />
        </div>
        <div className={styles.headerItem}>
          <LabeledText
            label={t('Время составления')}
            text={formatTime(protocol.registrationTime)}
          />
        </div>
      </div>
      <div className={styles.region}>
        <div className={styles.regionItem}>
          <LabeledText
            label={t('Область / Район')}
            text={[getRegionName(protocol.registeredRegionId), getDistrictName(protocol.registeredDistrictId)].join(' / ')}
          />
        </div>
        <div className={styles.regionItem}>
           <LabeledText
            label={t('Подразделение')}
            text={getDepartmentName(protocol.registeredDepartmentId)}
           />
        </div>
      </div>
      <div className={styles.qualification}>
        <div className={styles.qualificationItem}>
          <LabeledText
            label={t('Основная статья')}
            text={getArticlePartName(protocol.articlePartId)}
          />
        </div>
        <div className={styles.qualificationItem}>
          <LabeledText
            label={t('Вид нарушения')}
            text={getViolationTypeName(protocol.articleViolationTypeId)}
          />
        </div>
        <div className={styles.qualificationItem}>
          <LabeledText
            label={t('Дополнительные статьи')}
            text={protocol.additionalArticles}
          />
        </div>
      </div>
      <Divider mt={2} mb={2} />
      <div className={styles.violator}>
        <div className={styles.violatorItem}>
          <LabeledText
            label={t('Нарушитель')}
            text={protocol.fio}
          />
        </div>
        <div className={styles.violatorItem}>
          <LabeledText
            label={t('Дата рождения')}
            text={formatDate(protocol.birthDate)}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.actionsItem}>
          <Button
            type="button"
            variant="action"
            size="medium"
            appendIcon={<DownloadIcon />}
            onClick={handleDownloadProtocol}
            loading={fileDownloadState === 'pending'}
          >
            {t('Скачать протокол')}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ProtocolCard;
