import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import styles from './RaidDialog.module.scss';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import useToggler from '../../../hooks/useToggler';
import { setRaidActive } from '../authDataSlice';
import InputAutocomplete from '../../../components/InputAutocomplete';
import { useAutocompleteItems } from '../../catalogs/catalogsSlice';
import config from '../../../config';

const RaidDialog = () => {
  const [open, handleOpen, handleClose] = useToggler();
  const [t] = useTranslation();
  const getDistricts = useAutocompleteItems('districts');
  const getRegions = useAutocompleteItems('regions');
  const dispatch = useDispatch();
  const handleRaidModeActive = (values) => {
    dispatch(setRaidActive(values));
  };

  return (
    <>
      <Button
        type="button"
        size='small'
        variant="transparent"
        onClick={handleOpen}
        disabled={config.disableProtocolCreation}
      >
        {t('Включить режим РЕЙД')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        title={t('Укажите область и район рейда')}
      >
        <Form onSubmit={handleRaidModeActive}>
          {({ handleSubmit: submit, values, form }) => (
            <form onSubmit={submit}>
              <InputAutocomplete
                label={t('Область')}
                name="regionId"
                options={getRegions()}
                onChange={() => {
                  form.change('districtId', undefined);
                }}
              />
              <InputAutocomplete
                label={t('Район')}
                name="districtId"
                options={getDistricts((d) => String(d.regionId) === values.regionId)}
              />
              <div className={styles.raidDialogFooter}>
                <Button
                  type="submit"
                  variant="purple"
                >
                  {t('Подтвердить')}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </Dialog>
    </>
  );
};

export default RaidDialog;
