import React from 'react';
// import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LeftArrow } from '../../icons/left-arrow.svg';

import styles from './TopBar.module.scss';
import Button from '../Button';
import { ReactComponent as Trash } from '../../icons/trash.svg';
import ConfirmDialog from '../ConfirmDialog';

const TopBar = ({
  onBack,
  title,
  extra,
}) => {
  const [t] = useTranslation();

  return (
    <div className={styles.topBar}>
      <div className={styles.backContainer}>
        {
          onBack && (
            <Button
              type="button"
              variant="textWhite"
              onClick={onBack}
              prependIcon={<LeftArrow />}
              style={{ paddingLeft: 4 }}
            >
              {t('Назад')}
            </Button>
          )
        }
      </div>
      <div className={styles.titleContainer}>
      {
        title && (
          <div className={styles.title}>
            {title}
          </div>
        )
      }
      </div>
      <div className={styles.extraContainer}>
        {extra && extra()}
      </div>
    </div>
  );
};

export default TopBar;
