import React from 'react';

import { Field } from 'react-final-form';
import DateInput from './DateInput';
import { format, isValid } from 'date-fns';

const Adapter = ({
  name,
  label,
  asterisk,
  withTime,
  ...rest
}) => {

  return (
    <Field
      name={name}
      parse={(value) => {
        if (!value || !isValid(value)) return value;
        return (withTime ? format(value, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS') : format(value, 'yyyy-MM-dd'));
      }}
    >
      {({ input, meta }) => (
        <DateInput
          label={label}
          asterisk={asterisk}
          withTime={withTime}
          error={meta.touched && meta.error}
          onChange={input.onChange}
          value={input.value}
          {...rest}
        />
      )}
    </Field>
  );
};

export default Adapter;
