import React, { useEffect } from 'react';
// import cn from 'classnames';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSelect.module.scss';
import { changeLanguage, useActiveLang } from '../../features/app/appSlice';
import { clearAutocompleteItems } from '../../features/catalogs/catalogsSlice';

const LanguageSelect = ({
  disabled,
}) => {
  const [, i18n] = useTranslation();
  const dispatch = useDispatch();
  const lang = useActiveLang();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const handleChangeLang = (e) => {
    dispatch(changeLanguage(e.target.value));
    dispatch(clearAutocompleteItems());
  };

  return (
    <select name="lang" value={lang} onChange={handleChangeLang} className={styles.languageSelect} disabled={disabled}>
      <option value="kir">Ўзбек кирилл</option>
      <option value="lat">O&apos;zbek lotin</option>
      <option value="ru">Русский язык</option>
    </select>
  );
};

export default LanguageSelect;
