import React from 'react';
import { Field } from 'react-final-form';
import { remove } from 'ramda';
import Spinner from 'react-svg-spinner';
import { ReactComponent as AddFileIcon } from '../../icons/addFile.svg';
import styles from './FileField.module.scss';

const FileField = ({
  name,
  label,
  onUpload,
  disabled,
  loading,
  style,
  accept,
  ...rest
}) => (
    <Field
      name={name}
    >
      {({ input, meta }) => (
        <div className={styles.fileInputContainer}>
          <div className={styles.fileInputWrapper}>
            <input
              type="file"
              id={name}
              accept={accept}
              className={styles.fileInput}
              disabled={disabled}
              onChange={async (e) => {
                const fileUrl = URL.createObjectURL(e.target.files[0]);
                const links = await onUpload(e.target.files[0], fileUrl);
                const fileObj = { path: links.data.path, url: fileUrl };
                input.onChange(fileObj);
              }}
            />
            <label htmlFor={name} className={styles.fileInputLabel} style={style}>
              <span style={{ marginRight: '5px' }}>{label}</span><AddFileIcon />
            </label>
          </div>
          <div className={styles.fileInputSelected}>
            {
              loading
                ? <Spinner size="24px" thickness={3} color="#404045" />
                : (
                  input.value && (
                    <div>
                      <button
                        type="button"
                        className={styles.removeButton}
                        onClick={() => {
                          input.onChange(undefined);
                        }}
                      >
                        -
                      </button>
                      {input.value.path}
                    </div>
                  ))
            }
          </div>
           {
            meta.touched && meta.error && (
              <div className={styles.error}>
                {meta.touched && meta.error}
              </div>
            )
           }
        </div>
      )}
    </Field>
);

export default FileField;
