import React from 'react'
// import cn from 'classnames';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Field } from 'react-final-form'
import styles from './AgreementForm.module.scss'
import SignHere from '../../../components/SignHere'
import { setViolatorSign, useViolatorSign } from '../personSlice'
import FileField from '../../../components/FileField'
import useDecoratedHandle from '../../../hooks/useDecoratedHandle'
import { uploadFile, useFileUploadState } from '../../files/filesSlice'
import InputAutoCompleteMultiple from '../../../components/InputAutoCompleteMultiple/Adapter'
import {
  useAutocompleteItems,
  useCatalogsName,
} from '../../catalogs/catalogsSlice'
import Text from '../../../components/Text'

const AgreementForm = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const fileUploadingState = useFileUploadState()
  const handleUpload = useDecoratedHandle(async (file) =>
    dispatch(uploadFile({ file })).unwrap()
  )
  return (
    <>
      <div style={{ marginTop: '10px' }}>
        <Text type="subtitle2">
          {t(
            'Я ознакомился с протоколом и мне разъяснены мои права и обязанности в соответствии со статьей 294 Гражданского процессуального кодекса Республики Узбекистан'
          )}
        </Text>
      </div>
      <div className={styles.isAgree}>
        <div className={styles.isAgreeLabel}>{t('Признак согласия')}:</div>
        <div className={styles.isAgreeRadios}>
          <div className={styles.isAgreeRadiosItem}>
            <Field
              component="input"
              type="radio"
              id="agreed"
              name="isAgree"
              className={styles.isAgreeRadio}
              value="yes"
            />
            <label htmlFor="agreed" className={styles.isAgreeInputLabel}>
              {t('Согласен')}
            </label>
          </div>
          <div className={styles.isAgreeRadiosItem}>
            <Field
              component="input"
              type="radio"
              id="notAgreed"
              name="isAgree"
              className={styles.isAgreeRadio}
              value="no"
            />
            <label htmlFor="notAgreed" className={styles.isAgreeInputLabel}>
              {t('Не согласен')}
            </label>
          </div>
        </div>
      </div>
      <div className={styles.explanatory}>
        <FileField
          name="explanatory"
          label={t('Добавить объяснительную')}
          onUpload={handleUpload}
          loading={fileUploadingState === 'pending'}
          accept="image/*,application/pdf"
        />
      </div>
    </>
  )
}

export default AgreementForm
