import React from 'react';
// import cn from 'classnames';

import { useTranslation } from 'react-i18next';
import styles from './AttorneysContent.module.scss';
import Text from '../../../../../components/Text';
import LabeledText from '../../../../../components/LabeledText';

const AttorneysContent = ({
  fullName = '—',
  birthDate = '—',
  attorneySn = '—',
  attorneyGivenDate = '—',
  attorneyExpiresDate = '—',
}) => {
  const [t] = useTranslation();

  return (
    <>
      <div className={styles.selectContentHead}>
        <div className={styles.selectContentHeadItem}>
          <Text type="body1">{fullName}</Text>
        </div>
        <div className={styles.selectContentHeadItem}>
          <Text type="body1">{birthDate}</Text>
        </div>
      </div>
      <div className={styles.selectContentBody}>
        <div className={styles.selectContentBodyItem}>
          <LabeledText
            label={t('Номер доверенности')}
            text={attorneySn}
          />
        </div>
        <div className={styles.selectContentBodyItem}>
          <LabeledText
            label={t('Дата выдачи')}
            text={attorneyGivenDate}
          />
        </div>
        <div className={styles.selectContentBodyItem}>
          <LabeledText
            label={t('Действительна до')}
            text={attorneyExpiresDate}
          />
        </div>
      </div>
    </>
  );
};

export default AttorneysContent;
