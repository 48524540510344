import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './RadarPunishmentCard.module.scss';
import LabeledText from '../LabeledText';
import Button from '../Button';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { useCatalogsAlias, useCatalogsName } from '../../features/catalogs/catalogsSlice';
import StatusLabel from '../StatusLabel';
import Text from '../Text';
import { formatDate, toSom } from '../../utils';
import useAdmStatusColors from '../../hooks/useAdmStatusColors';

const RadarPunishmentCard = ({
  punishment,
  loading,
  handleDownload,
}) => {
  const [t] = useTranslation();
  const getAdmStatusName = useCatalogsName('admStatuses');
  const getAdmStatusAlias = useCatalogsAlias('admStatuses');
  const getChipColor = useAdmStatusColors();
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.cardHeaderItem}>
          <Text type="body1" className={styles.dangerText}>{t('Штраф')}</Text>
        </div>
      </div>
      <div className={styles.cardHeaderBetween}>
        <LabeledText label={t('Номер квитанции')} text={punishment.number} />
        <StatusLabel text={getAdmStatusName(punishment.admStatusId)} type={getChipColor(getAdmStatusAlias(punishment.admStatusId))} />
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cardBodyHalfItem}>
          <LabeledText label={t('Сумма штрафа')} text={toSom(punishment.penaltyAmount) || '—'} />
        </div>
        <div className={styles.cardBodyHalfItem}>
          <LabeledText label={t('Сумма оплаты')} text={toSom(punishment.penaltyPaidAmount) || '—'} />
        </div>
        <div className={styles.cardBodyHalfItem}>
          <LabeledText label={t('Номер авто')} text={punishment.vehicleNumber} />
        </div>
        <div className={styles.cardBodyHalfItem}>
          <LabeledText label={t('Дата нарушения')} text={formatDate(punishment.violationTime, true)} />
        </div>
        <div className={styles.cardBodyItem}>
          <LabeledText label={t('Решение')} text={`${punishment.number} от ${formatDate(punishment.resolutionDate)}`} />
        </div>
        <div className={styles.cardBodyItem}>
          <LabeledText label={t('Информация')} text={punishment.additionally || '—'} />
        </div>
      </div>
      <div className={styles.cardFooter}>
        <Button
          type="button"
          variant="action"
          size="medium"
          appendIcon={<DownloadIcon />}
          loading={loading}
          onClick={() => { handleDownload(punishment.pdfUrl); }}
        >
          {t('Скачать решение')}
        </Button>
      </div>
    </div>
  );
};

export default RadarPunishmentCard;
