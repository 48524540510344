import React from 'react';
import cn from 'classnames';
import styles from './SelectContent.module.scss';
import { ReactComponent as SelectUncheckedIcon } from '../../icons/select-unchecked.svg';
import { ReactComponent as SelectCheckedIcon } from '../../icons/select-checked.svg';

const SelectContent = ({
  children,
  selected,
  onClick,
  extra,
}) => {
  const handleClick = () => {
    onClick();
  };

  const classes = cn({
    [styles.selectContent]: true,
    [styles.checked]: selected,
  });

  return (
    <div className={classes}>
      <div className={styles.wrapper} onClick={handleClick}>
        <div className={styles.radioContainer}>
          { selected ? <SelectCheckedIcon /> : <SelectUncheckedIcon />}
        </div>
        <div className={styles.radioContent}>
          {children}
        </div>
      </div>
      {extra && extra()}
    </div>
  );
};

export default SelectContent;
