export default (builder, action) => {
  builder
    .addMatcher((a) => a.type === `${action.typePrefix}/pending`, (state) => {
      state.ui[action.typePrefix] = 'pending';
    })
    .addMatcher((a) => a.type === `${action.typePrefix}/rejected`, (state) => {
      state.ui[action.typePrefix] = 'rejected';
    })
    .addMatcher((a) => a.type === `${action.typePrefix}/fulfilled`, (state) => {
      state.ui[action.typePrefix] = 'fulfilled';
    });
};
