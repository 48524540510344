import React from 'react';
import { isEmpty, not } from 'ramda';
import { useDispatch } from 'react-redux';
import VehicleInfo from '../../components/VehicleInfo';
import {
  useInsurances,
  useInsurancesState,
  useSelectedTexPass, useTaxiLicense, useTaxiLicenseState,
  useTechPassportState,
  useTintings,
  useTintingState,
  useTransportInfoState, useVehicleInspection, useVehicleInspectionState,
} from './transportSlice';
import { useCatalogsName } from '../catalogs/catalogsSlice';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import {
  getPunishmentByPlateNumber,
  getRadarPunishments,
  openPunishmentDialog, setPunishmentQueryType,
  usePunishmentsState, useRadarPunishments, useRadarPunishmentsState,
} from '../punishments/punishmentsSlice';

const VehicleInfoContainer = () => {
  const dispatch = useDispatch();
  const insurancesState = useInsurancesState();
  const insurances = useInsurances();
  const vehicleInspections = useVehicleInspection();
  const vehicleInspectionsState = useVehicleInspectionState();
  const tintings = useTintings();
  const tintingState = useTintingState();
  const taxiLicense = useTaxiLicense();
  const taxiLicenseState = useTaxiLicenseState();
  const techPassportState = useTechPassportState();
  const transportInfoState = useTransportInfoState();
  const getVehicleBodyType = useCatalogsName('vehicleBodyTypes');
  const getVehicleOwnerships = useCatalogsName('vehicleOwnerships');
  const techPassport = useSelectedTexPass();
  const notEmpty = not(isEmpty(techPassport));
  const punishmentsByNumberState = usePunishmentsState();
  const radarPunishmentsState = useRadarPunishmentsState();

  const handleViolationHistory = useDecoratedHandle(async (number) => {
    const normalizedValue = {
      vehicleNumber: techPassport.vehicleNumber,
      texPassSeries: techPassport.texPassSeries,
      texPassNumber: techPassport.texPassNumber,
    };
    await dispatch(getPunishmentByPlateNumber(number));
    await dispatch(getRadarPunishments(normalizedValue));
    dispatch(setPunishmentQueryType('vehicleNumber'));
    dispatch(openPunishmentDialog());
  });
  const nameByOwnerType = {
    0: [techPassport.vehicleOwnerLastName, techPassport.vehicleOwnerFirstName, techPassport.vehicleOwnerSecondName].join(' '),
    1: techPassport.vehicleOwnerOrganizationName,
  };

  return notEmpty
    ? (
      <VehicleInfo
        plateNumber={techPassport.vehicleNumber}
        ownerName={nameByOwnerType[techPassport.vehicleOwnerTypeId]}
        techPassSN={[techPassport.texPassSeries, techPassport.texPassNumber].join(' ')}
        violatorType={getVehicleOwnerships(String(techPassport.vehicleOwnerTypeId))}
        vehicleModel={techPassport.vehicleModel}
        vehicleType={getVehicleBodyType(techPassport.vehicleBodyTypeId)}
        color={techPassport.vehicleColor}
        vehicleYear={techPassport.vehicleYear}
        onViolationHistoryClick={handleViolationHistory}
        insurances={insurances}
        insurancesLoading={insurancesState === 'pending'}
        tintings={tintings}
        tintingsLoading={tintingState === 'pending'}
        taxiLicense={taxiLicense}
        taxiLicenseLoading={taxiLicenseState === 'pending'}
        techPassport={techPassport}
        techPassportLoading={techPassportState === 'pending'}
        vehicleInspection={vehicleInspections}
        vehicleInspectionLoading={vehicleInspectionsState === 'pending'}
        vehicleInfoLoading={transportInfoState === 'pending'}
        violationHistoryLoading={punishmentsByNumberState === 'pending' || radarPunishmentsState === 'pending'}
      />
    ) : null;
};

export default VehicleInfoContainer;
