import React, { useEffect } from 'react';
// import cn from 'classnames';

import { Field, Form, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty, remove, replace } from 'ramda';
import { useLocation } from 'react-router';
import styles from './AdditionalPersonForm.module.scss';
import TextFormatedAdapter from '../../../components/InputText/TextFormatedAdapter';
import Divider from '../../../components/Divider';
import Text from '../../../components/Text';
import InputAutocomplete from '../../../components/InputAutocomplete';
import InputTextArea from '../../../components/InputTextArea';
import InputText from '../../../components/InputText';
import Button from '../../../components/Button';
import { ReactComponent as RefreshIcon } from '../../../icons/refresh.svg';
import { ReactComponent as DownloadIcon } from '../../../icons/download.svg';
import {
  setAdditionalPersonInfo,
  useAdditionalPersonInfo,
  usePersonDriverLicense,
  useSelectedPerson,
} from '../personSlice';
import { useAutocompleteItems } from '../../catalogs/catalogsSlice';
import { composeValidators, required, validateIf } from '../../../validators';
import RFFValidationError from '../../../components/RFFValidationError';

const PersonForm = () => {
  const selectedPerson = useSelectedPerson();
  const form = useForm();
  useEffect(() => {
    form.change('person', selectedPerson);
  }, [selectedPerson?.number]);
  return null;
};

const AdditionalPersonForm = ({ onChange, formRef }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const selectedPerson = useSelectedPerson();
  const getCountries = useAutocompleteItems('countries');
  const getRegions = useAutocompleteItems('regions');
  const getDistricts = useAutocompleteItems('districts');
  const getOccupations = useAutocompleteItems('occupations');
  const getIntoxicationTypes = useAutocompleteItems('intoxicationTypes');
  const driverLicenses = usePersonDriverLicense();
  const location = useLocation();
  const splitLocation = location.pathname.split('/');
  const backLocation = remove(-1, 1, splitLocation).join('/');
  const handleResetIntoxication = () => {
  };

  const initialValues = {
    countryId: selectedPerson.residentAddress?.countryId ? String(selectedPerson.residentAddress?.countryId) : null,
    regionId: selectedPerson.residentAddress?.regionId ? String(selectedPerson.residentAddress?.regionId) : null,
    districtId: selectedPerson.residentAddress?.districtId ? String(selectedPerson.residentAddress?.districtId) : null,
    address: selectedPerson.residentAddress?.address || null,
  };

  const handleSubmit = async (values) => {
    const normalizer = {
      ...values,
      driverLicenses,
    };
    onChange(normalizer);
    navigate(backLocation);
  };

  const validate = (values) => ({
    mobile: composeValidators(required)(values.mobile),
    countryId: composeValidators(required)(values.countryId),
    regionId: composeValidators(
      validateIf(values.countryId && values.countryId === '1')(required),
    )(values.regionId),
    districtId: composeValidators(
      validateIf(values.regionId)(required),
    )(values.districtId),
    address: composeValidators(required)(values.address),
    occupationId: composeValidators(required)(values.occupationId),
    occupationPlace: composeValidators(required)(values.occupationPlace),
    person: composeValidators(required)(values.person?.number),
  });

  return (
    <Form onSubmit={handleSubmit} validate={validate} initialValues={initialValues}>
      {({ handleSubmit: submit, values, form }) => {
        formRef.current = form;
        return (
          <form onSubmit={submit} className={styles.violatorForm}>
            <PersonForm />
            <div className={styles.violatorFormItem}>
              <TextFormatedAdapter
                label={t('Номер мобильного')}
                name="mobile"
                inputFormat="+998 (##) ###-##-##"
              />
            </div>
            <Divider mt={5} mb={5} />
            <div className={styles.sectionHeader}>
              <Text type="h3">{t('Адрес нарушителя')}</Text>
            </div>
            <div className={styles.violatorFormItem}>
              <InputAutocomplete
                label={t('Страна')}
                name="countryId"
                options={getCountries()}
                onChange={() => {
                  form.change('regionId', undefined);
                }}
              />
            </div>
            <div className={styles.violatorFormItem}>
              <InputAutocomplete
                label={t('Область')}
                name="regionId"
                options={getRegions(() => values.countryId === '1')}
                onChange={() => {
                  form.change('districtId', undefined);
                }}
              />
            </div>
            <div className={styles.violatorFormItem}>
              <InputAutocomplete
                label={t('Район')}
                name="districtId"
                options={getDistricts((d) => String(d.regionId) === values.regionId)}
              />
            </div>
            <div className={styles.violatorFormItem}>
              <InputTextArea
                label={t('Адрес')}
                name="address"
              />
            </div>
            <Divider mt={5} mb={5} />
            <div className={styles.sectionHeader}>
              <Text type="h3" >{t('Информация о занятости')}</Text>
            </div>
            <div className={styles.violatorFormItem}>
              <InputAutocomplete
                label={t('Род занятий')}
                name="occupationId"
                options={getOccupations()}
              />
            </div>
            <div className={styles.violatorFormItem}>
              <InputText
                label={t('Место занятости')}
                name="occupationPlace"
              />
            </div>
            <Divider mt={5} mb={5} />
            <div className={styles.labelWithExtra}>
              <Text type="h3">{t('Признак опьянения')}</Text>
              <Button
                type="button"
                variant="text"
                size="small"
                onClick={handleResetIntoxication}
                prependIcon={<RefreshIcon />}
              >
                {t('Сбросить признак')}
              </Button>
            </div>
            {
              getIntoxicationTypes().map(({ id, label }) => (
                <div className={styles.selectItem} key={id}>
                  <Field
                    className={styles.radio}
                    component="input"
                    name="intoxication"
                    value={String(id)}
                    type="radio"
                    id={`type-${id}`}
                  />
                  <label htmlFor={`type-${id}`}>{label}</label>
                </div>
              ))
            }
            <div className={styles.viewActions}>
              <div className={styles.viewActionsItem}>
                <Button
                  type="submit"
                  variant="purple"
                  size="medium"
                  fullWidth
                  appendIcon={<DownloadIcon />}
                  style={{ fontSize: '17px' }}
                >
                  {t('Составить протокол')}
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Form>
  );
};

export default AdditionalPersonForm;
