import React from 'react';
// import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import styles from './ProtocolCreationPhotos.module.scss';
import Card from '../../../components/Card';
import { ReactComponent as PhotoIcon } from '../../../icons/photo.svg';
import Divider from '../../../components/Divider';
import CardHeader from '../../../components/CardHeader';
import useDecoratedHandle from '../../../hooks/useDecoratedHandle';
import { uploadFile, useFileUploadState } from '../../files/filesSlice';
import FileAdapter from '../../../components/FileUpload/Adapter';

const ProtocolCreationPhotos = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const handleUpload = useDecoratedHandle(async (file) => dispatch(uploadFile({ file })).unwrap());
  const fileUploadingState = useFileUploadState();

  return (
    <Card style={{ marginTop: 10 }}>
      <CardHeader icon={<PhotoIcon />} label={t('Фото нарушения')} />
      <Divider color="#E1F0FF" />
      <div className={styles.photoContainer}>
        <div className={styles.photoItem}>
          <FileAdapter
            name="qualificationFiles[0]"
            onUpload={handleUpload}
            loading={fileUploadingState === 'pending'}
          />
        </div>
        <div className={styles.photoItem}>
          <FileAdapter
            name="qualificationFiles[1]"
            onUpload={handleUpload}
            loading={fileUploadingState === 'pending'}
          />
        </div>
      </div>
    </Card>
  );
};

export default ProtocolCreationPhotos;
