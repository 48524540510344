import axios from 'axios'
import queryString from 'query-string'

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )

  return JSON.parse(jsonPayload)
}

const hosts = {
  prod: 'http://api.emi.mvd:9090',
  // prod: 'http://10.1.40.33:9090',
  ubddTest: 'http://test-api-ubdd.emi.mvd',
  // dev: 'https://api.emi.igorkim.uz',
  dev: 'http://192.168.10.70:80',
}

const httpClient = axios.create({
  // baseURL: process.env.REACT_APP_MVD_BUILD ? 'http://api.emi.mvd:9090' : 'https://dev.ciasev.igorkim.uz',
  // baseURL: process.env.REACT_APP_MVD_BUILD ? 'http://10.1.40.33:9102' : 'https://dev.ciasev.igorkim.uz',
  // baseURL: 'http://api.emi.mvd:9090',
  // baseURL: 'http://10.1.40.33:9102', // testovyy prod
  baseURL: hosts[process.env.REACT_APP_MVD_BUILD],
})

httpClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('TOKEN')
    let newConfig = config

    if (token) {
      const parsedToken = parseJwt(token)
      newConfig = {
        ...newConfig,
        params: {
          ...config.params,
          log_user: parsedToken.username ? parsedToken.username : 'not_present',
          log_from: 'tablet-lite-v0',
        },
        headers: { ...config.headers, Authorization: `Bearer ${token}` },
        paramsSerializer: (params) =>
          queryString.stringify(params, { arrayFormat: 'comma' }),
      }
    }

    if (config.allPages) {
      newConfig = {
        ...newConfig,
        params: {
          ...newConfig.params,
          page: 'unpaged',
        },
      }
    }

    return newConfig
  },
  (error) => Promise.reject(error)
)

httpClient.interceptors.response.use(
  (response) => ({
    ...response,
    path: response.data.path,
    token: response.data.token,
    data: response.data.data,
    blob: response.data instanceof Blob ? response.data : null,
  }),
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('TOKEN')
    }
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result)
          resolve(Promise.reject(error))
        }

        reader.onerror = () => {
          reject(error)
        }

        reader.readAsText(error.response.data)
      })
    }

    return Promise.reject(error)
    // return Promise.reject(error);
  }
)

export default httpClient
