import React from 'react';
import { useTranslation } from 'react-i18next';
import { identity } from 'ramda';
import Spinner from 'react-svg-spinner';
import styles from './FileButton.module.scss';

const FileButton = ({
  label,
  icon,
  onChange = identity(),
  disabled,
  loading,
  ...rest
}) => {
  const [t] = useTranslation();
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.files);
    e.target.value = null;
  };
  const getInputType = () => (disabled ? 'button' : 'file');
  return (
    <label htmlFor='photo' className={styles.fileButton}>
      <input
        type={getInputType()}
        id='photo'
        onChange={handleChange}
        className={styles.fileInput}
        onClick={disabled ? onChange : null}
        {...rest}
      />
      <div>{icon}</div>
      <span>{label}</span>
      {
        loading && (
          <span className={styles.spinner}>
            <Spinner size="16px" thickness={3} color="#404045" />
          </span>
        )
      }
    </label>
  );
};

export default FileButton;
