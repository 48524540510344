import React from 'react';
// import cn from 'classnames';
import styles from './CardHeader.module.scss';

const CardHeader = ({
  label, icon, extra1, extra2,
}) => {

  return (
    <div className={styles.header}>
        <span className={styles.headerItem}>
          <div className={styles.label}>
            <span className={styles.icon}>
              {icon}
            </span>
            <span className={styles.title}>
              {label}
            </span>
            </div>
        </span>
      <span className={styles.headerItem}>
          <div className={styles.actions}>
            {
              extra1 && (
                <div className={styles.actionsItem}>
                  {extra1}
                </div>
              )
            }
            {
              extra2 && (
                <div className={styles.actionsItem}>
                  {extra2}
                </div>
              )
            }
          </div>
        </span>
    </div>
  );
};

export default CardHeader;
