import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createCompressor from 'redux-persist-transform-compress';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appReducer from '../features/app/appSlice';
import protocolListReducer from '../features/protocolList/protocolListSlice';
import decisionListReducer from '../features/decisionList/decisionListSlice';
import attorneysReducer from '../features/attorneys/attorneysSlice';
import authDataReducer from '../features/authData/authDataSlice';
import filesReducer from '../features/files/filesSlice';
import catalogsReducer from '../features/catalogs/catalogsSlice';
import protocolCreationReducer from '../features/protocolCreations/protocolCreationsSlice';
import transportReducer from '../features/transport/transportSlice';
import wantedReducer from '../features/wanted/wantedSlice';
import form1Reducer from '../features/person/personSlice';
import dependentCatalogsReducer from '../features/dependentCatalogs/dependentCatalogsSlice';
import punishmentsReducer from '../features/punishments/punishmentsSlice';
import servicesReducer from '../features/services/servicesSlice';
import loaderReducer from '../features/loaderSlice';

const compressor = createCompressor({
  whiteList: ['catalogs'],
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'loader',
    'protocolList',
    'decisionList',
    'attorneys',
    'files',
    'protocolCreation',
    'transport',
    'dependentCatalogs',
    'wanted',
    'form1',
    'punishments',
    'services',
  ],
  transforms: [compressor],
};

const persistedReducer = persistReducer(persistConfig, combineReducers({
  authData: authDataReducer,
  loader: loaderReducer,
  app: appReducer,
  protocolCreation: protocolCreationReducer,
  protocolList: protocolListReducer,
  decisionList: decisionListReducer,
  attorneys: attorneysReducer,
  files: filesReducer,
  catalogs: catalogsReducer,
  dependentCatalogs: dependentCatalogsReducer,
  transport: transportReducer,
  wanted: wantedReducer,
  punishments: punishmentsReducer,
  form1: form1Reducer,
  services: servicesReducer,
}));

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    //   {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
  }),
});
