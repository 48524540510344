import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import CardHeader from '../../components/CardHeader';
import { ReactComponent as UserIcon } from '../../icons/user.svg';
import Button from '../../components/Button';
import { ReactComponent as BackspaceIcon } from '../../icons/backspace.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import Divider from '../../components/Divider';
import styles from './ViolatorRouter.module.scss';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import ViolatorDetailsCardContainer from './ViolatorDetailsCardContainer';
import AgreementForm from './AgreementForm';
import Card from '../../components/Card';
import { resetAdditionalPersonInfo, resetSelectedPerson } from './personSlice';
import RFFValidationError from '../../components/RFFValidationError';
import ConfirmDialog from '../../components/ConfirmDialog';
import ConfiscatedForm from './ConfiscatedForm/ConfiscatedForm';

const ViolatorContainer = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { values } = useFormState();
  const form = useForm();
  const handleClickAddViolator = () => {
    dispatch(resetSelectedPerson());
    dispatch(resetAdditionalPersonInfo());
    navigate('violator');
  };

  const handleResetViolator = () => {
    dispatch(resetSelectedPerson());
    dispatch(resetAdditionalPersonInfo());
    form.change('violator', null);
  };

  const handleEditViolator = () => {
    navigate('violator');
  };

  const isEmptyPerson = !values.violator;
  return (
    <Card style={{ marginTop: 10 }} >
      <CardHeader
        label={t('Нарушитель')}
        icon={<UserIcon />}
        extra1={
          isEmptyPerson
            ? null
            : (
              <ConfirmDialog
                confirmText={t('Вы действительно хотите очистить?')}
                onConfirm={handleResetViolator}
                renderButton={(onClick) => (
                  <Button
                    type="button"
                    variant="text"
                    size="small"
                    prependIcon={<BackspaceIcon />}
                    onClick={onClick}
                  >
                    {t('Очистить')}
                  </Button>
                )}
              />
            )
        }
        extra2={
          isEmptyPerson
            ? null
            : (
              <Button
                type="button"
                variant="text"
                size="small"
                prependIcon={<EditIcon />}
                onClick={handleEditViolator}
              >
                {t('Редактировать')}
              </Button>
            )
        }
      />
      <Divider color="#E1F0FF" />
      {
        isEmptyPerson
          ? (
            <div className={styles.empty}>
              <Button
                fullHeight
                fullWidth
                type="button"
                variant="lightBlue"
                onClick={handleClickAddViolator}
              >
                <AddIcon style={{ width: 60, height: 60, margin: 0 }} />
              </Button>
              <RFFValidationError name='violator' />
            </div>
          ) : (
            <div className={styles.filled}>
              <ViolatorDetailsCardContainer />
            </div>
          )
      }
      <ConfiscatedForm />
    </Card>
  );
};

export default ViolatorContainer;
