import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isEmpty, isNil } from 'ramda';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';
import getError from '../../app/api/getError';
import buildRequestStates from '../../app/buildRequestStates';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';

const initialState = {
  ui: {},
  user: {},
  raid: {
    isActive: false,
  },
  ecpPrivateData: {},
  isOpenEcpPasswordDialog: false,
};

export const getMe = createAsyncThunk(
  'authData/getMe',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.getMe());

      return response.data?.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const login = createAsyncThunk(
  'authData/login',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.auth(), body);

      localStorage.setItem('TOKEN', response.token);
      return null;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const loginByUsername = createAsyncThunk(
  'authData/loginByUsername',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.authByUsername(), body);

      localStorage.setItem('TOKEN', response.token);
      return null;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const getMyECPKey = createAsyncThunk(
  'user/getMyECPKey',
  async (data, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.myECPKey());
      return response.data;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const setMyECPPrivateKey = createAsyncThunk(
  'user/setMyECPPrivateKey',
  async (data, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.myECPPrivateKey());
      return response.data;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const authDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setRaidActive: (state, { payload: data }) => ({ ...state, raid: { isActive: true, ...data } }),
    setRaidInactive: (state) => ({ ...state, raid: { isActive: false } }),
    removeUserData: (state) => ({ ...state, user: {} }),
    openEcpPasswordDialog: (state) => ({ ...state, isOpenEcpPasswordDialog: true }),
    closeEcpPasswordDialog: (state) => ({ ...state, isOpenEcpPasswordDialog: false }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.fulfilled, (state, { payload: data }) => {
        state.user = data;
      })
      .addCase(setMyECPPrivateKey.fulfilled, (state, { payload: data }) => {
        state.ecpPrivateData = data.object;
      });

    buildRequestStates(builder, login);
    buildRequestStates(builder, loginByUsername);
    buildRequestStates(builder, getMe);
    buildRequestStates(builder, setMyECPPrivateKey);
  },
});

export const useLoginRequestState = () => useSelector((state) => state.authData.ui[login.typePrefix]);

export const useGetMe = (forceFetch = false) => {
  const dispatch = useDispatch();
  const requestState = useSelector((state) => state.authData.ui[getMe.typePrefix]);
  const data = useSelector((state) => state.authData.user);

  const getAccountInfo = useDecoratedHandle(async () => {
    await dispatch(getMe()).unwrap();
  });

  useEffect(() => {
    if (forceFetch) {
      getAccountInfo();
      return;
    }
    if (isEmpty(data)) {
      getAccountInfo();
    }
  }, []);

  return [data, requestState === 'pending'];
};

export const {
  setRaidActive, setRaidInactive, openEcpPasswordDialog, closeEcpPasswordDialog,
  removeUserData,
} = authDataSlice.actions;

export const useRaid = () => useSelector((state) => state.authData.raid);
export const useEcpPrivateData = () => useSelector((state) => state.authData.ecpPrivateData);
export const useIsOpenEcpPasswordDialog = () => useSelector((state) => state.authData.isOpenEcpPasswordDialog);

export default authDataSlice.reducer;
