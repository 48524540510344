import React from 'react';

import { Field } from 'react-final-form';
import TextAreaInput from './TextAreaInput';

const Adapter = ({ name, ...rest }) => {

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <TextAreaInput
          error={meta.touched && meta.error}
          onChange={input.onChange}
          value={input.value}
          {...rest}
        />
      )}
    </Field>
  );
};

export default Adapter;
