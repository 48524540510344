import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getMe, useEcpPrivateData, useGetMe } from '../features/authData/authDataSlice';

const useSign = () => {
  const privateData = useEcpPrivateData();
  const [userInfo] = useGetMe();
  const [t] = useTranslation();
  const signedString = JSON.stringify({
    username: userInfo.username,
    documentSeries: userInfo.documentSeries,
    documentNumber: userInfo.documentNumber,
    workCertificate: userInfo.workCertificate,
    pinpp: userInfo.person?.pinpp,
  });

  const sign = (cb = () => {}) => {
    window.setSign = (text) => {
      if (text) {
        alert(text);
        cb(text);
        return;
      }
      cb(null);
    };
    window.onSignError = (err) => {
      alert(err);
      cb(null);
    };

    const token = 'DC0Nurn3ScyD9flEK9iMYM95T50WbCSKzhaXR6OG4pOC8ddopw3z4pe4xHll8T3lNk0mjw==';
    const password = localStorage.getItem('ECPPAS');
    const keyId = privateData.serial;
    const key = privateData.privateKey;
    if (window.EmiInterface) {
      try {
        window.EmiInterface.signString(signedString, token, password, keyId, key);
      } catch (e) {
        cb(null);
      }
      return;
    }
    alert('Нет подходящего оборудования!');
  };
  return [sign, signedString];
};

export default useSign;
