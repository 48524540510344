import React from 'react';
import { ReactComponent as Privacy } from '../../icons/privacy.svg';
import styles from './index.module.scss';

const DangerSticker = ({ text, onClick, buttonText }) => (
    <div className={styles.sticker}>
      <div className={styles.icon}>
        <Privacy />
      </div>
      <div className={styles.text}>{text}</div>
      <button
        type="button"
        className={styles.button}
        onClick={onClick}
      >
        {buttonText}
      </button>
    </div>
);

export default DangerSticker;
