import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { toUpper } from 'ramda';
import { useDispatch } from 'react-redux';
import Dialog from '../Dialog';
import styles from './UploadScanDialog.module.scss';
import number from '../../assets/number.png';
import InputText from '../InputText';
import Button from '../Button';
import InputAutocomplete from '../InputAutocomplete';
import { useAutocompleteItems } from '../../features/catalogs/catalogsSlice';
import FileField from '../FileField';
import { uploadFile, useFileUploadState } from '../../features/files/filesSlice';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { composeValidators, required } from '../../validators';

const UploadScanDialog = ({
  open,
  handleClose,
  onAddScan,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const documentTypes = useAutocompleteItems('documentTypes');
  const fileUploadingState = useFileUploadState();
  const handleUpload = useDecoratedHandle(async (file) => dispatch(uploadFile({ file })).unwrap());

  const validate = (values) => ({
    documentTypeId: composeValidators(required)(values.documentTypeId),
    document: composeValidators(required)(values.document),
  });

  return (
    <Dialog
      open={open}
      title={t('Загрузите документ')}
      onClose={handleClose}
    >
      <Form
        onSubmit={onAddScan}
        validate={validate}
      >
        {({ handleSubmit: submit }) => (
          <form onSubmit={submit} className={styles.dialogForm}>
            <div className={styles.numberInput}>
              <InputAutocomplete
                label={t('Тип документа')}
                name="documentTypeId"
                asterisk
                options={documentTypes()}
              />
            </div>
            <div>
              <FileField
                name="document"
                label={t('Добавить файл')}
                onUpload={handleUpload}
                style={{ fontSize: '15px', padding: '4px 8px' }}
                loading={fileUploadingState === 'pending'}
                accept="application/pdf"
              />
            </div>
            <div className={styles.dialogFooter}>
              <div className={styles.dialogCancel}>
                <Button
                  type="reset"
                  variant="action"
                  size="medium"
                  onClick={handleClose}
                  fullWidth
                >
                  {t('Отмена')}
                </Button>
              </div>
              <div className={styles.dialogSubmit}>
                <Button
                  type="submit"
                  variant="purple"
                  size="medium"
                  fullWidth
                >
                  {t('Добавить')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default UploadScanDialog;
