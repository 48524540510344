import React from 'react';
import cn from 'classnames';

import './TextAreaInput.scss';

const TextAreaInput = ({
  label = '',
  asterisk = false,
  error = null,
  expandOnFocus = true,
  rows = 3,
  ...rest
}) => {
  const wrapperClasses = cn(
    'input-wrapper',
    error && 'input-error',
  );

  const textAreaClasses = cn(
    'input',
    'input-area',
    expandOnFocus && 'input-area-expand',
  );

  return (
    <div className={wrapperClasses}>
      {label && (
        <div className="input-label">
          {label} {asterisk && <span className="input-asterisk">*</span>}
        </div>
      )}
      <textarea className={textAreaClasses} {...rest} rows={rows} />
      {
        error && (
          <div className="input-error-text">
            {error}
          </div>
        )
      }
    </div>
  );
};

export default TextAreaInput;
