import React from 'react';
import { Form } from 'react-final-form';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toUpper } from 'ramda';
import styles from './ProtocolCreationTransportHandyForm.module.scss';
import Card from '../../../components/Card';
import Text from '../../../components/Text';
import InputText from '../../../components/InputText';
import InputPlainText from '../../../components/InputPlainText';
import InputAutocomplete from '../../../components/InputAutocomplete';
import { ReactComponent as LeftArrow } from '../../../icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../../icons/right-arrow.svg';
import Button from '../../../components/Button';
import { useAutocompleteItems } from '../../catalogs/catalogsSlice';
import { setManualTransportInfo } from '../transportSlice';
import InputDate from '../../../components/InputDate';
import { composeValidators, required } from '../../../validators';

const ProtocolCreationTransportHandyForm = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getColorTypes = useAutocompleteItems('vehicleColorTypes');
  const getBodyTypes = useAutocompleteItems('vehicleBodyTypes');
  const getOwnerships = useAutocompleteItems('vehicleOwnerships');
  const getNationalities = useAutocompleteItems('nationalities');

  const handleSubmit = (values) => {
    dispatch(setManualTransportInfo(values));
    navigate('/protocol-creation-form/transport');
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  const validate = (values) => ({
    vehicleNumber: composeValidators(required)(values.vehicleNumber),
    texPassSeries: composeValidators(required)(values.texPassSeries),
    texPassNumber: composeValidators(required)(values.texPassNumber),
    vehicleOwnerTypeId: composeValidators(required)(values.vehicleOwnerTypeId),
    vehicleModel: composeValidators(required)(values.vehicleModel),
    vehicleBodyTypeId: composeValidators(required)(values.vehicleBodyTypeId),
    vehicleColor: composeValidators(required)(values.vehicleColor),
    vehicleBrand: composeValidators(required)(values.vehicleBrand),
  });

  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      {({ handleSubmit: submit }) => (
        <form onSubmit={submit}>
          <Card>
            <Text type="h1" center>{t('Ручной ввод авто')}</Text>
            <InputText
              label={t('ГОС номер авто')}
              name="vehicleNumber"
              asterisk
              parse={(value) => toUpper(value)}
            />
            <div className={styles.document}>
              <div className={styles.documentSn}>
                <Text type="subtitle2" asterisk>{t('Серия и номер техпаспорта')}</Text>
                <div className={styles.documentSnInput}>
                  <div className={styles.documentSeries}>
                    <InputPlainText
                      name="texPassSeries"
                      parse={(value) => toUpper(value)}
                    />
                  </div>
                  <div className={styles.documentNumber}>
                    <InputPlainText name="texPassNumber" />
                  </div>
                </div>
              </div>
              <div className={styles.ownership}>
                <InputAutocomplete
                  label={t('Тип владения')}
                  name="vehicleOwnerTypeId"
                  asterisk
                  options={getOwnerships()}
                />
              </div>
            </div>
            <InputText label={t('Марка авто')} name="vehicleBrand" asterisk />
            <div className={styles.vehicle}>
              <div className={styles.vehicleType}>
                <InputText
                  label={t('Модель')}
                  name="vehicleModel"
                  asterisk
                  options={getBodyTypes()}
                />
              </div>
              <div className={styles.vehicleColor}>
                <InputAutocomplete
                  label={t('Тип кузова')}
                  name="vehicleBodyTypeId"
                  asterisk
                  options={getBodyTypes()}
                />
              </div>
            </div>
            <div className={styles.vehicle}>
              <div className={styles.vehicleType}>
                <InputText
                  label={t('Цвет авто')}
                  name="vehicleColor"
                  asterisk
                />
              </div>
              <div className={styles.vehicleColor}>
                <InputText
                  label={t('Уточнение к цвету')}
                  name="vehicleSubColor"
                />
              </div>
            </div>
            {/* <div className={styles.ownerFio}> */}
            {/*  <div className={styles.ownerFioItem}> */}
            {/*    <InputText label={t('Фамилия')} name="ownerLastName" asterisk /> */}
            {/*  </div> */}
            {/*  <div className={styles.ownerFioItem}> */}
            {/*    <InputText label={t('Имя')} name="ownerFirstName" asterisk /> */}
            {/*  </div> */}
            {/*  <div className={styles.ownerFioItem}> */}
            {/*    <InputText label={t('Отчество')} name="ownerSecondName" asterisk /> */}
            {/*  </div> */}
            {/* </div> */}
            {/* <InputDate name="birthDate" label={t('Дата рождения')} /> */}
            {/* <InputAutocomplete */}
            {/*  label={t('Национальность')} */}
            {/*  name="nationality" */}
            {/*  options={getNationalities()} */}
            {/* /> */}
          </Card>

          <div className={styles.footer}>
            <div className={styles.footerBack}>
              <Button
                type="button"
                variant="grey"
                size="medium"
                fullWidth
                prependIcon={<LeftArrow />}
                onClick={handleClickBack}
              >
                {t('Назад')}
              </Button>
            </div>
            <div className={styles.footerSubmit}>
              <Button
                type="submit"
                variant="purple"
                size="medium"
                fullWidth
                appendIcon={<RightArrow />}
              >
                {t('Сохранить Авто')}
              </Button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default ProtocolCreationTransportHandyForm;
