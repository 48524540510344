import {
  toUpper, trim, last, dropLast, compose, replace,
} from 'ramda';
import { format, isValid, parseISO } from 'date-fns';

export const splitPlateNumber = (plate) => {
  const parsedRoot = {
    region: null,
    number: null,
  };

  if (!plate || plate.length < 8) return parsedRoot;

  const [reg1, reg2, ...rest] = plate;
  const regionCode = `${reg1}${reg2}`;

  const iter = (str, acc, type) => {
    if (str.length === 0) return acc;

    const [currentChar, ...tail] = str;
    const typeOfCurrentChar = Number.isNaN(Number(currentChar)) ? 'string' : 'number';

    if (type !== typeOfCurrentChar) {
      const newAcc = [...acc, [currentChar]];
      return iter(tail, newAcc, typeOfCurrentChar);
    }

    const lastAccItem = last(acc);
    const accWithoutLast = dropLast(1, acc);

    return iter(tail, [...accWithoutLast, [...lastAccItem, currentChar]], typeOfCurrentChar);
  };

  const res = iter(rest, [], null);

  const parseNumber = compose(toUpper, trim);

  return {
    ...parsedRoot,
    region: regionCode,
    number: parseNumber(res.reduce((acc, el) => `${acc} ${el.join('')}`, '')),
  };
};

export const formatDate = (date, withTime) => {
  if (!date) return null;
  const dateObj = parseISO(date);
  if (!isValid(dateObj)) return null;
  return withTime
    ? format(dateObj, 'dd.MM.yyyy (HH:mm)')
    : format(dateObj, 'dd.MM.yyyy');
};

export const formatTime = (date) => {
  if (!date) return null;
  const dateObj = parseISO(date);
  if (!isValid(dateObj)) return null;
  return format(dateObj, 'HH:mm');
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const normalizeNumber = (num) => {
  if (typeof num === 'number') return num;
  return num && num.replace(/[^0-9]/g, '');
};

export const generateFabula = ({
  violationDateParsed,
  violationTimeParsed,
  region,
  district,
  violatorFullName,
  violatorBirthDate,
  violationPart,
  violationType,
  vehicleNumber,
  vehicleBrand,
}) => {
  const fabulaString = `${violationDateParsed} й., ${violationTimeParsed} ВАҚТДА, ${region || ''}, ${district || ''} ҲУДУДИДА ${violatorFullName} ${violatorBirthDate} Й. ТУҒИЛГАН, ${violationPart ? '"' : ''}${violationPart || ''}${violationPart ? '"' : ''} ${violationType ? '(' : ''}${violationType || ''}${violationType ? ')' : ''} МОДДАСИ БЎЙИЧА МАЪМУРИЙ ҲУҚУҚБУЗАРЛИК СОДИР ЭТДИ ${vehicleNumber ? `ДАВЛАТ РАҚАМИ ${vehicleNumber}. РУСИМИ ${vehicleBrand}` : ''}.`;
  return fabulaString;
};

export const kirToLat = (str) => {
  if (!str) return '';
  const kir = new Map([
    ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'], ['д', 'd'], ['е', 'e'],
    ['є', 'e'], ['ё', 'yo'], ['ж', 'j'], ['з', 'z'], ['и', 'i'], ['ї', 'yi'], ['й', 'y'],
    ['к', 'k'], ['л', 'l'], ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'],
    ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'], ['х', 'x'], ['ц', 'c'], ['ч', 'ch'],
    ['ш', 'sh'], ['щ', 'sh'], ['ы', 'i'], ['э', 'e'], ['ю', 'yu'], ['я', 'ya'], ['қ', 'q'],
    ['ҳ', 'h'], ['ҳ', 'h'], ['ќ', 'q'], ['ћ', 'h'], ['ѓ', 'ғ'], ['ъ', '`'], ['ғ', 'g‘'],
    ['ў', 'o‘'],
  ]);

  const filteredStr = str.replace(/[ьЬ]+/g, '');
  /* eslint-disable */
  return Array.from(filteredStr)
    .reduce((s, l) => {
      const mappedLetter = kir.get(l);
      const mappedCAPSLetter = kir.get(l.toLowerCase());
      const isFirstLetter = s[s.length - 1] === ' ' || !s || s.length === 0;
      if (l === 'е' || l === 'Е') {
        return `${s}${isFirstLetter ? (l === 'e' ? 'ye' : 'Ye') : (l === 'e' ? 'e' : 'E')}`
      }
      if (l === 'ц' || l === 'Ц') {
        return `${s}${isFirstLetter ? (l === 'ц' ? 's' : 'S') : (l === 'ц' ? 'ts' : 'TS')}`
      }
      if (mappedLetter) {
        return `${s}${mappedLetter}`;
      }
      if (mappedCAPSLetter) {
        return mappedCAPSLetter.length > 1 ? `${s}${replace(/^./, toUpper)(mappedCAPSLetter)}` : `${s}${mappedCAPSLetter.toUpperCase()}`;
      }
      return `${s}${l}`;
    }, '');
  /* eslint-enable */
};

export const toSom = (string) => {
  const num = Number(string);
  if (!string) return '';
  if (Number.isNaN(num)) return string;
  return String(Math.round(num / 100));
};
export const toTiin = (string) => {
  const num = Number(string);
  if (!string) return string;
  if (Number.isNaN(num)) return string;
  return String(Math.round(num * 100));
};

export const normalizeAttorneys = (attorneys, vehicleNumber) => attorneys.reduce((attorneyAcc, attorney) => {
  const normalizeWithMembers = attorney.members?.map((member) => ({
    ...attorney,
    members: null,
    member,
  }));
  const normalizeWithSubjects = normalizeWithMembers.map((newAttorney) => {
    const activeSubject = newAttorney.subjects?.find((subject) => subject?.vehicleNumber === vehicleNumber);
    return {
      ...newAttorney,
      subjects: null,
      subject: activeSubject,
    };
  });
  const normalizedAttorneys = [
    ...attorneyAcc,
    ...normalizeWithSubjects,
  ];
  return normalizedAttorneys
    .filter((normalAttorney) => normalAttorney?.member?.memberTypeId === 4 || normalAttorney?.member?.memberTypeId === 10 || normalAttorney?.member?.memberTypeId === 85);
}, []);
