import React from 'react';

import { Field } from 'react-final-form';
import FileUpload from './index';

const Adapter = ({
  name,
  onRemove,
  onUpload,
  loading = false,
  ...rest
}) => (
  <Field name={name} {...rest}>
    {({ input, meta }) => (
      <FileUpload
        onUpload={async (file, fileUrl) => {
          const links = await onUpload(file);
          const fileObj = { path: links.data.path, url: fileUrl };
          input.onChange(fileObj);
        }}
        loading={loading}
        file={input.value}
        onRemove={() => {
          input.onChange(undefined);
          if (onRemove) onRemove();
        }}
      />
    )}
  </Field>
);

export default Adapter;
