import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import getError from '../../app/api/getError';
import buildRequestStates from '../../app/buildRequestStates';
import { sleep } from '../../utils';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';

const initialState = {
  ui: {},
  info: {
    techPassports: [],
    insurances: [],
    tintings: [],
    arrest: {},
    taxiLicense: {},
    currentTechPassport: {},
    vehicleInspection: [],
  },
  licenses: [],
};

export const getTransportInfo = createAsyncThunk(
  'transport/getTransportInfo',
  async (_, { rejectWithValue }) => {
    try {
      return sleep(100).then(() => ({
        plateNumber: '01L020RA',
      }));
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const searchTexPassByChip = createAsyncThunk(
  'transport/searchVehicleByChip',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.searchTexPassByChip(), { params });
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const getInsurance = createAsyncThunk(
  'transport/getInsurance',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.insurance(), { params });
      return response.data.objects;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const getVehicleInspection = createAsyncThunk(
  'transport/getVehicleInspection',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.vehicleInspection(), { params });
      return response.data.objects;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const getTinting = createAsyncThunk(
  'transport/getTinting',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.tinting(), { params });
      return response.data.objects;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const getTaxiLicense = createAsyncThunk(
  'transport/getTaxiLicense',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.taxiLicense(), { params });
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const getTechPassport = createAsyncThunk(
  'transport/getTechPassport',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.texpass(), { params });
      return response.data.objects;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const createArrest = createAsyncThunk(
  'transport/createArrest',
  async (body, { rejectWithValue }) => {
    try {
      const response = await httpClient.post(routes.createArrest(), body);
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const transportSlice = createSlice({
  name: 'transport',
  initialState,
  reducers: {
    selectTexPass: (state, { payload: texPass }) => {
      state.info.currentTechPassport = texPass;
    },
    setManualTransportInfo: (state, { payload: values }) => {
      state.info.currentTechPassport = values;
    },
    setTransportNumber: (state, { payload: data }) => {
      state.info = {
        ...state.info,
        ...data,
      };
    },
    resetTransport: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransportInfo.fulfilled, (state, { payload: data }) => {
        state.info = { ...state.info, ...data };
      })
      .addCase(getInsurance.fulfilled, (state, { payload: data }) => {
        state.info.insurances = data;
      })
      .addCase(getTinting.fulfilled, (state, { payload: data }) => {
        state.info.tintings = data;
      })
      .addCase(getTechPassport.fulfilled, (state, { payload: data }) => {
        state.info.techPassports = data;
      })
      .addCase(createArrest.fulfilled, (state, { payload: data }) => {
        state.info.arrest = data;
      })
      .addCase(searchTexPassByChip.fulfilled, (state, { payload: data }) => {
        state.info.currentTechPassport = data;
      })
      .addCase(getVehicleInspection.fulfilled, (state, { payload: data }) => {
        state.info.vehicleInspection = data;
      })
      .addCase(getTaxiLicense.fulfilled, (state, { payload: data }) => {
        state.info.taxiLicense = data;
      });
    buildRequestStates(builder, getTransportInfo);
    buildRequestStates(builder, getInsurance);
    buildRequestStates(builder, getTinting);
    buildRequestStates(builder, getTechPassport);
    buildRequestStates(builder, createArrest);
    buildRequestStates(builder, getVehicleInspection);
    buildRequestStates(builder, getTaxiLicense);
  },
});

export const useTransportInfoState = () => useSelector(
  (state) => state.transport.ui[getTransportInfo.typePrefix],
);

export const useInsurancesState = () => useSelector(
  (state) => state.transport.ui[getInsurance.typePrefix],
);

export const useTintingState = () => useSelector(
  (state) => state.transport.ui[getTinting.typePrefix],
);

export const useVehicleInspectionState = () => useSelector(
  (state) => state.transport.ui[getVehicleInspection.typePrefix],
);

export const useTechPassportState = () => useSelector(
  (state) => state.transport.ui[getTechPassport.typePrefix],
);

export const useTaxiLicenseState = () => useSelector(
  (state) => state.transport.ui[getTaxiLicense.typePrefix],
);

export const useTransportData = () => useSelector((state) => state.transport.info);

export const useInsurances = () => useSelector((state) => state.transport.info.insurances);

export const useTintings = () => useSelector((state) => state.transport.info.tintings);

export const useTechPassports = () => useSelector((state) => state.transport.info.techPassports);

export const useArrest = () => useSelector((state) => state.transport.info.arrest);

export const useSelectedTexPass = () => useSelector((state) => state.transport.info.currentTechPassport);

export const useVehicleInspection = () => useSelector((state) => state.transport.info.vehicleInspection);

export const useTaxiLicense = () => useSelector((state) => state.transport.info.taxiLicense);

export const {
  selectTexPass,
  resetTransport,
  setManualTransportInfo,
  setTransportNumber,
} = transportSlice.actions;

export default transportSlice.reducer;
