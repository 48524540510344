import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { mapObjIndexed, trim } from 'ramda'

const normalizeQueryParams = (values) =>
  mapObjIndexed(
    (value) => (typeof value === 'string' ? trim(value) : value),
    values
  )

const useQuery = () => {
  const location = useLocation()
  const paramsObject = queryString.parse(location.search, {
    arrayFormat: 'comma',
    parseBooleans: true,
  })
  return normalizeQueryParams(paramsObject)
}

export default useQuery
