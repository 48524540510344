import React, { useEffect } from 'react'
// import cn from 'classnames';

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isEmpty, not } from 'ramda'
import styles from './PersonList.module.scss'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import { ReactComponent as InfoIcon } from '../../../icons/info.svg'
import { ReactComponent as LibraryAddIcon } from '../../../icons/library-add.svg'
import {
  openPersonDialog,
  resetForm1List,
  searchManzil,
  selectPerson,
  useForm1List,
  useForm1Pagination,
  useForm1State,
} from '../personSlice'
import { formatDate } from '../../../utils'
import Skeleton from '../../../components/Skeleton'
import useDecoratedHandle from '../../../hooks/useDecoratedHandle'
import httpClient from '../../../app/api/httpClient'
import useQuery from '../../../hooks/useQuery'
import routes from '../../../app/api/routes'
import getError from '../../../app/api/getError'

const PersonList = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const personList = useForm1List()
  const form1State = useForm1State()
  const form1Pagination = useForm1Pagination()
  const { firstName, secondName, lastName, birthFrom, series, number } =
    useQuery()

  const getManzil = useDecoratedHandle(async (pinpp) => {
    await dispatch(searchManzil(pinpp)).unwrap()
  })

  const handleOpenPersonDialog = (person) => async () => {
    await getManzil(person.pinpp)
    dispatch(openPersonDialog(person))
    try {
      httpClient.get(routes.form1(), {
        params: {
          pinpp: person.pinpp,
          fullInfo: true,
          isTablet: true,
          firstName,
          secondName,
          lastName,
          birthFrom,
          series,
          number,
        },
      })
    } catch (e) {
      return getError(e)
    }
  }

  const handleSelectPerson = (person) => () => {
    dispatch(selectPerson(person))
    try {
      httpClient.get(routes.form1(), {
        params: {
          pinpp: person.pinpp,
          isTablet: true,
          fullInfo: true,
          firstName,
          secondName,
          lastName,
          birthFrom,
          series,
          number,
        },
      })
      navigate('/protocol-creation-form/violator')
    } catch (e) {
      return getError(e)
    }
  }

  useEffect(
    () => () => {
      dispatch(resetForm1List())
    },
    []
  )

  const renderFind = () => {
    if (form1State === 'pending') return <Skeleton width={120} height={26} />
    return not(isEmpty(personList)) ? (
      <Text type="h3">
        {t('Найдено')}: {form1Pagination.totalObjects}
      </Text>
    ) : null
  }

  return (
    <div className={styles.foundPersons}>
      <div className={styles.foundPersonsHeader}>{renderFind()}</div>
      {form1State === 'pending' ? (
        <Skeleton width={465} height={80} />
      ) : (
        personList.map((person) => (
          <div className={styles.foundPersonsBody} key={person.id}>
            <div className={styles.foundPersonsCard}>
              <Text type="body1">
                {[
                  person.lastNameLat,
                  person.firstNameLat,
                  person.secondNameLat,
                ].join(' ')}
              </Text>
              <Text type="body1">{formatDate(person.birthDate)}</Text>
              <div className={styles.foundPersonsCardActions}>
                <div className={styles.foundPersonsCardActionsItem}>
                  <Button
                    type="button"
                    variant="action"
                    appendIcon={<InfoIcon />}
                    onClick={handleOpenPersonDialog(person)}
                  >
                    {t('ИНФО')}
                  </Button>
                </div>
                <div className={styles.foundPersonsCardActionsItem}>
                  <Button
                    type="button"
                    variant="purple"
                    appendIcon={<LibraryAddIcon />}
                    onClick={handleSelectPerson(person)}
                  >
                    {t('ВЫБРАТЬ')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default PersonList
