import React from 'react';
// import cn from 'classnames';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './PhotoFixation.module.scss';
import Button from '../../components/Button';
import { ReactComponent as Trash } from '../../icons/trash.svg';
import { ReactComponent as CarIcon } from '../../icons/car.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as CameraIcon } from '../../icons/photo.svg';
import { ReactComponent as BackspaceIcon } from '../../icons/backspace.svg';
import { ReactComponent as GavelIcon } from '../../icons/gavel.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import TopBar from '../../components/TopBar';
import Body from '../../components/Body';
import Card from '../../components/Card';
import CardHeader from '../../components/CardHeader';
import InputText from '../../components/InputText';
import InputAutocomplete from '../../components/InputAutocomplete';
import Divider from '../../components/Divider';
import VehicleInfo from '../../components/VehicleInfo';
import FileUpload from '../../components/FileUpload';

const PhotoFixation = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleResetAll = () => {
    console.log('reset all');
  };

  const handleResetQualification = () => {
    console.log('reset qualification');
  };

  const handleSearch = async (values) => {
    console.log(values);
  };

  const handleClickSaveView = () => {
    console.log('save protocol');
  };

  const handleSearchValidate = (values) => ({});

  const handleSubmitQualification = async (values) => {

  };

  const validateQualification = (values) => {

  };

  return (
    <>
      <TopBar
        title={t('Фотофиксация')}
        onBack={handleBack}
        extra={() => (
          <Button
            type="button"
            onClick={handleResetAll}
            variant="textWhite"
            prependIcon={<Trash />}
          >
            {t('Очистить')}
          </Button>
        )}
      />
      <Body>
        <Card>
          <CardHeader
            label={t('Автотранспорт')}
            icon={<CarIcon />}
            extra1={
              <Form onSubmit={handleSearch} validate={handleSearchValidate}>
                {({ handleSubmit: submit }) => (
                  <form onSubmit={submit} className={styles.searchForm}>
                    <InputText name="plateNumber" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                    <Button
                      type="submit"
                      variant="action"
                      appendIcon={<SearchIcon />}
                      style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderColor: '#BBB5C7' }}
                    >
                      {t('ПОИСК')}
                    </Button>
                  </form>
                )}
              </Form>
            }
          />
          <Divider mt={2} mb={2} />
          <VehicleInfo
          />
        </Card>

        <Card style={{ marginTop: 10 }}>
          <CardHeader label={t('Фото нарушения')} icon={<CameraIcon />} />
          <Divider mt={2} mb={2} />
          <div className={styles.photoUploadBody}>
            <div className={styles.photoUploadBodyItem}>
              <FileUpload />
            </div>
            <div className={styles.photoUploadBodyItem}>
              <FileUpload />
            </div>
          </div>
        </Card>

        <Card style={{ marginTop: 10 }}>
          <CardHeader
            label={t('Правонарушение')}
            icon={<GavelIcon />}
            extra1={
              <Button
                type="button"
                variant="action"
                size="small"
                onClick={handleResetQualification}
                appendIcon={<BackspaceIcon />}
              >
                {t('Очистить')}
              </Button>
            }
          />
          <Divider mt={2} mb={2} />
          <Form onSubmit={handleSubmitQualification} validate={validateQualification}>
            {({ handleSubmit: submit }) => (
              <form onSubmit={submit}>
                <InputAutocomplete label={t('Вид нарушения')} name="violationType" options={[]} asterisk />
                <InputAutocomplete label={t('Район нарушения')} name="region" options={[]} asterisk />
                <InputText name="address" label={t('Адрес нарушения')} asterisk />
              </form>
            )}
          </Form>
        </Card>

        <div className={styles.viewActions}>
          <div className={styles.viewActionsItem}>
            <Button
              type="button"
              variant="purple"
              appendIcon={<DownloadIcon />}
              onClick={handleClickSaveView}
              fullWidth
            >
              {t('Сохранить')}
            </Button>
          </div>
        </div>
      </Body>
    </>
  );
};

export default PhotoFixation;
