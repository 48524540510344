import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { find, propEq, includes } from 'ramda';
import axios from 'axios';
import httpClient from '../../app/api/httpClient';
import routes, { outerServiceRoutes } from '../../app/api/routes';
import getError from '../../app/api/getError';
import buildRequestStates from '../../app/buildRequestStates';

const initialState = {
  ui: {
    personDialog: {
      open: false,
      selected: {},
    },
  },
  list: [],
  pagination: {},
  selectedPerson: {},
  personsByPhoto: [],
  personDriverLicense: {},
  additionalPersonInfo: {
    isAgree: true,
  },
  wantedPersonInfo: [],
};

export const searchByForm1 = createAsyncThunk(
  'person/searchByForm1',
  async (params, { rejectWithValue }) => {
    try {
      const normalizedParams = params;
      const response = await httpClient.get(routes.form1(), {
        params: normalizedParams,
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const searchManzil = createAsyncThunk(
  'person/searchManzil',
  async (pinpp, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.manzil(pinpp));
      return { pinpp, data: response.data };
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const getDriverLicense = createAsyncThunk(
  'person/getDriverLicense',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.driverLicense(), {
        params,
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const getWantedPersonInfo = createAsyncThunk(
  'person/getWantedPersonInfo',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.wantedPersonInfo(), {
        params,
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const sendPhoto = createAsyncThunk(
  'directories/photo/sendPhoto',
  async ({ files, username, faceIdToken = '' }, { rejectWithValue }) => {
    try {
      const logins = [
        'ADM_26000_071_2996',
        'ADM_26000_071_2853',
        'ADM_26000_071_4473',
        'ADM_27000_071_0992',
        'ADM_26000_071_2147',
        'ADM_26000_071_1635',
        'ADM_30091_990_0300',
        'ADM_00000_000_0001',
        'ADM_00000_071_0222',
        'ADM_26000_071_1427',
      ];
      const serviceT = (username && includes(username, logins))
        ? 'Bearer icvjtaor2pypadsiomjvu036lqnt2bb3'
        : `Bearer ${faceIdToken}`;

      // const serviceT = `Bearer icvjtaor2pypadsiomjvu036lqnt2bb3`;
      // const serviceT = `Bearer ${faceIdToken}`;

      const config = {
        headers: {
          Authorization: serviceT,
        },
      };
      const formData = new FormData();
      formData.append('input_image', files[0]);
      const response = await axios.post(outerServiceRoutes.faceId(), formData, config);
      return response.data.results;

    // THIS IS MOCK
    //   [{
      //     birth: '1990-09-30',
      //     name: 'КИМ/KIM ИГОРЬ/IGOR ВОЛЛЕРОВИЧ/VOLLEROVICH',
      //     passport: 'AA-4323816  (2014-02-17 EXP:2024-02-16)',
      //     personal_code: '33009900251385',
      //     photo: null,
      //     rank: 1,
      //     score: 0.761462,
      //   },
      //   {
      //     birth: '1990-09-30',
      //     name: 'КИМ/KIM ИГОРЬ/IGOR ВОЛЛЕРОВИЧ/VOLLEROVICH',
      //     passport: 'AA-4323816  (2014-02-17 EXP:2024-02-16)',
      //     personal_code: '33009900251386',
      //     photo: null,
      //     rank: 1,
      //     score: 0.261462,
      //   },
      // ]
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const searchPersonByIdChip = createAsyncThunk(
  'form1/searchPersonByIdChip',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.searchPersonByIdChip(), { params });
      return response.data;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const searchOnePersonByIdChip = createAsyncThunk(
  'form1/searchOnePersonByIdChip',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.searchOnePersonByIdChip(), { params });
      return response.data.object;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const searchPersonByDriverLicenseChip = createAsyncThunk(
  'form1/searchPersonByDriverLicenseChip',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.searchPersonByDriverLicenseChip(), { params });
      return response.data;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const personSlice = createSlice({
  name: 'form1',
  initialState,
  reducers: {
    resetForm1: () => initialState,
    resetForm1List: (state) => ({
      ...state,
      ui: {
        personDialog: {
          open: false,
          selected: {},
        },
      },
      list: [],
      pagination: {},
    }),
    selectPerson: (state, { payload: person }) => {
      state.selectedPerson = person;
    },
    resetSelectedPerson: (state) => {
      state.selectedPerson = {};
    },
    openPersonDialog: (state, { payload: person }) => {
      state.ui.personDialog.open = true;
      state.ui.personDialog.selected = person;
    },
    closePersonDialog: (state) => {
      state.ui.personDialog.open = false;
      state.ui.personDialog.selected = {};
    },
    setAdditionalPersonInfo: (state, { payload: data }) => ({
      ...state,
      additionalPersonInfo: {
        ...state.additionalPersonInfo,
        ...data,
      },
    }),
    setViolatorAgreement: (state, { payload: value }) => {
      state.additionalPersonInfo.isAgree = value;
    },
    setViolatorSign: (state, { payload: sign }) => {
      state.additionalPersonInfo.sign = sign;
    },
    setInspectorSign: (state, { payload: sign }) => {
      state.additionalPersonInfo.inspectorSign = sign;
    },
    resetAdditionalPersonInfo: (state) => {
      state.additionalPersonInfo = {
        isAgree: true,
      };
    },
    setExplanatoryText: (state, { payload: text }) => {
      state.additionalPersonInfo.explanatory = text;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchByForm1.fulfilled, (state, { payload: data }) => {
        state.pagination = data.pagination;
        state.list = data.objects;
      })
      .addCase(searchManzil.fulfilled, (state, { payload: { data, pinpp } }) => {
        const newList = state.list.map((person) => {
          if (person.pinpp !== pinpp) return person;
          return { ...person, manzilAddresses: data.objects };
        });
        return {
          ...state,
          list: newList,
        };
      })
      .addCase(searchPersonByIdChip.fulfilled, (state, { payload: data }) => {
        state.pagination = data.pagination;
        state.list = data.objects;
      })
      .addCase(getDriverLicense.fulfilled, (state, { payload: data }) => {
        state.personDriverLicense = data.objects;
      })
      .addCase(sendPhoto.fulfilled, (state, { payload: data }) => {
        state.personsByPhoto = data;
      })
      .addCase(getWantedPersonInfo.fulfilled, (state, { payload: data }) => {
        state.wantedPersonInfo = data.objects;
      })
      .addCase(searchPersonByDriverLicenseChip.fulfilled, (state, { payload: data }) => {
        state.selectedPerson = data.object;
      })
      .addCase(searchOnePersonByIdChip.fulfilled, (state, { payload: data }) => {
        state.selectedPerson = data;
      });
    buildRequestStates(builder, searchByForm1);
    buildRequestStates(builder, searchPersonByIdChip);
    buildRequestStates(builder, getDriverLicense);
    buildRequestStates(builder, sendPhoto);
    buildRequestStates(builder, searchPersonByDriverLicenseChip);
  },
});

export const useForm1List = () => useSelector((state) => state.form1.list);

export const useForm1PersonById = (id) => useSelector(
  (state) => find(propEq('id', id))(state.form1.list),
);

export const useForm1PersonByDocument = (series, number) => useSelector(
  (state) => find((person) => propEq('series', series)(person) && propEq('number', number)(person))(state.form1.list),
);

export const useSelectedPerson = () => useSelector(
  (state) => state.form1.selectedPerson,
);

export const usePersonDriverLicense = () => useSelector(
  (state) => state.form1.personDriverLicense,
);

export const useWantedPersonInfo = () => useSelector(
  (state) => state.form1.wantedPersonInfo,
);

export const useAdditionalPersonInfo = () => useSelector(
  (state) => state.form1.additionalPersonInfo,
);

export const useViolatorAgreement = () => useSelector(
  (state) => state.form1.additionalPersonInfo.isAgree,
);

export const useViolatorSign = () => useSelector(
  (state) => state.form1.additionalPersonInfo.sign,
);

export const useInspectorSign = () => useSelector(
  (state) => state.form1.additionalPersonInfo.inspectorSign,
);

export const useForm1Pagination = () => useSelector((state) => state.form1.pagination);

export const useForm1State = () => useSelector((state) => state.form1.ui[searchByForm1.typePrefix]);

export const usePersonDriverLicenseState = () => useSelector((state) => state.form1.ui[getDriverLicense.typePrefix]);

export const usePersonDialogOpenState = () => useSelector(
  (state) => state.form1.ui.personDialog,
);

export const useSendPhotoState = () => useSelector((state) => state.form1.ui[sendPhoto.typePrefix]);

export const usePersonsByPhoto = () => useSelector((state) => state.form1.personsByPhoto);

export const {
  resetForm1,
  resetForm1List,
  openPersonDialog,
  closePersonDialog,
  selectPerson,
  resetSelectedPerson,
  setAdditionalPersonInfo,
  resetAdditionalPersonInfo,
  setViolatorAgreement,
  setViolatorSign,
  setInspectorSign,
  setExplanatoryText,
} = personSlice.actions;

export default personSlice.reducer;
