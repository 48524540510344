import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { find } from 'ramda';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { searchByForm1, selectPerson, usePersonsByPhoto } from './personSlice';
import Text from '../../components/Text';
import styles from './ViolatorRouter.module.scss';
import SearchByPhotoCard from '../../components/SearchByPhotoCard';

const FoundPersonByPhotoContainer = () => {
  const [t] = useTranslation();
  const personsByPhoto = usePersonsByPhoto();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getScoringColor = useCallback((scoring) => {
    const colors = [
      {
        name: 'danger',
        check: (percent) => percent < 40,
      },
      {
        name: 'warning',
        check: (percent) => percent < 65,
      },
      {
        name: 'success',
        check: (percent) => percent > 65,
      },
    ];

    return find((color) => color.check(scoring), colors).name;
  }, []);

  const handleSearchByPhoto = (pinpp) => async () => {
    const { objects: persons } = await dispatch(searchByForm1({ pinpp })).unwrap();
    dispatch(selectPerson(persons[0]));
    navigate(-1);
  };
  return (
    <div>
      { personsByPhoto.length > 0
        ? (
          personsByPhoto.map((person) => (
            <div
              key={person.personal_code}
              className={styles.searchCardItem}
            >
              <SearchByPhotoCard
                photoUrl={person.photo}
                fio={person.name}
                birthDate={person.birth}
                pinfl={person.personal_code}
                score={(person.score * 100).toFixed(2)}
                color={getScoringColor(person.score * 100)}
                onClick={handleSearchByPhoto(person.personal_code)}
              />
            </div>
          ))
        ) : (
          <Text>
            {t('Лица не найдены')}
          </Text>
        )
      }
    </div>
  );
};

export default FoundPersonByPhotoContainer;
