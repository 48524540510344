import React from 'react';
import cn from 'classnames';

import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import Text from '../Text';

const AdditionalLicenseCard = ({
  icon,
  status = '——',
  type = '——',
  serial = '——',
  dateFrom = '——',
  dateTo = '——',
}) => {
  const [t] = useTranslation();

  const statusClasses = cn({
    [styles.licenseCard]: true,
    [styles.active]: status === 'active',
    [styles.overdue]: status === 'overdue',
    [styles.absent]: status === 'absent',
    [styles.inactive]: status === 'inactive',
  });

  return (
    <div className={statusClasses}>
      <div className={styles.header}>
        <div className={styles.licenseType}>
          {icon}
          <span style={{ marginLeft: '4px' }}>{type}</span>
        </div>
        <div className={styles.licenseStatus}>{status}</div>
      </div>
      {
        status !== 'absent'
          ? (
           <>
             <div className={styles.serial}>{serial}</div>
             <div className={styles.dates}>
               <div className={styles.dateFrom}>
                 <span className={styles.dateToLabel}>{t('От')}:</span>
                 {' '}
                 <span className={styles.dateToBody}>{dateFrom}</span>
               </div>
               <div className={styles.dateTo}>
                 <span className={styles.dateToLabel}>{t('До')}:</span>
                 {' '}
                 <span className={styles.dateToBody}>{dateTo}</span>
               </div>
             </div>
           </>
          ) : null
      }
    </div>
  );
};

export default AdditionalLicenseCard;
