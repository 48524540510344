import React, { useState } from 'react';
import cn from 'classnames';
import {
  find, identity, prop, propEq,
} from 'ramda';
import './Autocomplete.scss';
import { FaChevronDown } from 'react-icons/fa';
import ChooseModal from './ChooseModal';

const Autocomplete = ({
  label = '',
  asterisk = false,
  error = null,
  options = [],
  onChange = identity,
  value,
  ...rest
}) => {
  const [openModal, setOpenModal] = useState(false);
  const wrapperClasses = cn(
    'input-wrapper',
    error && 'input-error',
  );

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChoose = (id) => {
    onChange(id);
    handleCloseModal();
  };

  const defaultOption = find(propEq('id', value), options);
  const defaultValue = prop('label', defaultOption);

  return (
    <div className={wrapperClasses}>
      {label && (
        <div className="input-label">
          {label} {asterisk && <span className="input-asterisk">*</span>}
        </div>
      )}
      <div className='inputContainer'>
        <input
          className="input"
          value={defaultValue || ''}
          onChange={onChange}
          {...rest}
        />
        <div className='icon'>
          <FaChevronDown />
        </div>
      </div>
      <div className="autocomplete-open-button" onClick={rest.disabled ? identity : handleOpenModal} />
      {
        openModal && (
          <ChooseModal
            options={options}
            onClose={handleCloseModal}
            onChoose={handleChoose}
            defaultId={value}
          />
        )
      }
      {
        error && (
          <div className="input-error-text">
            {error}
          </div>
        )
      }
    </div>
  );
};

export default Autocomplete;
