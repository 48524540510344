import React from 'react';
import { Field } from 'react-final-form';
import styles from './RFFValidationError.module.scss';

const RFFValidationError = ({ name }) => (
  <Field
    autoComplete="off"
    name={name}
    subscription={{ touched: true, error: true }}
    render={
      ({ meta: { touched, error } }) => (
        touched && error ? <span className={styles.error}>{error}</span> : null
      )
    }
  />
);

export default RFFValidationError;
