import React from 'react';
import cn from 'classnames';
import Spinner from 'react-svg-spinner';
import styles from './index.module.scss';

const Button = ({
  type = 'button',
  variant = 'transparent',
  fullWidth = false,
  fullHeight = false,
  size = 'normal',
  prependIcon = null,
  appendIcon = null,
  loading = false,
  style = {},
  children,
  disabled,
  ...rest
}) => {
  const classes = cn({
    [styles.button]: true,
    [styles.transparent]: variant === 'transparent',
    [styles.purple]: variant === 'purple',
    [styles.lightBlue]: variant === 'lightBlue',
    [styles.grey]: variant === 'grey',
    [styles.text]: variant === 'text',
    [styles.action]: variant === 'action',
    [styles.textWhite]: variant === 'textWhite',
    [styles.normal]: size === 'normal',
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.large]: size === 'large',
    [styles.fullWidth]: fullWidth,
    [styles.fullHeight]: fullHeight,
  });

  return (
    <button type={type} className={classes} style={style} {...rest} disabled={loading || disabled}>
      {prependIcon ? <span>{prependIcon}</span> : null}
      <span className={styles.buttonText}>
        {children}
      </span>
      {
        loading && (
          <span className={styles.spinner}>
            <Spinner size="16px" thickness={3} color="#404045" />
          </span>
        )
      }
      {appendIcon ? <span>{appendIcon}</span> : null}
    </button>
  );
};

export default Button;
