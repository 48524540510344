import React from 'react';
// import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './DecisionCard.module.scss';
import Card from '../Card';
import LabeledText from '../LabeledText';
import StatusLabel from '../StatusLabel';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { ReactComponent as AttachMailIcon } from '../../icons/attach-mail.svg';
import { ReactComponent as MailSentIcon } from '../../icons/mail-sent.svg';
import Button from '../Button';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { downloadFileGeneratedPDF, useFileRequestState } from '../../features/files/filesSlice';
import routes from '../../app/api/routes';
import { useCatalogsName } from '../../features/catalogs/catalogsSlice';
import Text from '../Text';
import { formatDate, toSom } from '../../utils';

const DecisionCard = ({
  decision = {},
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const fileDownloadState = useFileRequestState();
  const getPunishmentTypeName = useCatalogsName('punishmentTypes');
  const getAdmStatusName = useCatalogsName('admStatuses');
  const getArticlePartName = useCatalogsName('articleParts');

  const handleDownloadProtocol = useDecoratedHandle(async () => {
    await dispatch(downloadFileGeneratedPDF({ src: routes.decisionPDF(decision.id) })).unwrap();
  });

  return (
    <Card style={{ marginBottom: 10 }}>
      <div className={styles.title}>
        <Text type='h3' className={styles.redColor}>{getPunishmentTypeName(decision.mainPunishmentTypeId)}</Text>
      </div>
      <div className={styles.header}>
        <div className={styles.headerItem}>
          <Text type="subtitle1">{t('Номер квитанции')}</Text>
          <Text type="body1" className={styles.redColor}>{decision.penaltyInvoiceSerial || '——'}</Text>
        </div>
        <div className={styles.headerItem}>
          <StatusLabel
            type={decision.statusType}
            text={getAdmStatusName(decision.statusId)}
          />
        </div>
      </div>
      <div className={styles.amount}>
        <div className={styles.amountItem}>
          <LabeledText label={t('Сумма штрафа')} text={decision.mainPunishmentAmountText} />
        </div>
        <div className={styles.amountItem}>
          <LabeledText label={t('Сумма со скидкой 70%')} text={toSom(decision.penaltyDiscountAmount)} />
        </div>
        <div className={styles.amountItem}>
          <LabeledText label={t('Скидка действительна до')} text={formatDate(decision.penaltyDiscountForDate)} />
        </div>
      </div>
      <div className={styles.payment}>
        <div className={styles.paymentItem}>
          <LabeledText label={t('Сумма оплаты')} text={toSom(decision.penaltyPaidAmount)} />
        </div>
        <div className={styles.paymentItem}>
          <LabeledText label={t('Дата оплаты')} text={formatDate(decision.penaltyLastPayTime, true)} />
        </div>
      </div>
      <div className={styles.violator}>
        <div className={styles.violatorItem}>
          <LabeledText label={t('Нарушитель')} text={[decision.violatorSecondNameLat, decision.violatorFirstNameLat, decision.violatorLastNameLat].join(' ')} />
        </div>
      </div>
      <div className={styles.serial}>
        <div className={styles.serialItem}>
          <LabeledText label={t('Решение')} text={[decision.series, decision.number].join('-')} />
        </div>
        <div className={styles.serialItem}>
          <LabeledText label={t('Основная статья')} text={getArticlePartName(decision.articlePartId)} />
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.actionsItem}>
          <Button
            type="button"
            variant="action"
            size="medium"
            appendIcon={<DownloadIcon />}
            loading={fileDownloadState === 'pending'}
            onClick={handleDownloadProtocol}
          >
            {t('Скачать решение')}
          </Button>
        </div>
        <div className={styles.actionsItem}>
          {
            decision.isMailSent
              ? (
                <span className={styles.mailSent}>
                  <span className={styles.mailSentText}>{t('Решение отправлено!')}</span>
                  <span className={styles.mailSentIcon}>
                    <MailSentIcon />
                  </span>
                </span>
              ) : (
                <Button
                  type="button"
                  variant="action"
                  size="medium"
                  appendIcon={<AttachMailIcon />}
                  disabled
                >
                  {t('Отправить решение по почте!')}
                </Button>
              )
          }
        </div>
      </div>
    </Card>
  );
};

export default DecisionCard;
