import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import styles from '../../views/Entry/Entry.module.scss';
import InputUsername from '../../components/InputUsername';
import Button from '../../components/Button';
import { ReactComponent as ArrowRight } from '../../icons/arrow.svg';
import { composeValidators, required } from '../../validators';
import { login, loginByUsername, useLoginRequestState } from './authDataSlice';
import { useAppRoutes } from '../app/appSlice';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { ReactComponent as UsernameIcon } from '../../icons/username.svg';
import RFFValidationError from '../../components/RFFValidationError';
import { ReactComponent as LockIcon } from '../../icons/lock.svg';
import TextInput from '../../components/InputText/TextInput';

const AuthFormByUsername = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const mainRoute = useAppRoutes('Main');
  const navigate = useNavigate();
  const loginRequest = useLoginRequestState();

  const handleSubmit = useDecoratedHandle(async (values) => {
    localStorage.setItem('WorkCSerialN', values.workCertificateSn);
    localStorage.setItem('loginBy', 'rest');
    const normalizedValues = {
      username: values.username,
      password: values.password,
    };
    await dispatch(loginByUsername(normalizedValues)).unwrap();
    document.body.classList.remove('no-bg');
    navigate(mainRoute);
  });

  const validate = (values) => ({
    username: composeValidators(required)(values.username),
    password: composeValidators(required)(values.password),
  });

  const initialValues = {};

  return (
    <Form onSubmit={handleSubmit} validate={validate} initialValues={initialValues}>
      {({ handleSubmit: submit }) => (
        <form onSubmit={submit} className={styles.formContainer}>
          <div className={styles.label}>{t('Логин')}</div>
          <div className={styles.formGroup}>
            <div className={styles.formInputIcon}>
              <UsernameIcon />
            </div>
            <Field name="username">
              {({ input, meta }) => (
                <NumberFormat
                  format="ADM_#####_###_####"
                  mask="*"
                  onChange={input.onChange}
                  value={input.value}
                  placeholder="ADM_*****_***_****"
                  className={styles.formInputField}
                  error={meta.touched && meta.error ? meta.error : null}
                />
              )}
            </Field>
          </div>
          <div className={styles.label}>{t('Пароль')}</div>
          <div className={styles.formGroup}>
            <div className={styles.formInputIcon}>
              <LockIcon />
            </div>
            <Field name="password">
              {({ input, meta }) => (
                <input
                  type="password"
                  className={styles.formInputField}
                  onChange={input.onChange}
                  value={input.value}
                  placeholder={t('Введите пароль')}
                />
              )}
            </Field>
          </div>
          <div className={styles.submitButton}>
            <Button
              type="submit"
              variant="purple"
              fullWidth
              loading={loginRequest === 'pending'}
              appendIcon={<ArrowRight />}
            >
              {t('Войти')}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

export default AuthFormByUsername;
