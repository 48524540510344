import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { FieldArray } from 'react-final-form-arrays'
import { useForm } from 'react-final-form'
import { FiMapPin } from 'react-icons/fi'
import { useGetMe, useRaid } from '../authData/authDataSlice'
import { useAutocompleteItems } from '../catalogs/catalogsSlice'
import { useRegisteredArticlePartViolationsToAutocomplete } from '../dependentCatalogs/dependentCatalogsSlice'
import { useCreateMainProtocolState } from './protocolCreationsSlice'
import { getLocationAddress, useGPSState } from '../services/servicesSlice'
import useDecoratedHandle from '../../hooks/useDecoratedHandle'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import { ReactComponent as GavelIcon } from '../../icons/gavel.svg'
import Button from '../../components/Button'
import { ReactComponent as BackspaceIcon } from '../../icons/backspace.svg'
import Divider from '../../components/Divider'
import InputAutocomplete from '../../components/InputAutocomplete'
import styles from './ProtocolCreationQualification/ProtocolCreationQualification.module.scss'
import { ReactComponent as TrashIcon } from '../../icons/trash2.svg'
import Text from '../../components/Text'
import { ReactComponent as AddIcon } from '../../icons/add.svg'
import InputText from '../../components/InputText'
import AdditionalFabula from './ProtocolCreationQualification/AdditionalFabula'
import ProtocolCreationPhotos from './ProtocolCreationPhotos'
import { ReactComponent as SaveIcon } from '../../icons/save.svg'
import AgreementForm from '../person/AgreementForm'
import SignHere from '../../components/SignHere'
import { 
  setInspectorSign, setViolatorSign, useInspectorSign, useViolatorSign
} from '../person/personSlice'
import { ReactComponent as EditIcon } from '../../icons/edit.svg'

const QualificationContainer = ({ submit }) => {
  const [t] = useTranslation()
  const [user] = useGetMe()
  const raid = useRaid()
  const dispatch = useDispatch()
  const getDistricts = useAutocompleteItems('districts')
  const getRegions = useAutocompleteItems('regions')
  const registeredArticlePartViolations =
    useRegisteredArticlePartViolationsToAutocomplete()
  const protocolCreationState = useCreateMainProtocolState()
  const gpsDataState = useGPSState()
  const form = useForm()
  const chosenSign = useViolatorSign()
  const chosenInspectorSign = useInspectorSign()
  const getGPS = useDecoratedHandle(async (settings) => {
    // eslint-disable-next-line no-nested-ternary
    const lat =
      window.EmiInterface && window.EmiInterface.getLocation()
        ? JSON.parse(window.EmiInterface.getLocation())
          ? JSON.parse(window.EmiInterface.getLocation()).latitude
          : null
        : null

    // eslint-disable-next-line no-nested-ternary
    const long =
      window.EmiInterface && window.EmiInterface.getLocation()
        ? JSON.parse(window.EmiInterface.getLocation())
          ? JSON.parse(window.EmiInterface.getLocation()).longitude
          : null
        : null

    const gpsData = await dispatch(getLocationAddress({ lat, long }))
    if (!settings.ignoreError) {
      if (!gpsData.payload.address || !lat || !long) {
        alert(
          `Невозможно определить локацию. \n Latitude: ${lat} \n Longitude: ${long}. \n Ответ: ${JSON.stringify(
            gpsData.payload,
            0,
            2
          )}`
        )
        return
      }
    }
    form.change('address', gpsData.payload.address)
    // console.log(gpsData.address);
  })

  const handleSign = (base64) => {
    dispatch(setViolatorSign(base64))
  }

  const handleInspectorSign = (base64) => {
    dispatch(setInspectorSign(base64))
  }

  useEffect(() => {
    getGPS({ ignoreError: true })
  }, [])

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Card style={{ marginTop: 10 }}>
              <CardHeader
                label={t('Правонарушение')}
                icon={<GavelIcon />}
                extra1={
                  <Button
                    type="button"
                    variant="lightBlue"
                    size="small"
                    style={{ borderRadius: 3 }}
                    prependIcon={<BackspaceIcon />}
                    onClick={form.reset}
                  >
                    {t('Очистить')}
                  </Button>
                }
              />
              <Divider color="#E1F0FF" />
              <InputAutocomplete
                label={t('Вид нарушения')}
                name="violationType"
                asterisk
                options={registeredArticlePartViolations}
              />
              <Divider mt={4} mb={4} />
              <FieldArray name="additionalArticles">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <div key={name} className={styles.additionalViolation}>
                        <div className={styles.additionalViolationItem}>
                          <InputAutocomplete
                            label={t('Дополнительное нарушение')}
                            name={`${name}.violationType`}
                            asterisk
                            options={registeredArticlePartViolations}
                          />
                        </div>
                        <div className={styles.additionalViolationItem}>
                          <Button
                            type="button"
                            variant="lightBlue"
                            fullWidth
                            style={{
                              color: '#F00',
                              position: 'relative',
                              top: -2,
                            }}
                            appendIcon={<TrashIcon />}
                            onClick={() => fields.remove(index)}
                          />
                        </div>
                      </div>
                    ))}
                    <div className={styles.additionalViolationAdd}>
                      <Text type="subtitle2">{t('Дополнительные статьи')}</Text>
                      <Button
                        type="button"
                        variant="action"
                        size="small"
                        prependIcon={<AddIcon />}
                        onClick={() =>
                          fields.push({ violationType: undefined })
                        }
                      >
                        {t('Добавить дополнительное нарушение')}
                      </Button>
                    </div>
                  </>
                )}
              </FieldArray>
              <Divider mt={4} mb={4} />
              <InputAutocomplete
                label={t('Область нарушения')}
                name="regionId"
                asterisk
                options={getRegions()}
              />
              <InputAutocomplete
                label={t('Район нарушения')}
                name="district"
                asterisk
                options={getDistricts((d) =>
                  raid.isActive
                    ? String(d.regionId) === raid.regionId
                    : d.regionId === user.regionId
                )}
              />
              <div className={styles.protocolGPSField}>
                <InputText
                  label={t('Адрес нарушения')}
                  name="address"
                  disabled={gpsDataState === 'pending'}
                  asterisk
                />
                <button
                  type="button"
                  className={styles.protocolGPSButton}
                  onClick={() => getGPS({ ignoreError: false })}
                >
                  <FiMapPin />
                </button>
              </div>
              <AdditionalFabula />
              <AgreementForm />
            </Card>
            <ProtocolCreationPhotos />

            <Card style={{ marginTop: 10 }}>
              <CardHeader
                icon={<EditIcon />}
                label={t('Электронная подпись нарушителя')}
              />
              <SignHere onSave={handleSign} image={chosenSign} />
            </Card>
            <Card style={{ marginTop: 10, marginBottom: 10 }}>
              <CardHeader
                icon={<EditIcon />}
                label={t('Электронная подпись инспектора')}
              />
              <SignHere onSave={handleInspectorSign} image={chosenInspectorSign} />
            </Card>
            <div className={styles.protocolAction}>
              <Button
                onClick={submit}
                variant="purple"
                appendIcon={<SaveIcon />}
                fullWidth
                style={{ fontSize: '17px' }}
                loading={protocolCreationState === 'pending'}
              >
                {protocolCreationState === 'pending'
                  ? t('Подождите')
                  : t('Сохранить')}
              </Button>
            </div>
          </>
        }
      />
    </Routes>
  )
}

export default QualificationContainer
