import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../components/VehicleInfo/VehicleInfo.module.scss';
import Skeleton from '../../components/Skeleton';
import LabeledText from '../../components/LabeledText';
import { useCatalogsName } from '../catalogs/catalogsSlice';

const TexPassContent = ({ texPass }) => {
  const [t] = useTranslation();
  const getVehicleBodyType = useCatalogsName('vehicleBodyTypes');
  const getVehicleOwnerships = useCatalogsName('vehicleOwnerships');

  const nameByOwnerType = {
    0: [texPass.vehicleOwnerLastName, texPass.vehicleOwnerFirstName, texPass.vehicleOwnerSecondName].join(' '),
    1: texPass.vehicleOwnerOrganizationName,
  };

  return texPass
    ? (
      <div className={styles.mainData}>
        <div className={styles.mainDataItem}>
          <LabeledText label={t('Владелец транспорта')} text={nameByOwnerType[texPass.vehicleOwnerTypeId]} />
        </div>
        <div className={styles.mainDataItem}>
          <LabeledText label={t('Техпаспорт')} text={[texPass.texPassSeries, texPass.texPassNumber].join(' ')} />
        </div>
        <div className={styles.mainDataItem}>
          <LabeledText label={t('Лицо')} text={getVehicleOwnerships(String(texPass.vehicleOwnerTypeId))} />
        </div>
        <div className={styles.mainDataItem}>
          <LabeledText label={t('Марка')} text={texPass.vehicleModel} />
        </div>
        <div className={styles.mainDataItem}>
          <LabeledText label={t('Тип транспорта')} text={getVehicleBodyType(texPass.vehicleBodyTypeId)} />
        </div>
        <div className={styles.mainDataItem}>
          <LabeledText label={t('Цвет')} text={texPass.vehicleColor} />
        </div>
      </div>
    ) : null;
};

export default TexPassContent;
