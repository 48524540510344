import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './ProtocolsAndResolutions.module.scss';
import TopBar from '../../components/TopBar';
import Button from '../../components/Button';
import { ReactComponent as ExitIcon } from '../../icons/exit.svg';
import Body from '../../components/Body';
import { useAppRoutes } from '../../features/app/appSlice';
import Tabs from '../../components/Tabs';
import ProtocolList from '../../features/protocolList/ProtocolList';
import DecisionList from '../../features/decisionList/DecisionList';

const ProtocolsAndResolutions = () => {
  const [t] = useTranslation();
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('protocols');

  const handleBack = () => {
    navigate(appRoutes.Main);
  };

  const tabs = [
    { key: 'protocols', title: t('Мои протоколы') },
    { key: 'decisions', title: t('Мои решения') },
  ];

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <TopBar
        onBack={handleBack}
        title={t('Мои дела')}
      />
      <Body>
        <Tabs
          activeTab={activeTab}
          onTabClick={onTabClick}
          tabs={tabs}
        />
        {
          activeTab === 'protocols' && (
            <ProtocolList />
          )
        }
        {
          activeTab === 'decisions' && (
            <DecisionList />
          )
        }
      </Body>
    </>
  );
};

export default ProtocolsAndResolutions;
