import React from 'react';
// import cn from 'classnames';
import { Route, Routes } from 'react-router-dom';
import TransportForm from './TransportForm';
import TransportContainer from './TransportContainer';
import TransportHandy from './TransportHandy';
import ParkingFine from './ParkingFine';

const TransportRouter = ({ onChange }) => (
    <Routes>
      <Route path="/" element={ <TransportContainer /> } />
      <Route path='transport' element={ <TransportForm onChange={onChange} /> } />
      <Route path='transport/handy' element={ <TransportHandy /> } />
      <Route path='transport/parking-fine' element={ <ParkingFine /> } />
    </Routes>
);

export default TransportRouter;
