import React from 'react';
// import cn from 'classnames';

import styles from './index.module.scss';

const MenuItem = ({
  icon,
  text,
  onClick,
  disabled,
  color = '#FCF3CE',
}) => (
    <button
      type="button"
      onClick={onClick}
      className={styles.button}
      disabled={disabled}
      style={{
        backgroundColor: color,
      }}
    >
      <span className={styles.icon}>{icon}</span>
      <span className={styles.text}>{text}</span>
    </button>
);

export default MenuItem;
