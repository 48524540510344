import React from 'react';
// import cn from 'classnames';
import { Field } from 'react-final-form';

import { useTranslation } from 'react-i18next';
import styles from './AdditionalFabula.module.scss';
import InputTextArea from '../../../components/InputTextArea';
import { ReactComponent as RateIcon } from '../../../icons/rate.svg';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import useToggler from '../../../hooks/useToggler';

const AdditionalFabula = () => {
  const [t] = useTranslation();
  const [open, handleOpen, handleClose] = useToggler();

  return (
  // <Field name="fabulaAdditional">
  //   {({ input }) => (
        <>
          <Button
            type="button"
            variant="action"
            fullWidth
            appendIcon={<RateIcon />}
            onClick={handleOpen}
          >
            {t('Дополнительная информация')}
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            title={t('Дополнительная информация')}
          >
            <InputTextArea
              label={t('Дополнительная информация')}
              name="fabulaAdditional"
              asterisk
              expandOnFocus={false}
              rows={6}
            />
            <div className={styles.additionalFabulaFooter}>
              <Button
                type="button"
                variant="purple"
                fullWidth
                onClick={handleClose}
              >
                {t('OK')}
              </Button>
            </div>
          </Dialog>
        </>
  //   )}
  // </Field>
  );
};

export default AdditionalFabula;
