import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from '../../views/Entry/Entry.module.scss';
import InputUsername from '../../components/InputUsername';
import Button from '../../components/Button';
import { ReactComponent as ArrowRight } from '../../icons/arrow.svg';
import { composeValidators, required } from '../../validators';
import { login, useLoginRequestState } from './authDataSlice';
import { useAppRoutes } from '../app/appSlice';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';

const AuthForm = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const mainRoute = useAppRoutes('Main');
  const navigate = useNavigate();
  const loginRequest = useLoginRequestState();

  const handleSubmit = useDecoratedHandle(async (values) => {
    localStorage.setItem('WorkCSerialN', values.workCertificateSn);
    const normalizedValues = {
      workCertificate: `${values.workCertificateSn}${values.workCertificateNo}`,
      password: values.password,
    };
    await dispatch(login(normalizedValues)).unwrap();
    document.body.classList.remove('no-bg');
    navigate(mainRoute);
  });

  const validate = (values) => ({
    workCertificateNo: composeValidators(required)(values.workCertificateNo),
    password: composeValidators(required)(values.password),
  });

  const initialValues = {
    workCertificateSn: localStorage.getItem('WorkCSerialN'),
  };

  return (
    <Form onSubmit={handleSubmit} validate={validate} initialValues={initialValues}>
      {({ handleSubmit: submit }) => (
        <form onSubmit={submit} className={styles.formContainer}>
          <InputUsername disabled={loginRequest === 'pending'} />
          <div className={styles.submitButton}>
            <Button
              type="submit"
              variant="purple"
              fullWidth
              loading={loginRequest === 'pending'}
              appendIcon={<ArrowRight />}
            >
              {t('Войти')}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

export default AuthForm;
