import Example from './Example';
import Entry from './Entry';
import Main from './Main';
import ProtocolsAndResolutions from './ProtocolsAndResolutions';
import PhotoFixation from './PhotoFixation';
import ProtocolCreationForm from './ProtocolCreationForm';

export const appRoutes = {
  Example: '/example',
  Entry: '/',
  Main: '/main',
  ProtocolsAndResolutions: '/protocols-and-resolutions',
  ProtocolCreationForm: '/protocol-creation-form',
  PhotoFixation: '/photo-fixation',
};

// export const appRoutes = viewList
//   .reduce((acc, view) => ({ ...acc, [view.viewname]: view.pathname }), {});

export default [
  { pathname: '/example', View: Example },
  { pathname: '/', View: Entry },
  { pathname: '/main', View: Main },
  { pathname: '/protocols-and-resolutions', View: ProtocolsAndResolutions },
  { pathname: '/protocol-creation-form/*', View: ProtocolCreationForm },
  { pathname: '/photo-fixation', View: PhotoFixation },
];
