import React from 'react';
import Spinner from 'react-svg-spinner';
import { ReactComponent as Trash2 } from '../../icons/trash2.svg';
import { ReactComponent as PhotoUpload } from '../../icons/photo-upload.svg';

import styles from './FileUpload.module.scss';

const FileUpload = ({
  onUpload,
  onRemove,
  loading = false,
  file = {},
}) => {
  const handleLoad = async (e) => {
    const fileUrl = URL.createObjectURL(e.target.files[0]);
    await onUpload(e.target.files[0], fileUrl);
  };

  return (
    <div className={styles.container}>
      {
        loading && (
          <div className={styles.progress}>
            <div className={styles.progressLoader}>
              <Spinner size="56px" thickness={3} color="#404045" />
            </div>
          </div>
        )
      }
      <img src={file.url} alt="" className={styles.imagePreview} />
      {
        file.url
          ? null
          : (
            <input type="file" onChange={handleLoad} className={styles.inputFile} />
          )
      }
      {
        file.url
          ? (
            <button
              type="button"
              className={styles.removeButton}
              onClick={onRemove}
            >
              <Trash2 />
            </button>
          ) : (
            <div className={styles.uploadIcon}>
              <PhotoUpload />
            </div>
          )
      }
    </div>
  );
};

export default FileUpload;
