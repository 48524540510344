import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import Dialog from '../../components/Dialog';
import styles from './ProtocolCreation.module.scss';
import Button from '../../components/Button';
import { createSimplifiedResolution, resetQualification, useCaseMovement } from './protocolCreationsSlice';
import { toSom, toTiin } from '../../utils';
import { resetAttorneys } from '../attorneys/attorneysSlice';
import { resetTransport } from '../transport/transportSlice';
import { resetWanted } from '../wanted/wantedSlice';
import { resetAdditionalPersonInfo, resetSelectedPerson } from '../person/personSlice';
import { useAppRoutes } from '../app/appSlice';
import { downloadFileGeneratedPDF, useFileRequestState } from '../files/filesSlice';
import routes from '../../app/api/routes';
import { useCatalogsName } from '../catalogs/catalogsSlice';

const MakeDecisionConfirmDialog = ({
  isOpen,
  handleClose,
}) => {
  const [t] = useTranslation();
  const caseMovement = useCaseMovement();
  const dispatch = useDispatch();
  const amountPenalty = toSom(caseMovement.simplified?.maxAmountPenalty);
  const fileRequestState = useFileRequestState();
  const getArticlePartName = useCatalogsName('articleParts');
  const getViolationTypeName = useCatalogsName('violationTypes');
  const articleText = `${getViolationTypeName(caseMovement.simplified?.articleViolationTypeId)} ${getArticlePartName(caseMovement.simplified?.articlePartId)}`;
  const { admCaseId } = caseMovement;
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();

  const handleRefuce = () => {
    dispatch(resetQualification());
    dispatch(resetAttorneys());
    dispatch(resetTransport());
    dispatch(resetWanted());
    dispatch(resetSelectedPerson());
    dispatch(resetAdditionalPersonInfo());
    navigate(appRoutes.Main, { replace: true });
    handleClose();
  };

  const handleConfirm = async () => {
    const normalizer = {
      admCaseId,
      amountPenalty: caseMovement.simplified?.maxAmountPenalty,
      signature: null,
    };
    const decision = await dispatch(createSimplifiedResolution(normalizer)).unwrap();
    await dispatch(downloadFileGeneratedPDF({ src: routes.decisionPDF(decision.id) })).unwrap();
    toast.success(t('Решение вынесенно'));
    dispatch(resetQualification());
    dispatch(resetAttorneys());
    dispatch(resetTransport());
    dispatch(resetWanted());
    dispatch(resetSelectedPerson());
    dispatch(resetAdditionalPersonInfo());
    navigate(appRoutes.Main, { replace: true });
    handleClose();
  };
  return (
    <Dialog
      open={isOpen}
      title=''
      onClose={handleRefuce}
    >
      <div className={styles.confirmWrapper}>
        <div className={styles.infoIcon}>
          <InfoIcon />
        </div>
        <div className={styles.infoText}>
          {t('Статья 332 Прим 1. Упрощенный порядок исполнения постановления о наложении штрафа')}
        </div>
        <div className={styles.articleText}>
          {articleText}
        </div>
        <div className={styles.amount}>
          {amountPenalty} {t('сумов')}
        </div>
        <div className={styles.footer}>
          <div className={styles.confirmReject}>
            <Button
              type="button"
              variant="grey"
              size="medium"
              fullWidth
              disabled={fileRequestState === 'pending'}
              onClick={handleRefuce}
            >
              {t('Нет')}
            </Button>
          </div>
          <div className={styles.confirmSubmit}>
            <Button
              type="button"
              variant="purple"
              size="medium"
              onClick={handleConfirm}
              loading={fileRequestState === 'pending'}
              fullWidth
            >
              {t('Да')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default MakeDecisionConfirmDialog;
