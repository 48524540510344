import React from 'react';
// import cn from 'classnames';
import styles from './DecisionList.module.scss';
import { useDecisionList } from './decisionListSlice';
import DecisionCard from '../../components/DecisionCard';
import { useGetMe } from '../authData/authDataSlice';
import Skeleton from '../../components/Skeleton';

const DecisionList = () => {
  const [user] = useGetMe();
  const [decisions, isLoading] = useDecisionList({
    userId: user.id, size: 20, page: 0, sort: 'id,desc',
  });

  return (
    <div className={styles.decisions}>
      {
        isLoading
          ? <Skeleton width={470} height={300} />
          : decisions.list.map((decision) => (
              <DecisionCard decision={decision} key={decision.id} />
          ))
      }
    </div>
  );
};

export default DecisionList;
