import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { format, isValid, parse } from 'date-fns';
import './DateInput.scss';
import NumberFormat from 'react-number-format';
import { ReactComponent as Calendar } from '../../icons/calendar.svg';

const DateInput = ({
  label = '',
  asterisk = false,
  withTime = false,
  error = '',
  onChange = () => {},
  value = '',
  onBlur = () => {},
  disabled = false,
  ...rest
}) => {
  const [date, setDate] = useState(new Date(value));

  useEffect(() => {
    setDate(new Date(value));
  }, [value]);

  const wrapperClasses = cn(
    'input-wrapper',
    error && 'input-error',
  );

  const handleSetValue = (e) => {
    onBlur(e);
    const newDate = parse(e.target.value, withTime ? 'dd.MM.yyyy (HH:mm)' : 'dd.MM.yyyy', new Date());
    if (isValid(newDate)) {
      setDate(newDate);
      onChange(newDate);
      return;
    }
    onChange(null);
    setDate({});
  };

  return (
    <div className={wrapperClasses}>
      {label && (
        <div className="input-label">
          {label} {asterisk && <span className="input-asterisk">*</span>}
        </div>
      )}
      <div className="input-calendar-wrapper">
        <NumberFormat
          format={withTime ? '##.##.#### (##:##)' : '##.##.####'}
          mask="_"
          value={
            value
              ? format(
                date,
                withTime ? 'dd.MM.yyyy (HH:mm)' : 'dd.MM.yyyy',
              ) : ''
          }
          onBlur={handleSetValue}
          className="input"
          type="tel"
          disabled={disabled}
          {...rest}
        />
        <input
          type={withTime ? 'datetime-local' : 'date'}
          disabled={disabled}
          className="input-calendar-btn"
          value={
            isValid(date)
              ? format(date, withTime ? 'yyyy-MM-dd\'T\'HH:mm:ss.SSS' : 'yyyy-MM-dd')
              : ''
          }
          onChange={(e) => {
            const newDate = parse(e.target.value, withTime ? 'yyyy-MM-dd\'T\'HH:mm' : 'yyyy-MM-dd', new Date());
            if (isValid(newDate)) {
              setDate(newDate);
              onChange(newDate);
              return;
            }
            setDate({});
            onChange(null);
          }}
          tabIndex={-1}
        />
        <button type="button" className="input-calendar-fake" tabIndex={-1}>
          <Calendar />
        </button>
      </div>
      {
        error && (
          <div className="input-error-text">
            {error}
          </div>
        )
      }
    </div>
  );
};

export default DateInput;
