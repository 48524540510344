import React from 'react';
// import cn from 'classnames';

import styles from './ProtocolList.module.scss';
import { useProtocolList } from './protocolListSlice';
import ProtocolCard from '../../components/ProtocolCard';
import { useGetMe } from '../authData/authDataSlice';
import Skeleton from '../../components/Skeleton';

const ProtocolList = () => {
  const [user] = useGetMe();
  const [protocols, isLoading] = useProtocolList({
    userId: user.id, size: 20, page: 0, sort: 'registrationTime,desc',
  });

  return (
    <div className={styles.protocols}>
      {
        isLoading
          ? <Skeleton width={470} height={300} />
          : protocols.list.map((protocol) => (
            <ProtocolCard protocol={protocol} key={protocol.id} />
          ))
      }
    </div>
  );
};

export default ProtocolList;
