import React from 'react';

import { Field } from 'react-final-form';
import TextInput from './TextInput';

const Adapter = ({
  name,
  label,
  asterisk,
  parse,
  ...rest
}) => (
    <Field name={name} parse={parse}>
      {({ input, meta }) => (
        <TextInput
          label={label}
          asterisk={asterisk}
          error={meta.touched && meta.error}
          onChange={input.onChange}
          value={input.value}
          {...rest}
        />
      )}
    </Field>
);

export default Adapter;
