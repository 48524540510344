import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import imgPlaceholder from '../../../assets/img-placeholder.png';
import {
  getImageURL, setMemoFileUrl, useImageLoadingState, useMemoFileUrls,
} from '../filesSlice';
import Skeleton from '../../../components/Skeleton';

const Img = ({
  src,
  memo = false,
  placeholder = imgPlaceholder,
  skeleton = {
    width: 72,
    height: 83,
  },
  ...rest
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const memoFileUrl = useMemoFileUrls(src);
  const imageLoadingState = useImageLoadingState();

  useEffect(() => {
    const setImage = async () => {
      if (!src) {
        ref.current?.setAttribute('src', placeholder);
        return null;
      }

      if (memoFileUrl) {
        ref.current?.setAttribute('src', memoFileUrl);
        return null;
      }

      try {
        const fileUrl = await dispatch(getImageURL({ src })).unwrap();
        ref.current?.setAttribute('src', fileUrl);
        if (memo) {
          dispatch(setMemoFileUrl({ src, fileUrl }));
        }
        return null;
      } catch (e) {
        if (ref.current) ref.current.setAttribute('src', placeholder);
        return null;
      }
    };
    if (ref) setImage();
  }, [src, ref]);

  return (
    <>
      {imageLoadingState === 'pending' ? <Skeleton width={skeleton.width} height={skeleton.height} /> : null }
      <img {...rest} ref={ref} alt="" style={{ width: '100%' }} />
    </>
  );
  // return imageLoadingState === 'pending'
  //   ? <Skeleton width={skeleton.width} height={skeleton.height} />
  //   : <img {...rest} ref={ref} alt="" style={{ width: '100%' }} />;
};

export default Img;
