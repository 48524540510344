import React from 'react';
// import cn from 'classnames';

import styles from './index.module.scss';
import Text from '../Text';

const LabeledText = ({ label, text }) => {

  return (
    <div className={styles.wrapper}>
      <Text type="subtitle1">{label}</Text>
      <Text type="body1">{text || '——'}</Text>
    </div>
  );
};

export default LabeledText;
