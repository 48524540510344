import React from 'react';
import cn from 'classnames';

import styles from './Card.module.scss';

const Card = ({
  children,
  style = {},
  className = '',
}) => {
  const cardClasses = cn(styles.card, className);
  return (
    <div className={cardClasses} style={style}>
      {children}
    </div>
  );
};

export default Card;
