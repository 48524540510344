import React from 'react';
// import cn from 'classnames';

import { identity } from 'ramda';
import styles from './SearchActions.module.scss';
import Button from '../Button';
import FileButton from '../FileButton';

const SearchActions = ({
  actions = [],
}) => (
    <div className={styles.actions}>
      {
        actions.filter(identity).map(({
          id,
          label,
          onClick,
          Icon,
          loading,
          disabled,
          type,
        }) => {
          const types = {
            file: (
              <FileButton
                onChange={onClick}
                label={label}
                loading={loading}
                icon={<Icon />}
              />
            ),
            button: (
            <Button
              type="button"
              variant="lightBlue"
              onClick={onClick}
              prependIcon={<Icon />}
              fullWidth
              loading={loading}
              style={{ paddingLeft: 4, paddingRight: 4 }}
              disabled={disabled}
              size="large"
            >
              {label}
            </Button>
            ),
          };
          return (
            <div className={styles.actionsItem} key={id}>
              {types[type]}
            </div>
          );
        })
      }
    </div>
);

export default SearchActions;
