import React from 'react';
// import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { isFuture, parseISO } from 'date-fns';
import { isEmpty, not } from 'ramda';
import styles from './VehicleInfo.module.scss';
import VehicleNumber from '../VehicleNumber';
import Button from '../Button';
import { ReactComponent as GavelIcon } from '../../icons/gavel2.svg';
import { ReactComponent as ShieldIcon } from '../../icons/shield.svg';
import { ReactComponent as CarIcon } from '../../icons/car2.svg';
import AdditionalLicenseCard from '../AdditionalLicenseCard';
import LabeledText from '../LabeledText';
import Skeleton from '../Skeleton';
import { formatDate } from '../../utils';
import { useCatalogsName } from '../../features/catalogs/catalogsSlice';

const VehicleInfo = ({
  plateNumber,
  insurances = [],
  insurancesLoading,
  tintings = [],
  tintingsLoading,
  taxiLicense = {},
  taxiLicenseLoading,
  vehicleInspection = [],
  vehicleInspectionLoading,
  techPassportLoading,
  ownerName,
  vehicleYear,
  techPassSN = '—',
  violatorType = '—',
  vehicleModel = '—',
  vehicleType = '—',
  color = '—',
  onViolationHistoryClick,
  vehicleInfoLoading,
  violationHistoryLoading,
}) => {
  const [t] = useTranslation();
  const licenseLoadings = insurancesLoading && tintingsLoading && vehicleInspectionLoading && taxiLicenseLoading;
  const getTintingCategories = useCatalogsName('tintingCategories');
  return (
    <>
      <div className={styles.plate}>
        <div className={styles.plateItem}>
          {
            vehicleInfoLoading
              ? <Skeleton width={156} height={42} />
              : <VehicleNumber plateNumber={plateNumber} />
          }
        </div>
        <div className={styles.plateItem}>
          {
            vehicleInfoLoading
              ? <Skeleton width={222} height={34} />
              : (
                <Button
                  type="button"
                  variant="transparent"
                  onClick={() => onViolationHistoryClick(plateNumber)}
                  prependIcon={<GavelIcon />}
                  fullWidth
                  style={{ paddingLeft: 4, paddingRight: 4, fontSize: 13 }}
                  loading={violationHistoryLoading}
                >
                  {t('История правонарушений')}
                </Button>
              )
          }
        </div>
      </div>
      <div className={styles.otherLicenses}>
        {
          licenseLoadings
            ? <Skeleton width={222} height={110} />
            : (
              <>
                {
                  insurances.length > 0
                    ? insurances.map((license, i) => (
                      <div className={styles.otherLicensesItem} key={i}>
                        <AdditionalLicenseCard
                          icon={<ShieldIcon />}
                          status={isFuture(parseISO(license.toDate)) ? 'active' : 'overdue'}
                          type={t('Страховка')}
                          serial={[license.policySeries, license.policyNumber].join(' ')}
                          dateFrom={formatDate(license.fromDate)}
                          dateTo={formatDate(license.toDate)}
                        />
                      </div>
                    )) : (
                      <div className={styles.otherLicensesItem}>
                        <AdditionalLicenseCard
                          icon={<ShieldIcon />}
                          status="inactive"
                          type={t('Страховка')}
                          serial={t('Не найдено')}
                          dateFrom="—"
                          dateTo="—"
                        />
                      </div>
                    )
                }
                {
                  tintings.length > 0
                    ? tintings.map((tinting, i) => (
                      <div className={styles.otherLicensesItem} key={i}>
                        <AdditionalLicenseCard
                          icon={<CarIcon />}
                          status={isFuture(parseISO(tinting.toDate)) ? 'active' : 'overdue'}
                          type={t('Тонировка')}
                          serial={getTintingCategories(tinting.tintingCategoryId) || t('Тип не определен')}
                          dateFrom={formatDate(tinting.fromDate)}
                          dateTo={formatDate(tinting.toDate)}
                        />
                      </div>
                    )) : (
                      <div className={styles.otherLicensesItem}>
                        <AdditionalLicenseCard
                          icon={<CarIcon />}
                          status="inactive"
                          type={t('Тонировка')}
                          serial={t('Не найдено')}
                          dateFrom="—"
                          dateTo="—"
                        />
                      </div>
                    )
                }
                {
                  vehicleInspection.length > 0
                    ? vehicleInspection.map((inspection, i) => (
                      <div className={styles.otherLicensesItem} key={i}>
                        <AdditionalLicenseCard
                          icon={<CarIcon />}
                          status={inspection.isInspectionPass ? 'active' : 'overdue'}
                          type={t('Тех осмотр')}
                          serial={inspection.isInspectionPass ? t('Пройден') : t('Не пройден')}
                          dateFrom={formatDate(inspection.inspectionTime)}
                          dateTo={formatDate(inspection.nextInspectionTime)}
                        />
                      </div>
                    )) : (
                      <div className={styles.otherLicensesItem}>
                        <AdditionalLicenseCard
                          icon={<CarIcon />}
                          status="inactive"
                          type={t('Тех осмотр')}
                          serial={t('Не найдено')}
                          dateFrom="—"
                          dateTo="—"
                        />
                      </div>
                    )
                }
                {
                  not(isEmpty(taxiLicense))
                    ? (
                      <div className={styles.otherLicensesItem}>
                        <AdditionalLicenseCard
                          icon={<CarIcon />}
                          status={isFuture(parseISO(taxiLicense.toDate)) ? 'active' : 'overdue'}
                          type={t('Лицензия на пассажирские перевозки')}
                          serial={taxiLicense.number}
                          dateFrom={formatDate(taxiLicense.fromDate)}
                          dateTo={formatDate(taxiLicense.toDate)}
                        />
                      </div>
                    ) : (
                      <div className={styles.otherLicensesItem}>
                        <AdditionalLicenseCard
                          icon={<CarIcon />}
                          status="inactive"
                          type={t('Лицензия на пассажирские перевозки')}
                          serial={t('Не найдено')}
                          dateFrom="—"
                          dateTo="—"
                        />
                      </div>
                    )
                }
              </>
            )
        }
      </div>
      <div className={styles.mainData}>
        {
          ownerName && (
            <div className={styles.mainDataItem}>
              {
                techPassportLoading
                  ? <Skeleton width={452} height={29} />
                  : <LabeledText label={t('Владелец транспорта')} text={ownerName} />
              }
            </div>
          )
        }
        <div className={styles.mainDataItem}>
          {
            techPassportLoading
              ? <Skeleton width={120} height={29} />
              : <LabeledText label={t('Техпаспорт')} text={techPassSN} />
          }
        </div>
        <div className={styles.mainDataItem}>
          {
            techPassportLoading
              ? <Skeleton width={120} height={29} />
              : <LabeledText label={t('Лицо')} text={violatorType} />
          }
        </div>
        <div className={styles.mainDataItem}>
          {
            techPassportLoading
              ? <Skeleton width={120} height={29} />
              : <LabeledText label={t('Год выпуска авто')} text={vehicleYear} />
          }
        </div>
        <div className={styles.mainDataItem}>
          {
            techPassportLoading
              ? <Skeleton width={120} height={29} />
              : <LabeledText label={t('Марка')} text={vehicleModel} />
          }
        </div>
        <div className={styles.mainDataItem}>
          {
            techPassportLoading
              ? <Skeleton width={120} height={29} />
              : <LabeledText label={t('Тип транспорта')} text={vehicleType} />
          }
        </div>
        <div className={styles.mainDataItem}>
          {
            techPassportLoading
              ? <Skeleton width={120} height={29} />
              : <LabeledText label={t('Цвет')} text={color} />
          }
        </div>
      </div>
    </>
  );
};

export default VehicleInfo;
