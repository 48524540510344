import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty, not } from 'ramda';
import { useDispatch } from 'react-redux';
import ViolatorDetailsCard from '../../../components/ViolatorDetailsCard';
import {
  getDriverLicense, getWantedPersonInfo,
  usePersonDriverLicense,
  usePersonDriverLicenseState,
  useSelectedPerson, useWantedPersonInfo,
} from '../personSlice';
import { formatDate } from '../../../utils';
import { useAddressToString, useCatalogsName } from '../../catalogs/catalogsSlice';
import DriverLicenseModal from '../DriverLicenseModal';
import useDecoratedHandle from '../../../hooks/useDecoratedHandle';
import {
  getPunishmentByPinpp,
  getRadarPunishments,
  openPunishmentDialog,
  setPunishmentQueryType,
  usePunishmentsByPinppState,
  usePunishmentsState,
  useRadarPunishmentsState,
} from '../../punishments/punishmentsSlice';

const ViolatorDetailsCardContainer = () => {
  const [t] = useTranslation();
  const selectedPerson = useSelectedPerson();
  const addressToString = useAddressToString();
  const dispatch = useDispatch();
  const getGenderName = useCatalogsName('genders');
  const personDriverLicense = usePersonDriverLicense();
  const personDriverLicenseState = usePersonDriverLicenseState();
  const punishmentRequestState = usePunishmentsByPinppState();
  const radarPunishmentsState = useRadarPunishmentsState();
  const wantedPersonInfo = useWantedPersonInfo();
  const handleViolationHistory = useDecoratedHandle(async (pinpp) => {
    const normalizedValue = {
      pinpp,
    };
    await dispatch(getPunishmentByPinpp(pinpp));
    await dispatch(getRadarPunishments(normalizedValue));
    dispatch(setPunishmentQueryType('pinpp'));
    dispatch(openPunishmentDialog());
  });
  useEffect(() => {
    const getViolatorsInfo = async () => {
      const normalizer = {
        // pinpp: selectedPerson.pinpp,
        // serial: selectedPerson.series,
        // number: selectedPerson.number,
        birthDate: selectedPerson.birthDate,
        firstName: selectedPerson.firstNameLat,
        lastName: selectedPerson.lastNameLat,
        secondName: selectedPerson.secondNameLat,
      };
      await dispatch(getDriverLicense(normalizer));
      await dispatch(getWantedPersonInfo({ ...normalizer, pinpp: selectedPerson.pinpp }));
    };
    if (not(isEmpty(selectedPerson)) && selectedPerson.pinpp) {
      getViolatorsInfo();
    }
  }, [JSON.stringify(selectedPerson)]);

  return not(isEmpty(selectedPerson)) ? (
    <ViolatorDetailsCard
      photo={selectedPerson.personPhotoUrl}
      fullName={[selectedPerson.lastNameLat, selectedPerson.firstNameLat, selectedPerson.secondNameLat].join(' ')}
      birthDate={formatDate(selectedPerson.birthDate)}
      documentSn={[selectedPerson.series, selectedPerson.number].join('-')}
      givenDate={formatDate(selectedPerson.givenDate)}
      givenPlace={addressToString(selectedPerson.givenAddress)}
      pinpp={selectedPerson.pinpp}
      gender={getGenderName(selectedPerson.genderId)}
      citizenship={selectedPerson.pinpp ? t('УЗБЕКИСТАН') : null}
      onViolationHistoryClick={handleViolationHistory}
      violationHistoryLoading={punishmentRequestState === 'pending' || radarPunishmentsState === 'pending'}
      isWanted={wantedPersonInfo.length > 0}
      driverLicenseDialogButton={
        <DriverLicenseModal
          licenses={personDriverLicense}
          loading={personDriverLicenseState === 'pending'}
        />
      }
    />
  ) : (
    <div style={{ color: '#C62828' }}>
      {t('Выберите Нарушителя')}
    </div>
  );
};

export default ViolatorDetailsCardContainer;
