import React from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

const StatusLabel = ({ text, type }) => {
  const classes = cn({
    [styles.label]: true,
    [styles.warning]: type === 'warning',
    [styles.danger]: type === 'danger',
    [styles.primary]: type === 'primary',
    [styles.success]: type === 'success',
    [styles.grey]: type === 'grey',
    [styles.info]: type === 'info',
  });

  return (
    <span className={classes}>{text}</span>
  );
};

export default StatusLabel;
