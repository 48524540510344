import React, { useEffect, useState } from 'react';
// import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './Main.module.scss';
import { ReactComponent as ExitIcon } from '../../icons/exit.svg';
import { ReactComponent as CameraIcon } from '../../icons/camera.svg';
import { ReactComponent as PaperIcon } from '../../icons/paper.svg';
import { ReactComponent as ProfilerIcon } from '../../icons/profile.svg';
import { ReactComponent as UnlockIcon } from '../../icons/unlock.svg';
import { ReactComponent as Person } from '../../icons/person-walking-solid.svg';
import TopBar from '../../components/TopBar';
import Button from '../../components/Button';
import Body from '../../components/Body';
import UserInfoCard from '../../features/authData/UserInfoCard';
import MenuItem from '../../components/MenuItem';
import { useAppRoutes } from '../../features/app/appSlice';
import { resetWanted } from '../../features/wanted/wantedSlice';
import { resetTransport } from '../../features/transport/transportSlice';
import { resetAttorneys } from '../../features/attorneys/attorneysSlice';
import { resetCreatedProtocol, resetQualification } from '../../features/protocolCreations/protocolCreationsSlice';
import {
  resetAdditionalPersonInfo,
  resetForm1,
  resetForm1List,
  resetSelectedPerson, useSelectedPerson,
} from '../../features/person/personSlice';
import {
  getMyECPKey,
  openEcpPasswordDialog, removeUserData,
  setMyECPPrivateKey,
  useGetMe,
} from '../../features/authData/authDataSlice';
import config from '../../config';
import TransportByNumberDialogForm from '../../features/transport/TransportByNumberDialogForm';
import useToggler from '../../hooks/useToggler';
import {
  getPunishmentByPinpp,
  getPunishmentByPlateNumber,
  openPunishmentDialog,
} from '../../features/punishments/punishmentsSlice';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { removeMemoFileUrl } from '../../features/files/filesSlice';
import FAB from '../../features/app/FAB';

const Main = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const [userInfo] = useGetMe();
  const [openByNumber, handleOpenByNumber, handleCloseByNumber] = useToggler();

  const handleExit = () => {
    localStorage.removeItem('TOKEN');
    localStorage.removeItem('ECPPAS');
    dispatch(removeMemoFileUrl());
    dispatch(removeUserData());
    navigate(appRoutes.Entry, { replace: true });
  };

  const handleClickPhotoFixation = () => {
    navigate(appRoutes.PhotoFixation);
  };

  const handleClickProtocol = () => {
    navigate('/protocol-creation-form');
  };

  const handleClickPedestrian = () => {
    navigate('/protocol-creation-form/pedestrian');
  };

  const handleClickCheckAuto = () => {
    navigate('/protocol-creation-form/transport');
  };

  const handleClickCheckPerson = () => {
    navigate('/protocol-creation-form/violator?hideFields=true');
  };

  const handleSearchByNumber = () => {
    handleOpenByNumber();
  };
  const handleViolationHistory = useDecoratedHandle(async (vehicleNumber) => {
    dispatch(openPunishmentDialog());
    await dispatch(getPunishmentByPlateNumber(vehicleNumber)).unwrap();
  });

  const handleReset = () => {
    dispatch(resetWanted());
    dispatch(resetTransport());
    dispatch(resetAttorneys());
    dispatch(resetQualification());
    dispatch(resetCreatedProtocol());
    dispatch(resetSelectedPerson());
    dispatch(resetForm1List());
    dispatch(resetForm1());
    dispatch(resetAdditionalPersonInfo());
  };

  useEffect(() => {
    handleReset();
  }, []);

  useEffect(() => {
    const getECPKey = async () => {
      const result = await dispatch(getMyECPKey()).unwrap();
      if (result.objects.length > 0) {
        await dispatch(setMyECPPrivateKey()).unwrap();
      }
    };
    if (userInfo.mustProvideDigitalSignature) {
      getECPKey();
    }
  }, [userInfo.mustProvideDigitalSignature]);

  return (
    <>
      <TopBar

        extra={() => (
          <Button
            type="button"
            variant="lightBlue"
            size="small"
            prependIcon={<ExitIcon />}
            onClick={handleExit}
            style={{ paddingTop: 8, paddingBottom: 8, borderRadius: 4 }}
          />
        )}
      />
      <Body>
        <UserInfoCard />
        <div className={styles.menu}>
          <div className={styles.meuItem}>
            <MenuItem
              text={t('Проверка ЛИЦА')}
              onClick={handleClickCheckPerson}
              icon={<ProfilerIcon />}
              color="#DDF5FE"
            />
          </div>
          <div className={styles.meuItem}>
             {/* { */}
             {/* config.disableProtocolCreation */}
             {/*   ? ( */}
             {/*     <MenuItem */}
             {/*       text={t('Проверка АВТО')} */}
             {/*       onClick={handleSearchByNumber} */}
             {/*       icon={<UnlockIcon />} */}
             {/*       color="#FCEBE3" */}
             {/*     /> */}
             {/*   ) */}
             {/*   : ( */}
             {/*     <MenuItem */}
             {/*       text={t('Проверка АВТО')} */}
             {/*       onClick={handleClickCheckAuto} */}
             {/*       icon={<UnlockIcon />} */}
             {/*       color="#FCEBE3" */}
             {/*     /> */}
             {/*   ) */}
             {/* } */}
            <MenuItem
              text={t('Проверка АВТО')}
              onClick={handleClickCheckAuto}
              icon={<UnlockIcon />}
              color="#FCEBE3"
            />
          </div>
          <div className={styles.meuItem}>
            <MenuItem
              text={t('Пешеход')}
              onClick={handleClickPedestrian}
              icon={<Person />}
              disabled={config.disableProtocolCreation}
              color="#EEEAFC"
            />
          </div>
          <div className={styles.meuItem}>
            <MenuItem
              text={t('Протокол')}
              onClick={handleClickProtocol}
              icon={<PaperIcon />}
              disabled={config.disableProtocolCreation}
              color="#DDF6F0"
            />
          </div>
          {/* <div className={styles.meuItem}> */}
          {/*  <MenuItem */}
          {/*    text={t('Фотофиксация')} */}
          {/*    onClick={handleClickPhotoFixation} */}
          {/*    icon={<CameraIcon />} */}
          {/*    disabled={config.disableProtocolCreation} */}
          {/*    color="#FCF3CE" */}
          {/*  /> */}
          {/* </div> */}

          {userInfo.username === 'ADM_30091_990_0300' && (
            <div>
              <button
                type="button"
                onClick={() => {
                  window.setAutoLicense = (serial) => {
                    alert(serial);
                  };
                  if (!window.EmiInterface) {
                    alert('Нет подходящего оборудования');
                    return;
                  }
                  window.EmiInterface.readAutoLicense();
                }}
              >
                Прочитать код чипа
              </button>
            </div>
          )}
          <FAB />
        </div>
        <TransportByNumberDialogForm
          open={openByNumber}
          handleClose={handleCloseByNumber}
          onSubmit={handleViolationHistory}
          simple
        />
      </Body>
    </>
  );
};

export default Main;
