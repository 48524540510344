import React from 'react';
// import cn from 'classnames';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isEmpty, not } from 'ramda';
import styles from './PersonDialog.module.scss';
import Dialog from '../../../components/Dialog';
import Text from '../../../components/Text';
import LabeledText from '../../../components/LabeledText';
import imagePlaceholder from '../../../assets/profile.png';
import Button from '../../../components/Button';
import {
  closePersonDialog,
  useForm1PersonByDocument,
  useForm1PersonById,
  usePersonDialogOpenState,
} from '../personSlice';
import { formatDate } from '../../../utils';
import { useAddressToString, useCatalogsName } from '../../catalogs/catalogsSlice';
import Img from '../../files/Img';

const PersonDialog = () => {
  const [t] = useTranslation();
  const { open, selected } = usePersonDialogOpenState();
  const person = useForm1PersonByDocument(selected.series, selected.number);
  const getGenderName = useCatalogsName('genders');
  const addressToString = useAddressToString();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closePersonDialog());
  };

  const labelAddressByType = {
    PERMANENT: t('Адрес (Постоянная прописка)'),
    TEMPORARY: t('Адрес (Временная регистрация)'),
  };

  return person
    ? (
      <Dialog
        open={open}
        title={t('Гражданин')}
        onClose={handleClose}
      >
        <div className={styles.personDialog}>
          <div className={styles.personDialogPhoto}>
            <Img
              src={person.personPhotoUrl}
              placeholder={imagePlaceholder}
            />
          </div>
          <div className={styles.personDialogName}>
            <Text type="body1">
              {[person.lastNameLat, person.firstNameLat, person.secondNameLat].join(' ')}
            </Text>
            <Text type="body1">
              {formatDate(person.birthDate)}
            </Text>
            <div className={styles.personDialogDocument}>
              <div className={styles.personDialogDocumentItem}>
                <LabeledText label={t('Серия и номер док')} text={[person.series, person.number].join('-')} />
              </div>
              <div className={styles.personDialogDocumentItem}>
                <LabeledText label={t('Дата выдачи')} text={formatDate(person.givenDate)} />
              </div>
              <div className={styles.personDialogDocumentItem}>
                <LabeledText label={t('ПИНФЛ')} text={person.pinpp} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.personDialogBody}>
          <div className={styles.personDialogGender}>
            <Text type="body1">{getGenderName(person.genderId)}</Text>
          </div>
          {
            not(isEmpty(person.manzilAddresses))
              ? person.manzilAddresses?.map((address, i) => (
                <div className={styles.personDialogCountry} key={i}>
                  <LabeledText label={labelAddressByType[address.type]} text={addressToString(address)} />
                </div>
              )) : (
                <div className={styles.personDialogAddress}>
                  <Text type="body1">{'——'}</Text>
                </div>
              )
          }
        </div>
        <div className={styles.personDialogFooter}>
          <div className={styles.personDialogFooterItem}>
            <Button
              type="button"
              variant="purple"
              onClick={handleClose}
              size="medium"
              fullWidth
            >
              {t('ЗАКРЫТЬ')}
            </Button>
          </div>
        </div>
      </Dialog>
    )
    : null;
};

export default PersonDialog;
