import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toUpper } from 'ramda';
import styles from './ViolatorRouter.module.scss';
import { ReactComponent as LeftArrow } from '../../icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../icons/right-arrow.svg';
import { useAutocompleteItems } from '../catalogs/catalogsSlice';
import Card from '../../components/Card';
import Text from '../../components/Text';
import InputText from '../../components/InputText';
import InputAutocomplete from '../../components/InputAutocomplete';
import Button from '../../components/Button';
import DateInput from '../../components/InputDate/DateInput';
import InputDate from '../../components/InputDate';
import { selectPerson } from './personSlice';
import {
  composeValidators, dateGreaterThan1900, required, validateIf, dateLessThanNow,
} from '../../validators';
import InputPlainText from '../../components/InputPlainText';

const ViolatorCreationHandyForm = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getNationalities = useAutocompleteItems('nationalities');
  const getGenders = useAutocompleteItems('genders');
  const getCitizenshipTypes = useAutocompleteItems('citizenshipTypes');
  const getPersonDocumentTypes = useAutocompleteItems('personDocumentTypes');
  const getCountries = useAutocompleteItems('countries');
  const getRegions = useAutocompleteItems('regions');
  const getDistricts = useAutocompleteItems('districts');

  const handleSubmit = (values) => {
    dispatch(selectPerson(values));
    navigate(-1);
  };

  const validate = (values) => ({
    secondNameKir: composeValidators(required)(values.secondNameKir),
    firstNameKir: composeValidators(required)(values.firstNameKir),
    lastNameKir: composeValidators(required)(values.lastNameKir),
    birthCountryId: composeValidators(required)(values.birthCountryId),
    birthRegionId: composeValidators(
      validateIf(values.birthCountryId && values.birthCountryId === '1')(required),
    )(values.birthRegionId),
    birthDistrictId: composeValidators(
      validateIf(values.birthRegionId)(required),
    )(values.birthDistrictId),
    birthAddress: composeValidators(required)(values.birthAddress),
    birthDate: composeValidators(required, dateGreaterThan1900, dateLessThanNow)(values.birthDate),
    series: composeValidators(required)(values.series),
    number: composeValidators(required)(values.number),
    personDocumentTypeId: composeValidators(required)(values.personDocumentTypeId),
    givenDate: composeValidators(required, dateLessThanNow)(values.givenDate),
    givenAddress: {
      countryId: composeValidators(required)(values.givenAddress?.countryId),
      regionId: composeValidators(
        validateIf(values.givenAddress?.countryId && values.givenAddress?.countryId === '1')(required),
      )(values.givenAddress?.regionId),
      districtId: composeValidators(
        validateIf(values.givenAddress?.regionId)(required),
      )(values.givenAddress?.districtId),
      birthAddress: composeValidators(required)(values.givenAddress?.birthAddress),
    },
    genderId: composeValidators(required)(values.genderId),
    citizenshipTypeId: composeValidators(required)(values.citizenshipTypeId),
    nationalityId: composeValidators(required)(values.nationalityId),
  });

  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      {({ handleSubmit: submit, form, values }) => (
        <form onSubmit={submit}>
          <Card>
            <Text type="h1" center>{t('Ручной ввод нарушителя')}</Text>
            <InputText
              label={t('Фамилия')}
              name="secondNameKir"
              placeholder={t('Введите фамилию')}
              asterisk
            />
            <InputText
              label={t('Имя')}
              name="firstNameKir"
              placeholder={t('Введите имя')}
              asterisk
            />
            <InputText
              label={t('Отчество')}
              name="lastNameKir"
              placeholder={t('Введите отчество')}
              asterisk
            />
            <InputAutocomplete
              label={t('Страна рождения')}
              name="birthCountryId"
              options={getCountries()}
              onChange={() => {
                form.change('birthRegionId', undefined);
              }}
            />
            <InputAutocomplete
              label={t('Область рождения')}
              name="birthRegionId"
              options={getRegions(() => values.birthCountryId === '1')}
              onChange={() => {
                form.change('birthDistrictId', undefined);
              }}
            />
            <InputAutocomplete
              label={t('Район рождения')}
              name="birthDistrictId"
              options={getDistricts((d) => String(d.regionId) === values.birthRegionId)}
            />
            <InputText
              label={t('Адрес рождения')}
              name="birthAddress"
              asterisk
            />
            <InputDate
              name="birthDate"
              label={t('Дата рождения')}
            />
            <div className={styles.document}>
              <div className={styles.documentSn}>
                <Text type="subtitle2" asterisk>{t('Серия и номер документа')}</Text>
                <div className={styles.documentSnInput}>
                  <div className={styles.documentSeries}>
                    <InputPlainText
                      name="series"
                      parse={(value) => toUpper(value)}
                    />
                  </div>
                  <div className={styles.documentNumber}>
                    <InputPlainText name="number" />
                  </div>
                </div>
              </div>
              <div className={styles.documentType}>
                <InputAutocomplete
                  label={t('Тип документа')}
                  name="personDocumentTypeId"
                  asterisk
                  options={getPersonDocumentTypes()}
                />
              </div>
            </div>
            <InputDate
              name="givenDate"
              label={t('Дата выдачи')}
            />
            <InputAutocomplete
              label={t('Страна выдачи')}
              name="givenAddress.countryId"
              options={getCountries()}
              onChange={() => {
                form.change('givenAddress.regionId', undefined);
              }}
            />
            <InputAutocomplete
              label={t('Область выдачи')}
              name="givenAddress.regionId"
              options={getRegions(() => values.givenAddress?.countryId === '1')}
              onChange={() => {
                form.change('givenAddress.districtId', undefined);
              }}
            />
            <InputAutocomplete
              label={t('Район выдачи')}
              name="givenAddress.districtId"
              options={getDistricts((d) => String(d.regionId) === values.givenAddress?.regionId)}
            />
            <InputText
              label={t('Адрес выдачи')}
              name="givenAddress.birthAddress"
              asterisk
            />
            <InputAutocomplete
              label={t('Пол')}
              name="genderId"
              asterisk
              options={getGenders()}
            />
            <InputAutocomplete
              label={t('Гражданство')}
              name="citizenshipTypeId"
              asterisk
              options={getCitizenshipTypes()}
            />
            <InputAutocomplete
              label={t('Национальность')}
              name="nationalityId"
              asterisk
              options={getNationalities()}
            />
          </Card>

          <div className={styles.footer}>
            <div className={styles.footerBack}>
              <Button
                type="button"
                variant="grey"
                size="medium"
                fullWidth
                prependIcon={<LeftArrow />}
              >
                {t('Назад')}
              </Button>
            </div>
            <div className={styles.footerSubmit}>
              <Button
                type="submit"
                variant="purple"
                size="medium"
                fullWidth
                appendIcon={<RightArrow />}
              >
                {t('Сохранить нарушителя')}
              </Button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default ViolatorCreationHandyForm;
