import React from 'react';
import { useTranslation } from 'react-i18next';
// import cn from 'classnames';

import { useDispatch } from 'react-redux';
import styles from './AttorneysExtra.module.scss';
import { ReactComponent as Badge2Icon } from '../../../../../icons/badge2.svg';
import Button from '../../../../../components/Button';
import { ReactComponent as InfoIcon } from '../../../../../icons/info.svg';
import DriverLicenseModal from '../../../../person/DriverLicenseModal';
import { getDriverLicense, usePersonDriverLicense, usePersonDriverLicenseState } from '../../../../person/personSlice';

const AttorneysExtra = ({ attorney }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const personDriverLicense = usePersonDriverLicense();
  const personDriverLicenseState = usePersonDriverLicenseState();

  const handleSearchDriverLicense = () => {
    const normalizer = {
      // pinpp: attorney.member?.pinpp,
      // serial: selectedPerson.series,
      // number: selectedPerson.number,
      birthDate: attorney.member?.birthDate,
      firstName: attorney.member?.firstName,
      lastName: attorney.member?.lastName,
      secondName: attorney.member?.secondName,
    };
    dispatch(getDriverLicense(normalizer));
  };
  return (
    <div className={styles.selectExtra}>
      <DriverLicenseModal
        licenses={personDriverLicense}
        loading={personDriverLicenseState === 'pending'}
        onBtnClick={handleSearchDriverLicense}
      />
    </div>
  );
};

export default AttorneysExtra;
