import React from 'react';
import cn from 'classnames';

import './PlainTextInput.scss';

const PlainTextInput = ({
  error = null,
  ...rest
}) => {
  const wrapperClasses = cn(
    'input-wrapper',
    error && 'input-error',
  );
  return (
    <div className={wrapperClasses}>
      <input className="input" {...rest} />
    </div>
  );
};

export default PlainTextInput;
