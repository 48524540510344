import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { identity, includes, toLower } from 'ramda';
import './ChooseModal.scss';
import { ReactComponent as CheckedRadio } from '../../icons/check.svg';
import { ReactComponent as UncheckedRadio } from '../../icons/uncheck.svg';
import { ReactComponent as Close } from '../../icons/close.svg';
import { useTranslation } from 'react-i18next';

const ChooseModal = ({
  options = [],
  defaultId = '',
  onClose = identity,
  onChoose = identity,
}) => {
  const [t] = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const getResultItemClasses = (bool) => cn(
    'autocomplete-modal-results-item',
    bool && 'autocomplete-modal-results-selected',
  );

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleChoose = (id) => () => {
    onChoose(id);
  };

  return (
    <div className="autocomplete-modal">
      <div className="autocomplete-modal-window">
        <div className="autocomplete-modal-window-header">
          <div className="autocomplete-modal-window-title">
            {t('Выберите один вариант')}
          </div>
          <div className="autocomplete-modal-window-close" onClick={onClose}>
            <Close />
          </div>
        </div>
        <input
          type="text"
          value={searchValue}
          onChange={handleChangeSearch}
          className="input"
          placeholder={t('Поиск')}
          ref={inputRef}
        />
        <div className="autocomplete-modal-results">
          <div className={getResultItemClasses(!defaultId)} onClick={handleChoose(null)}>
            <span className="autocomplete-modal-results-icon">
              {
                !defaultId
                  ? <CheckedRadio />
                  : <UncheckedRadio />
              }
            </span>
            {t('Ничего не выбрано')}
          </div>
          {
            options
              .filter((opt) => includes(toLower(searchValue), toLower(opt.label)))
              .map((opt) => (
                <div
                  key={opt.id}
                  className={getResultItemClasses(String(opt.id) === String(defaultId))}
                  onClick={handleChoose(opt.id)}
                >
                  <span className="autocomplete-modal-results-icon">
                    {
                      String(opt.id) === String(defaultId)
                        ? <CheckedRadio />
                        : <UncheckedRadio />
                    }
                  </span>
                  {opt.label}
                </div>
              ))
          }
        </div>
      </div>
    </div>
  );
};

export default ChooseModal;
