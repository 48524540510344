import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../components/Text';
import styles from './PunishmentDialog/PunishmentDialog.module.scss';
import Button from '../../components/Button';
import { ReactComponent as GavelIcon } from '../../icons/gavel2.svg';

const PunishmentErrorContainer = ({
  errorText,
  onRefresh,
  loading,
}) => {
  const [t] = useTranslation();
  return (
    <div>
      <Text type="h3" className={styles.errorText}>
        {errorText}
      </Text>
      <div className={styles.refreshButton}>
        <Button
          type="button"
          onClick={onRefresh}
          prependIcon={<GavelIcon />}
          loading={loading}
        >
          {t('Повторить запрос')}
        </Button>
      </div>
    </div>
  );
};

export default PunishmentErrorContainer;
