import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LAT from './lat/translation.json';
import KIR from './kir/translation.json';
import RU from './ru/translation.json';

i18n
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    // lng: 'kir',
    fallbackLng: 'ru',
    // debug: !(process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test'),
    debug: false,
    resources: {
      ru: {
        translations: RU,
      },
      kir: {
        translations: KIR,
      },
      lat: {
        translations: LAT,
      },
    },

    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18n;
