import React from 'react';
import { useCatalogs } from './catalogsSlice';

const CatalogsWrapper = ({ children }) => {
  useCatalogs();

  return children;
};

export default CatalogsWrapper;
