import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { toUpper } from 'ramda';
import { toast } from 'react-toastify';
import {
  closeEcpPasswordDialog,
  openEcpPasswordDialog, useGetMe,
  useIsOpenEcpPasswordDialog,
} from '../../features/authData/authDataSlice';
import Dialog from '../Dialog';
import styles from '../../features/transport/TransportRouter.module.scss';
import InputText from '../InputText';

import Button from '../Button';
import useSign from '../../hooks/useSign';

const ECPPasswordDialog = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const open = useIsOpenEcpPasswordDialog();
  const [sign, signedString] = useSign();
  const [userInfo] = useGetMe();

  // TODO: ECP ON
  // useEffect(() => {
  //   if (open) return;
  //   if (!userInfo.mustProvideDigitalSignature) return;
  //   if (localStorage.getItem('ECPPAS')) return;
  //   if (!window.EmiInterface) return;
  //   dispatch(openEcpPasswordDialog());
  // }, [open, userInfo.mustProvideDigitalSignature]);

  // const handleClose = () => {
  //   dispatch(closeEcpPasswordDialog());
  // };

  const handleSubmit = (values) => {
    localStorage.setItem('ECPPAS', values.ecpPass);
    sign((signature) => {
      alert(signature);
      if (!signature) {
        localStorage.removeItem('ECPPAS');
        dispatch(openEcpPasswordDialog());
        toast.error('wrong pass');
      }
    });
    dispatch(closeEcpPasswordDialog());
  };

  return (
    <Dialog
      open={open}
      title={t('Пароль от ЭЦП')}
    >
      <Form onSubmit={handleSubmit}>
        {({ handleSubmit: submit }) => (
          <form onSubmit={submit} className={styles.numberDialogForm}>
            <div className={styles.numberInput}>
              <InputText
                name="ecpPass"
                label={t('Пароль от ЭЦП')}
                type="password"
                placeholder={t('Введите пароль от ЭЦП')}
                parse={(value) => toUpper(value)}
              />
            </div>
            <div className={styles.numberDialogFooter}>
              {/* <div className={styles.numberDialogCancel}> */}
              {/*  <Button */}
              {/*    type="reset" */}
              {/*    variant="action" */}
              {/*    size="medium" */}
              {/*    onClick={handleClose} */}
              {/*    fullWidth */}
              {/*  > */}
              {/*    {t('Отмена')} */}
              {/*  </Button> */}
              {/* </div> */}
              <div className={styles.numberDialogSubmit}>
                <Button
                  type="submit"
                  variant="purple"
                  size="medium"
                  fullWidth
                >
                  {t('Ок')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default ECPPasswordDialog;
