import { useState } from 'react';

export default (defaultValue = false) => {
  const [open, setOpen] = useState(defaultValue);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  return [open, handleOpen, handleClose, handleToggle];
};
