import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { identity, toUpper } from 'ramda';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import styles from './TransportRouter.module.scss';
import InputText from '../../components/InputText';
import { ReactComponent as NumberIcon } from '../../icons/number.svg';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { getTechPassport } from './transportSlice';
import { composeValidators, required } from '../../validators';
import config from '../../config';
import { hideLoader, showLoader } from '../loaderSlice';

const TransportByNumberDialogForm = ({
  open,
  handleClose,
  simple = false,
  onSubmit = identity,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleSubmit = useDecoratedHandle(async (values) => {
    if (!config.disableProtocolCreation && !simple) {
      await dispatch(getTechPassport(values)).unwrap();
    }
    onSubmit(values.vehicleNumber);
    handleClose();
  }, true);

  const validate = (values) => ({
    vehicleNumber: composeValidators(required)(values.vehicleNumber),
  });

  return (
    <Dialog
      open={open}
      title={t('Поиск авто по номеру')}
      onClose={handleClose}
    >
      <div className={styles.imageContainer}>
        <NumberIcon className={styles.numberImage} />
      </div>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ handleSubmit: submit }) => (
          <form onSubmit={submit} className={styles.numberDialogForm}>
            <div className={styles.numberInput}>
              <InputText
                name="vehicleNumber"
                label={t('Номер авто')}
                placeholder={t('Введите номер авто')}
                parse={(value) => toUpper(value)}
              />
            </div>
            <div className={styles.numberDialogFooter}>
              <div className={styles.numberDialogCancel}>
                <Button
                  type="reset"
                  variant="action"
                  size="medium"
                  onClick={handleClose}
                  fullWidth
                >
                  {t('Отмена')}
                </Button>
              </div>
              <div className={styles.numberDialogSubmit}>
                <Button
                  type="submit"
                  variant="purple"
                  size="medium"
                  fullWidth
                >
                  {t('Поиск')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </Dialog>
  );
};

export default TransportByNumberDialogForm;
