import React from 'react';
// import cn from 'classnames';
import { ReactComponent as UzbFlag } from '../../icons/uzb-flag.svg';
import styles from './index.module.scss';
import { splitPlateNumber } from '../../utils';

const VehicleNumber = ({ plateNumber = '' }) => {
  const { region, number } = splitPlateNumber(plateNumber);
  return (
    <div className={styles.plate}>
      <div className={styles.region}>{region}</div>
      <div className={styles.number}>{number}</div>
      <div className={styles.flag}>
        <UzbFlag />
      </div>
    </div>
  );
};

export default VehicleNumber;
