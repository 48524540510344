import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  routes: {},
  language: 'ru',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,

  reducers: {
    initialize: (state, { payload: appState }) => {
      return { ...state, ...appState };
    },
    changeLanguage: (state, { payload: newLang }) => {
      state.language = newLang;
    },
  },
});

export const { initialize, changeLanguage } = appSlice.actions;

export const useAppRoutes = (viewName) => {
  const appRoutes = useSelector((state) => state.app.routes);
  if (!viewName) return appRoutes;
  return appRoutes[viewName];
  // return appRoutes;
};

export const useActiveLang = () => {
  return useSelector((state) => state.app.language);
};

export default appSlice.reducer;
