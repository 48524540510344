import {
  compose, createAsyncThunk, createSelector, createSlice,
} from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import {
  filter,
  find, identity, isEmpty, isNil, prop, propEq, sortBy, toLower,
} from 'ramda';
import { useTranslation } from 'react-i18next';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';
import { useActiveLang } from '../app/appSlice';
import { showLoader, hideLoader } from '../loaderSlice';
import buildRequestStates from '../../app/buildRequestStates';

const initialState = {
  ui: {
    loadingState: null, // 'started' or 'finished'
    isAlreadyFetched: false,
    needReFetchKey: 0,
    validationErrorDialog: {
      open: false,
      messages: [],
    },
  },
  lists: {},
  autocompletes: {},
};

export const getOrgans = createAsyncThunk(
  'catalogs/getOrgans',
  async () => {
    const response = await httpClient.get(routes.organs(), { allPages: true });
    return response.data.objects;
  },
);

export const getRegions = createAsyncThunk(
  'catalogs/getRegions',
  async () => {
    const response = await httpClient.get(routes.regions(), { allPages: true });
    return response.data.objects;
  },
);

export const getDistricts = createAsyncThunk(
  'catalogs/getDistricts',
  async () => {
    const response = await httpClient.get(routes.districts(), { allPages: true });
    return response.data.objects;
  },
);

export const getArticles = createAsyncThunk(
  'catalogs/getArticles',
  async () => {
    const response = await httpClient.get(routes.articles(), { allPages: true });
    return response.data.objects;
  },
);

export const getArticleParts = createAsyncThunk(
  'catalogs/getArticleParts',
  async () => {
    const response = await httpClient.get(routes.articleParts(), { allPages: true });
    return response.data.objects;
  },
);

export const getViolationTypes = createAsyncThunk(
  'catalogs/getViolationTypes',
  async () => {
    const response = await httpClient.get(routes.violationTypes(), { allPages: true });
    return response.data.objects;
  },
);

export const getPunishmentTypes = createAsyncThunk(
  'catalogs/getPunishmentTypes',
  async () => {
    const response = await httpClient.get(routes.punishmentTypes(), { allPages: true });
    return response.data.objects;
  },
);

export const getAdmStatuses = createAsyncThunk(
  'catalogs/getAdmStatuses',
  async () => {
    const response = await httpClient.get(routes.admStatuses(), { allPages: true });
    return response.data.objects;
  },
);

export const getGenders = createAsyncThunk(
  'catalogs/getGenders',
  async () => {
    const response = await httpClient.get(routes.genders(), { allPages: true });
    return response.data.objects;
  },
);

export const getCountries = createAsyncThunk(
  'catalogs/getCountries',
  async () => {
    const response = await httpClient.get(routes.countries(), { allPages: true });
    return response.data.objects;
  },
);

export const getOccupations = createAsyncThunk(
  'catalogs/getOccupations',
  async () => {
    const response = await httpClient.get(routes.occupations(), { allPages: true });
    return response.data.objects;
  },
);

export const getIntoxicationTypes = createAsyncThunk(
  'catalogs/getIntoxicationTypes',
  async () => {
    const response = await httpClient.get(routes.intoxicationTypes(), { allPages: true });
    return response.data.objects;
  },
);

export const getNationalities = createAsyncThunk(
  'catalogs/getNationalities',
  async () => {
    const response = await httpClient.get(routes.nationalities(), { allPages: true });
    return response.data.objects;
  },
);

/* Справочники УБДД */

export const getVehicleOwnerships = createAsyncThunk(
  'catalogs/getVehicleOwnerships',
  async () => {
    const response = await httpClient.get(routes.vehicleOwnerships(), { allPages: true });
    return response.data.objects;
  },
);

export const getVehicleColorTypes = createAsyncThunk(
  'catalogs/getVehicleColorTypes',
  async () => {
    const response = await httpClient.get(routes.vehicleColorTypes(), { allPages: true });
    return response.data.objects;
  },
);

export const getVehicleBodyTypes = createAsyncThunk(
  'catalogs/getVehicleBodyTypes',
  async () => {
    const response = await httpClient.get(routes.vehicleBodyTypes(), { allPages: true });
    return response.data.objects;
  },
);

export const getMessages = createAsyncThunk(
  'catalogs/getMessages',
  async () => {
    const response = await httpClient.get(routes.messages(), { allPages: true });
    return response.data.objects;
  },
);

export const getCitizenshipTypes = createAsyncThunk(
  'catalogs/getCitizenshipTypes',
  async () => {
    const response = await httpClient.get(routes.citizenshipTypes(), { allPages: true });
    return response.data.objects;
  },
);

export const getPersonDocumentTypes = createAsyncThunk(
  'catalogs/getPersonDocumentTypes',
  async () => {
    const response = await httpClient.get(routes.personDocumentTypes(), { allPages: true });
    return response.data.objects;
  },
);

export const getDepartments = createAsyncThunk(
  'catalogs/getDepartments',
  async () => {
    const response = await httpClient.get(routes.departments(), { allPages: true });
    return response.data.objects;
  },
);

export const getVehicleArrestPlaces = createAsyncThunk(
  'catalogs/getVehicleArrestPlaces',
  async () => {
    const response = await httpClient.get(routes.vehicleArrestPlaces(), { allPages: true });
    return response.data.objects;
  },
);

export const getVehicleArrestReasons = createAsyncThunk(
  'catalogs/getVehicleArrestReasons',
  async () => {
    const response = await httpClient.get(routes.vehicleArrestReasons(), { allPages: true });
    return response.data.objects;
  },
);

export const getDocumentTypes = createAsyncThunk(
  'catalogs/getDocumentTypes',
  async () => {
    const response = await httpClient.get(routes.documentTypes(), { allPages: true });
    return response.data.objects;
  },
);

export const getConfiscatedCategories = createAsyncThunk(
  'catalogs/getConfiscatedCategories',
  async () => {
    const response = await httpClient.get(routes.confiscatedCategories(), { allPages: true });
    return response.data.objects;
  },
);

export const getWantedReasons = createAsyncThunk(
  'catalogs/getWantedReasons',
  async () => {
    const response = await httpClient.get(routes.wantedReasons(), { allPages: true });
    return response.data.objects;
  },
);

export const getTintingCategories = createAsyncThunk(
  'catalogs/getTintingCategories',
  async () => {
    const response = await httpClient.get(routes.tintingCategories(), { allPages: true });
    return response.data.objects;
  },
);

export const getWantedOrgans = createAsyncThunk(
  'catalogs/getWantedOrgans',
  async () => {
    const response = await httpClient.get(routes.wantedOrgans(), { allPages: true });
    return response.data.objects;
  },
);

const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {
    setLoadingCatalogsState: (state, { payload: catalogsState }) => {
      state.ui.loadingState = catalogsState;
    },
    forceReFetchCatalogs: (state) => {
      state.ui.needReFetchKey += 1;
      state.ui.isAlreadyFetched = false;
    },
    setAlreadyFetched: (state) => {
      state.ui.isAlreadyFetched = true;
    },
    openValidationErrorDialog: (state, { payload: messages }) => {
      state.ui.validationErrorDialog.open = true;
      state.ui.validationErrorDialog.messages = messages;
    },
    closeValidationErrorDialog: (state) => {
      state.ui.validationErrorDialog.open = false;
      state.ui.validationErrorDialog.messages = [];
    },
    setAutocompleteItem: (state, { payload: { catalogName, data } }) => {
      state.autocompletes[catalogName] = data;
    },
    clearAutocompleteItems: (state) => {
      state.autocompletes = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrgans.fulfilled, (state, { payload: objects }) => {
        state.lists.organs = objects;
      })
      .addCase(getDistricts.fulfilled, (state, { payload: objects }) => {
        state.lists.districts = objects;
      })
      .addCase(getRegions.fulfilled, (state, { payload: objects }) => {
        state.lists.regions = objects;
      })
      .addCase(getArticles.fulfilled, (state, { payload: objects }) => {
        state.lists.articles = objects;
      })
      .addCase(getArticleParts.fulfilled, (state, { payload: objects }) => {
        state.lists.articleParts = objects;
      })
      .addCase(getViolationTypes.fulfilled, (state, { payload: objects }) => {
        state.lists.violationTypes = objects;
      })
      .addCase(getPunishmentTypes.fulfilled, (state, { payload: objects }) => {
        state.lists.punishmentTypes = objects;
      })
      .addCase(getAdmStatuses.fulfilled, (state, { payload: objects }) => {
        state.lists.admStatuses = objects;
      })
      .addCase(getGenders.fulfilled, (state, { payload: objects }) => {
        state.lists.genders = objects;
      })
      .addCase(getCountries.fulfilled, (state, { payload: objects }) => {
        state.lists.countries = objects;
      })
      .addCase(getOccupations.fulfilled, (state, { payload: objects }) => {
        state.lists.occupations = objects;
      })
      .addCase(getIntoxicationTypes.fulfilled, (state, { payload: objects }) => {
        state.lists.intoxicationTypes = objects;
      })
      .addCase(getVehicleOwnerships.fulfilled, (state, { payload: objects }) => {
        state.lists.vehicleOwnerships = objects;
      })
      .addCase(getVehicleColorTypes.fulfilled, (state, { payload: objects }) => {
        state.lists.vehicleColorTypes = objects;
      })
      .addCase(getVehicleBodyTypes.fulfilled, (state, { payload: objects }) => {
        state.lists.vehicleBodyTypes = objects;
      })
      .addCase(getNationalities.fulfilled, (state, { payload: objects }) => {
        state.lists.nationalities = objects;
      })
      .addCase(getMessages.fulfilled, (state, { payload: objects }) => {
        state.lists.messages = objects;
      })
      .addCase(getCitizenshipTypes.fulfilled, (state, { payload: objects }) => {
        state.lists.citizenshipTypes = objects;
      })
      .addCase(getPersonDocumentTypes.fulfilled, (state, { payload: objects }) => {
        state.lists.personDocumentTypes = objects;
      })
      .addCase(getDepartments.fulfilled, (state, { payload: objects }) => {
        state.lists.departments = objects;
      })
      .addCase(getVehicleArrestPlaces.fulfilled, (state, { payload: objects }) => {
        state.lists.vehicleArrestPlaces = objects;
      })
      .addCase(getVehicleArrestReasons.fulfilled, (state, { payload: objects }) => {
        state.lists.vehicleArrestReasons = objects;
      })
      .addCase(getDocumentTypes.fulfilled, (state, { payload: objects }) => {
        state.lists.documentTypes = objects;
      })
      .addCase(getWantedReasons.fulfilled, (state, { payload: objects }) => {
        state.lists.wantedReasons = objects;
      })
      .addCase(getConfiscatedCategories.fulfilled, (state, { payload: objects }) => {
        state.lists.confiscatedCategories = objects;
      })
      .addCase(getTintingCategories.fulfilled, (state, { payload: objects }) => {
        state.lists.tintingCategories = objects;
      })
      .addCase(getWantedOrgans.fulfilled, (state, { payload: objects }) => {
        state.lists.wantedOrgans = objects;
      });
    buildRequestStates(builder, getMessages);
  },
});

export const {
  setLoadingCatalogsState,
  forceReFetchCatalogs,
  setAlreadyFetched,
  openValidationErrorDialog,
  closeValidationErrorDialog,
  setAutocompleteItem,
  clearAutocompleteItems,
} = catalogsSlice.actions;

export const useGetMessagesState = () => useSelector((state) => state.catalogs.ui[getMessages.typePrefix]);
export const useCatalogsState = () => useSelector((state) => state.catalogs.ui.loadingState);

export const useValidationErrorDialogState = () => useSelector(
  (state) => state.catalogs.ui.validationErrorDialog,
);

export const useCatalogs = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const catalogsUiState = useSelector((state) => state.catalogs.ui);

  const fetchAllCatalogs = useCallback(async () => {
    const promises = [
      dispatch(getOrgans()),
      dispatch(getRegions()),
      dispatch(getDistricts()),
      dispatch(getArticles()),
      dispatch(getArticleParts()),
      dispatch(getViolationTypes()),
      dispatch(getPunishmentTypes()),
      dispatch(getAdmStatuses()),
      dispatch(getGenders()),
      dispatch(getCountries()),
      dispatch(getOccupations()),
      dispatch(getIntoxicationTypes()),
      dispatch(getVehicleOwnerships()),
      dispatch(getVehicleColorTypes()),
      dispatch(getVehicleBodyTypes()),
      dispatch(getNationalities()),
      dispatch(getCitizenshipTypes()),
      dispatch(getPersonDocumentTypes()),
      dispatch(getDepartments()),
      dispatch(getVehicleArrestPlaces()),
      dispatch(getVehicleArrestReasons()),
      dispatch(getDocumentTypes()),
      dispatch(getConfiscatedCategories()),
      dispatch(getWantedReasons()),
      dispatch(getTintingCategories()),
      dispatch(getWantedOrgans()),
    ];

    return Promise.all(promises);
  }, []);

  // useEffect(() => {
  //   if (catalogsUiState.needReFetchKey === 0) return;
  //   dispatch(showLoader({ label: t('Загрузка справочников') }));
  //   fetchAllCatalogs().then(() => {
  //     dispatch(hideLoader());
  //     dispatch(clearAutocompleteItems());
  //   });
  // }, [catalogsUiState.needReFetchKey, fetchAllCatalogs]);

  useEffect(() => {
    if (catalogsUiState.isAlreadyFetched) return;
    dispatch(showLoader({ label: t('Загрузка справочников') }));
    fetchAllCatalogs().then(() => {
      dispatch(hideLoader());
      dispatch(clearAutocompleteItems());
      dispatch(setAlreadyFetched());
    });
  }, [catalogsUiState.isAlreadyFetched, fetchAllCatalogs]);
};

export const useCatalogsName = (catalogName) => {
  const currentCatalog = useSelector((state) => state.catalogs.lists[catalogName]);
  const lang = useActiveLang();
  if (!currentCatalog) {
    return () => 'CTLGERR404';
  }

  return (catalogId) => {
    if (!catalogId) return null;
    const foundCatalog = find(propEq('id', Number(catalogId)))(currentCatalog);
    if (!foundCatalog) return 'CTLGNAMEERR404';
    return foundCatalog.name[lang];
  };
};

export const useCatalogsAlias = (catalogName) => {
  const currentCatalog = useSelector((state) => state.catalogs.lists[catalogName]);
  if (!currentCatalog) return null;
  return (catalogId) => {
    const foundCatalog = find(propEq('id', Number(catalogId)))(currentCatalog);
    return foundCatalog.alias || null;
  };
};

export const useGetErrorName = () => {
  const currentCatalog = useSelector((state) => state.catalogs.lists.messages);
  const lang = useActiveLang();
  if (!currentCatalog) {
    return () => 'CTLGERR404';
  }

  return (catalogCode) => {
    if (!catalogCode) return null;
    const foundCatalog = find(propEq('code', catalogCode))(currentCatalog);
    if (!foundCatalog) return 'CTLGNAMEERR404';
    return foundCatalog.text[lang];
  };
};

export const useAddressToString = () => {
  const getCountryName = useCatalogsName('countries');
  const getRegionName = useCatalogsName('regions');
  const getDistrictName = useCatalogsName('districts');

  return (addr) => {
    if (isNil(addr) || isEmpty(addr)) return '——';
    const {
      countryId, regionId, districtId, address,
    } = addr;
    const country = getCountryName(countryId);
    const region = getRegionName(regionId);
    const district = getDistrictName(districtId);

    return [country, region, district, address]
      .filter(identity)
      .join(', ');
  };
};

const sortByNameCaseInsensitive = sortBy(compose(toLower, prop('label')));

const catalogsSelector = createSelector(
  (state) => state.catalogs.lists,
  (_, lang) => lang,
  (_, __, catalogName) => catalogName,
  (_, __, ___, showOnlyActive) => showOnlyActive,
  (catalogs, lang, catalogName, showOnlyActive) => {
    const currentCatalog = catalogs[catalogName];
    if (!currentCatalog) return null;
    return sortByNameCaseInsensitive(currentCatalog
      .filter((el) => (showOnlyActive ? el.isActive : true))
      .map((catalogItem) => ({
        ...catalogItem,
        value: String(catalogItem.id),
        id: String(catalogItem.id),
        label: [catalogItem.code, catalogItem.mfo, catalogItem.name[lang]]
          .filter((el) => el)
          .join(' - '),
      })));
  },
);

export const useAutocompleteItems = (catalogName, showOnlyActive = true) => {
  const lang = useActiveLang();
  const dispatch = useDispatch();
  const autocompleteItems = useSelector(
    (state) => catalogsSelector(state, lang, catalogName, showOnlyActive),
  );
  const jsonAutoCompleteItems = JSON.stringify(autocompleteItems);

  return useCallback((filterCallback) => {
    // dispatch(setAutocompleteItem({ catalogName, data: autocompleteItems }));
    if (!autocompleteItems) return [];
    if (!filterCallback) return autocompleteItems;
    return filter(filterCallback, autocompleteItems);
  }, [jsonAutoCompleteItems]);
};

export const useIsCatalogsAlreadyFetched = () => useSelector((state) => state.catalogs.ui.isAlreadyFetched);

export default catalogsSlice.reducer;
