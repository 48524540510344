import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { useAppRoutes } from '../features/app/appSlice';
import Button from '../components/Button';
import { ReactComponent as TaxiAlert } from '../icons/taxi-alert.svg';
import { ReactComponent as Camera } from '../icons/camera.svg';
import Text from '../components/Text';
import LabeledText from '../components/LabeledText';
import MenuItem from '../components/MenuItem';
import Tabs from '../components/Tabs';
import StatusLabel from '../components/StatusLabel';
import DangerSticker from '../components/DangerSticker';
import VehicleNumber from '../components/VehicleNumber';
import AdditionalLicenseCard from '../components/AdditionalLicenseCard';
import InputAutocomplete from '../components/InputAutocomplete';
import InputDate from '../components/InputDate';
import InputPlainText from '../components/InputPlainText';
import InputText from '../components/InputText';
import TextAreaInput from '../components/InputTextArea';
import Card from '../components/Card';
import Divider from '../components/Divider';
import Dialog from '../components/Dialog';

const Example = () => {
  const appRoutes = useAppRoutes();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    console.log('test');
    console.log(appRoutes);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  return (
    <div>
      <div>Example</div>
      <br/>
      <Button
        type="button"
        variant="transparent"
        onClick={handleClick}
        prependIcon={<TaxiAlert />}
        appendIcon={<TaxiAlert />}
        loading={loading}
      >
        ID карта NFC
      </Button>
      <br/>
      <Button
        type="button"
        variant="purple"
        onClick={handleClick}
        size="small"
        prependIcon={<TaxiAlert />}
        loading={loading}
      >
        Тех. паспорт NFC
      </Button>
      <br/>
      <Button
        type="button"
        size="medium"
        variant="lightBlue"
        onClick={handleClick}
        prependIcon={<TaxiAlert />}
        appendIcon={<TaxiAlert />}
        loading={loading}
      >
        Test
      </Button>
      <Button
        type="button"
        variant="grey"
        onClick={handleClick}
        prependIcon={<TaxiAlert />}
        appendIcon={<TaxiAlert />}
        loading={loading}
      >
        Test
      </Button>
      <Button
        type="button"
        variant="text"
        onClick={handleClick}
        prependIcon={<TaxiAlert />}
        appendIcon={<TaxiAlert />}
        loading={loading}
      >
        Test
      </Button>
      <Button
        type="button"
        variant="action"
        onClick={handleClick}
        prependIcon={<TaxiAlert />}
        appendIcon={<TaxiAlert />}
        loading={loading}
      >
        Test
      </Button>
      <div style={{ width: '100%', height: 200 }}>
        <Button
          type="button"
          variant="lightBlue"
          fullWidth
          fullHeight
          onClick={handleClick}
          prependIcon={<TaxiAlert />}
          appendIcon={<TaxiAlert />}
          loading={loading}
        >
          Test
        </Button>
      </div>

      {/*  TEXT COMPONENTS  */}

      <Text type="h1">История правонарушений</Text>
      <Text type="h1" center>История правонарушений</Text>
      <Text type="h2" center>E-protokol</Text>
      <Text type="h3">Информация о занятости</Text>
      <Text type="body1">Body1</Text>
      <Text type="body2">Body1</Text>
      <Text type="subtitle1">Body1</Text>

      <div style={{ display: 'flex' }}>
        <div style={{ width: '30%' }}>
          <LabeledText label="Орган рассмотрения" text="ГУБДД" />
        </div>
        <div style={{ width: '30%' }}>
          <LabeledText label="Орган рассмотрения" text="ГУБДД" />
        </div>
        <div style={{ width: '30%' }}>
          <LabeledText label="Орган рассмотрения" text="ГУБДД" />
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%', boxSizing: 'border-box', padding: '0 4px' }}>
          <MenuItem
            icon={<Camera />}
            text="Фотофиксация"
            onClick={() => console.log('work')}
          />
        </div>
        <div style={{ width: '50%', boxSizing: 'border-box', padding: '0 4px' }}>
          <MenuItem
            icon={<Camera />}
            text="Фотофиксация"
            onClick={() => console.log('work')}
          />
        </div>
      </div>
      <Tabs
        activeTab="tab-1"
        tabs={[
          { key: 'tab-1', title: 'Мои протоколы' },
          { key: 'tab-2', title: 'Мои решения' },
        ]}
        onTabClick={(tab) => console.log(tab)}
      />
      <div style={{ padding: 4 }}>
        <StatusLabel text="OJIDAET ISPOLNENIYA" type="danger" />
      </div>
      <div style={{ padding: 4 }}>
        <StatusLabel text="OJIDAET ISPOLNENIYA" type="warning" />
      </div>
      <div style={{ padding: 4 }}>
        <StatusLabel text="OJIDAET ISPOLNENIYA" type="grey" />
      </div>
      <div style={{ padding: 4 }}>
        <StatusLabel text="OJIDAET ISPOLNENIYA" />
      </div>
      <DangerSticker onClick={() => console.log('work')} text="Числится в базе угона" buttonText="Подробнее" />
      <VehicleNumber plateNumber="75438vcs" />

      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%', boxSizing: 'border-box', padding: '0 4px' }}>
          <AdditionalLicenseCard
            status="not_exist"
            type="Страховка"
            serial="GIL108972"
            dateFrom="30.09.1990"
            dateTo="20.09.1991"
          />
        </div>
        <div style={{ width: '50%', boxSizing: 'border-box', padding: '0 4px' }}>
          <AdditionalLicenseCard status="absent" type="Strahovka" />
        </div>
      </div>
      <div style={{ height: 4 }} />
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%', boxSizing: 'border-box', padding: '0 4px' }}>
          <AdditionalLicenseCard
            status="active"
            type="Страховка"
            serial="GIL108972"
            dateFrom="30.09.1990"
            dateTo="20.09.1991"
          />
        </div>
        <div style={{ width: '50%', boxSizing: 'border-box', padding: '0 4px' }}>
          <AdditionalLicenseCard
            status="overdue"
            type="Страховка"
            serial="GIL108972"
            dateFrom="30.09.1990"
            dateTo="20.09.1991"
          />
        </div>
      </div>
      <AdditionalLicenseCard
        status="not_exist"
        type="Страховка"
        serial="GIL108972"
        dateFrom="30.09.1990"
        dateTo="20.09.1991"
      />
      <div style={{ height: 20 }} />
      <Form
        onSubmit={(values) => console.log(values)}
        validate={(values) => ({
          text: 'error',
        })}
      >
        {({ handleSubmit: submit, values }) => (
          <form onSubmit={submit}>
            <pre>{JSON.stringify(values)}</pre>
            <div>
              <InputAutocomplete
                name="label"
                label="Label"
                asterisk
                options={[{ label: 'text 1', id: '2' }, { label: 'text 1', id: '1' }, { label: 'text 1', id: '3' }]}
              />
            </div>
            <div>
              <InputDate
                name="date"
                label="Date"
                asterisk
                withTime
              />
            </div>
            <div>
              <InputPlainText name="plain" />
            </div>
            <div>
              <InputText name="text" label="Text" asterisk />
            </div>
            <div>
              <TextAreaInput name="area" label="Area" asterisk />
            </div>
            <button type="submit">submit</button>
          </form>
        )}
      </Form>
      <Card>
        test
        <Divider mt={2} mb={2} />
        test
      </Card>

      <div>
        <Button
          type="button"
          variant="purple"
          onClick={() => setOpen(true)}
        >
          Open Modal
        </Button>
        <Dialog
          title="Test test test"
          open={open}
          onClose={() => setOpen(false)}

        >
          Lorem ipsum dolor sit.
          Lorem ipsum dolor sit.
          Lorem ipsum dolor sit.
          Lorem ipsum dolor sit.
          Lorem ipsum dolor sit.
          Lorem ipsum dolor sit.
        </Dialog>
      </div>
      <div style={{ height: 100 }} />
    </div>
  );
};

export default Example;
