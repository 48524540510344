import React from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-svg-spinner';
import { includes } from 'ramda';
import styles from './LoaderContainer.module.scss';
import { useShowLoader } from '../../features/loaderSlice';

const LoaderContainer = () => {
  const [t] = useTranslation();
  const showLoader = useShowLoader();
  const show = Boolean(showLoader.isShow);

  const { label } = showLoader;
  return show ? (
    <div className={styles.loaderContainer}>
      <div>
        <Spinner size="32px" thickness={3} color="#404045" />
      </div>
      <div className={styles.loader}>
        {label}
      </div>
    </div>
  ) : null;
};

export default LoaderContainer;
