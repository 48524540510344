import React, { useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { useTranslation } from 'react-i18next';
import { identity } from 'ramda';
import Button from '../Button';
import Dialog from '../Dialog';
import Tabs from '../Tabs';
import { ReactComponent as Fingerprint } from '../../icons/fingerprint.svg';
import FingerprintScanner from './FingerprintScanner';

import './SignHere.scss';

const ReactSketchCanvasContainer = (props, ref) => (
  <ReactSketchCanvas {...props} ref={ref}/>
);

const ReactSketchCanvasRef = React.forwardRef(ReactSketchCanvasContainer);

const SignHere = ({
  disabled = false,
  onSave = identity,
  image = '',
}) => {
  const [open, setOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('sketch');
  const [finger, setFinger] = useState(null);
  const [t] = useTranslation();
  const sign = cn('sign');
  const signButton = cn('sign-button');
  const signIcon = cn('sign-button-icon');
  const signButtonText = cn('sign-button-text');

  const ref = useRef();

  const handleOpen = () => {
    if (disabled) return;
    setOpen(true);
  };

  const handleClose = () => {
    if (currentTab === 'sketch') {
      ref.current.clearCanvas();
    }
    if (currentTab === 'finger') {
      setFinger(null);
    }
    setOpen(false);
  };

  const handleClearCanvas = () => {
    if (currentTab === 'sketch') {
      ref.current.clearCanvas();
      return;
    }
    if (currentTab === 'finger') {
      setFinger(null);
    }
  };

  const handleSubmit = async () => {
    if (currentTab === 'sketch') {
      ref.current.clearCanvas();
      const base64Img = await ref.current.exportImage('png');
      onSave(base64Img);
      handleClose();
      return;
    }
    if (currentTab === 'finger') {
      onSave(finger);
      handleClose();
    }
  };

  const handleClickTab = (key) => {
    setCurrentTab(key);
  };

  const items = useMemo(() => [
    { key: 'sketch', title: t('Графическая') },
    { key: 'finger', title: t('Отпечаток') },
  ], []);

  return (
    <>
      <button className={signButton} type="button" onClick={handleOpen} disabled={disabled}>
        <span className={signIcon}>
          <Fingerprint />
        </span>
        <span className={signButtonText}>
          {
            image
              ? (
                <span style={{ textAlign: 'center', display: 'inline-block' }}>
                  <img src={image} alt="" style={{ width: '100%', height: 120 }} />
                </span>
              ) : t('Нажмите чтобы вызвать метод электронной подписи')
          }
        </span>
      </button>
      <Dialog
        open={open}
        title={t('Подписать')}
        onClose={handleClose}
      >
        <div>
          <Tabs
            tabs={items}
            activeTab={currentTab}
            onTabClick={handleClickTab}
          />
          <div>
            {
              currentTab === 'sketch' && (
                <ReactSketchCanvasRef
                  strokeWidth={2}
                  strokeColor="black"
                  height="400px"
                  ref={ref}
                  allowOnlyPointerType="all"
                />
              )
            }
            {
              currentTab === 'finger' && <FingerprintScanner onScan={setFinger} finger={finger} />
            }
          </div>
          <div style={{ marginTop: 20 }}>
            <Button
              type="button"
              variant="lightBlue"
              style={{ marginRight: 6 }}
              onClick={handleClearCanvas}
            >
              {t('Очистить')}
            </Button>
            <Button
              type="button"
              variant="grey"
              style={{ marginRight: 6 }}
              onClick={handleClose}
            >
              {t('Отменить')}
            </Button>
            <Button
              type="button"
              variant="purple"
              onClick={handleSubmit}
            >
              {t('Подтвердить')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SignHere;
