import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { toUpper } from 'ramda';
import { useDispatch } from 'react-redux';
import Portal from '../../components/Portal';
import styles from './TransportRouter.module.scss';
import number from '../../assets/number.png';
import InputText from '../../components/InputText';
import Button from '../../components/Button';
import Dialog from '../../components/Dialog';
import {
  getInsurance, getTaxiLicense, getTinting, getVehicleInspection, selectTexPass, useSelectedTexPass, useTechPassports,
} from './transportSlice';
import SelectContent from '../../components/SelectContent';
import TexPassContent from './TexPassContent';
import { getAttorneys } from '../attorneys/attorneysSlice';
import { hideLoader, showLoader } from '../loaderSlice';
import { getWantedInfo } from '../wanted/wantedSlice';

const SetTexPassDialog = ({
  open,
  handleClose,
}) => {
  const [t] = useTranslation();
  const techPassports = useTechPassports();
  const dispatch = useDispatch();
  const selectedTexPass = useSelectedTexPass();

  const handleSelectTexPass = (texPass) => () => {
    dispatch(selectTexPass(texPass));
  };
  const onSelectTexPass = async () => {
    dispatch(showLoader({ label: t('Поиск данных авто') }));
    if (selectedTexPass.vehicleNumber) {
      const promises = [
        dispatch(getInsurance({ vehicleNumber: selectedTexPass.vehicleNumber })),
        dispatch(getTinting({ vehicleNumber: selectedTexPass.vehicleNumber })),
        dispatch(getTaxiLicense({ vehicleNumber: selectedTexPass.vehicleNumber })),
        dispatch(getAttorneys({ vehicleNumber: selectedTexPass.vehicleNumber })),
        dispatch(getWantedInfo({ vehicleNumber: selectedTexPass.vehicleNumber, isClosed: false, isDeleted: false })),
        dispatch(getVehicleInspection({ vehicleNumber: selectedTexPass.vehicleNumber })),
      ];
      await Promise.all(promises);
    }
    dispatch(hideLoader());
    handleClose();
  };

  return (
    <Dialog
      open={open}
      title={t('Выберите техпаспорт')}
      onClose={handleClose}
    >
      <div>
        {
          techPassports.map((texPass, i) => (
            <div className={styles.texPassSelect} key={i}>
              <SelectContent
                selected={selectedTexPass?.id === texPass?.id}
                onClick={handleSelectTexPass(texPass)}
              >
                <TexPassContent
                  texPass={texPass}
                />
              </SelectContent>
            </div>
          ))
        }
        <div className={styles.texPassFooter}>
          <Button
            type="button"
            variant="purple"
            onClick={onSelectTexPass}
          >
            {t('Подтвердить')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SetTexPassDialog;
