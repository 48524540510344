import React from 'react';
import { isEmpty, not } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useField, useForm, useFormState } from 'react-final-form';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import CardHeader from '../../components/CardHeader';
import { ReactComponent as CarIcon } from '../../icons/car.svg';
import ConfirmDialog from '../../components/ConfirmDialog';
import Button from '../../components/Button';
import { ReactComponent as BackspaceIcon } from '../../icons/backspace.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import Divider from '../../components/Divider';
import styles from './TransportRouter.module.scss';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import VehicleInfoContainer from './VehicleInfoContainer';
import Card from '../../components/Card';
import { resetWanted } from '../wanted/wantedSlice';
import { resetAttorneys } from '../attorneys/attorneysSlice';
import { resetTransport } from './transportSlice';
import RFFValidationError from '../../components/RFFValidationError';

const TransportContainer = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { values } = useFormState();
  const form = useForm();
  const dispatch = useDispatch();
  const handleClickAddTransport = () => {
    dispatch(resetWanted());
    dispatch(resetAttorneys());
    dispatch(resetTransport());
    navigate('transport');
  };

  const handleReset = () => {
    dispatch(resetWanted());
    dispatch(resetAttorneys());
    dispatch(resetTransport());
    form.change('transport', null);
  };

  const handleEdit = () => {
    navigate('/protocol-creation-form/transport');
  };

  return (
    <Card>
      <CardHeader
        label={t('Автотранспорт')}
        icon={<CarIcon />}
        extra1={
          values.transport && (
            <ConfirmDialog
              confirmText={t('Вы действительно хотите очистить?')}
              onConfirm={handleReset}
              renderButton={(onClick) => (
                <Button
                  type="button"
                  variant="text"
                  size="small"
                  prependIcon={<BackspaceIcon />}
                  onClick={onClick}
                >
                  {t('Очистить')}
                </Button>
              )}
            />
          )}
        extra2={
          values.transport && (
            <Button
              type="button"
              variant="text"
              size="small"
              prependIcon={<EditIcon />}
              onClick={handleEdit}
            >
              {t('Редактировать')}
            </Button>
          )}
      />
      <Divider color="#E1F0FF" />
      {
        !values.transport
          ? (
            <div className={styles.empty}>
              <Button
                fullHeight
                fullWidth
                type="button"
                variant="lightBlue"
                onClick={handleClickAddTransport}
              >
                <AddIcon style={{ width: 60, height: 60, margin: 0 }} />
              </Button>
              <RFFValidationError name='transport' />
            </div>
          ) : (
            <div className={styles.filled}>
              <VehicleInfoContainer />
            </div>
          )
      }
    </Card>
  );
};

export default TransportContainer;
