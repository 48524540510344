import React from 'react';
import { useTranslation } from 'react-i18next';
import { isFuture, parseISO } from 'date-fns';
import { identity } from 'ramda';
import { ReactComponent as BadgeIcon } from '../../icons/badge.svg';
import Button from '../../components/Button';
import Dialog from '../../components/Dialog';
import useToggler from '../../hooks/useToggler';
import DriverLicenseCard from '../../components/DriverLicenseCard';
import { formatDate } from '../../utils';
import styles from '../protocolCreations/ProtocolCreationQualification/ProtocolCreationQualification.module.scss';
import Text from '../../components/Text';

const DriverLicenseModal = ({
  licenses,
  loading,
  onBtnClick = identity,
}) => {
  const [t] = useTranslation();
  const [open, handleOpen, handleClose] = useToggler();
  const handleDriverLicense = async () => {
    await onBtnClick();
    handleOpen();
  };
  return (
    <>
      <Button
        type="button"
        variant="transparent"
        size="medium"
        loading={loading}
        style={{ padding: '8px 5px', fontSize: 10 }}
        prependIcon={<BadgeIcon style={{ width: 12, height: 12, margin: '0 2px' }} />}
        onClick={handleDriverLicense}
      >
        {t('Водительское удостоверение')}
      </Button>
      {
        !loading && (
          <Dialog
            open={open}
            title=""
            onClose={handleClose}
          >
            <div>
              {
                licenses && licenses.length > 0
                  ? licenses.map((license) => (
                    <div key={license.id} className={styles.driverLicenseItem}>
                      <DriverLicenseCard
                        status={isFuture(parseISO(license.expireDate)) ? 'active' : 'overdue'}
                        type={t('Водительское удостоверение')}
                        serial={license.serialNumber}
                        issuedBy={license.issuedBy}
                        categories={license.categories}
                        dateFrom={formatDate(license.givenDate)}
                        dateTo={formatDate(license.expireDate)}
                      />
                    </div>
                  ))
                  : <Text type='h2'>{t('Нет данных по водительским правам')}</Text>
              }
              <div>
                <div className={styles.footer}>
                  <Button
                    onClick={handleClose}
                    variant="purple"
                    fullWidth
                  >
                    {t('Закрыть')}
                  </Button>
                </div>
              </div>
            </div>
          </Dialog>
        )
      }
    </>
  );
};

export default DriverLicenseModal;
