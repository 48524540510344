import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './SearchByPhotoCard.module.scss';
import personIcon from '../../assets/profile.png';
import Button from '../Button';
import Text from '../Text';

const SearchByPhotoCard = ({
  photoUrl,
  fio,
  birthDate,
  pinfl,
  color,
  score,
  onClick,
}) => {
  const [t] = useTranslation();
  const photoCardClasses = cn({
    [styles.photoCardContainer]: true,
    [styles[`photoCardContainer-${color}`]]: true,
  });
  const photo = photoUrl ? `data:image/png;base64,${photoUrl}` : personIcon;
  return (
    <div className={photoCardClasses}>
      <div className={styles.photoCardBody}>
        <div className={styles.cardImage}>
          <img src={photo} alt="" style={{ width: '100%' }} />
        </div>
        <div className={styles.cardTextContainer}>
          <div className={styles.cardTextRow}>
            <Text type='subtitle1'>{t('ФИО')}</Text>
            <Text type='body1'>{fio}</Text>
          </div>
          <div className={styles.cardTextRow}>
            <Text type='subtitle1'>{t('ПИНФЛ')}</Text>
            <Text type='body1'>{pinfl}</Text>
          </div>
          <div className={styles.cardTextColumn}>
            <div className={styles.cardTextDate}>
              <Text type='subtitle1'>{t('Дата рождения')}</Text>
              <Text type='body1'>{birthDate}</Text>
            </div>
            <div className={styles.cardTextConcurrence}>
              <Text type='subtitle1'>{t('Совпадение(%)')}</Text>
              <Text type='body1'>{score}</Text>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          variant="purple"
          type='button'
          onClick={onClick}
        >
          {t('Выбрать и получить данные')}
        </Button>
      </div>
    </div>
  );
};

export default SearchByPhotoCard;
