import React, { useCallback } from 'react';
import { FiRefreshCcw, FiSettings } from 'react-icons/fi';
import { Action, Fab } from 'react-tiny-fab';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { forceReFetchCatalogs } from '../../catalogs/catalogsSlice';
// import cn from 'classnames';

const FAB = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const handleReloadSystem = useCallback(() => {
    const isConfirmed = window.confirm(t('Вы уверены?\nПриложение будет полностью перезагружено и Вам придется зайти заново'));
    if (isConfirmed) {
      if ('URL' in window) {
        const url = new URL(window.location.href);
        url.searchParams.set('reloadTime', Date.now().toString());
        window.location.href = url.toString();
      } else {
        window.location.href = `${window.location.origin
          + window.location.pathname
          + window.location.search
          + (window.location.search ? '&' : '?')
        }reloadTime=${
          Date.now().toString()
        }${window.location.hash}`;
      }
      localStorage.removeItem('TOKEN');
    }
  }, [t]);

  const handleReloadCatalogs = useCallback(() => {
    const isConfirmed = window.confirm(t('Вы уверены?\nЭто действие может занять некоторое время'));
    if (isConfirmed) {
      dispatch(forceReFetchCatalogs());
    }
  }, []);

  return (
    <Fab
      icon={<FiSettings />}
      alwaysShowTitle
      style={{
        bottom: 0,
        right: 0,
      }}
    >
      <Action
        text={t('Перезагрузить систему')}
        onClick={handleReloadSystem}
      >
        <FiRefreshCcw />
      </Action>
      <Action
        text={t('Перезагрузить справочники')}
        onClick={handleReloadCatalogs}
      >
        <FiRefreshCcw />
      </Action>
    </Fab>
  );
};

export default FAB;
