import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { identity } from 'ramda';
import styles from './InputAutoCompleteMultiple.module.scss';

const InputAutoCompleteMultiple = ({
  options,
  label,
  placeholder,
  disabled,
  asterisk,
  value,
  error,
  onChange = identity,
}) => {
  const [t] = useTranslation();
  const colorStyles = {
    control: (style) => ({ ...style, backgroundColor: 'white' }),
    option: (style, { isDisabled }) => ({
      ...style,
    }),
  };
  return (
    <div>
      {label && (
        <div className="input-label">
          {label} {asterisk && <span className="input-asterisk">*</span>}
        </div>
      )}
      <Select
        disabled={disabled}
        placeholder={placeholder}
        styles={colorStyles}
        value={value}
        closeMenuOnSelect={false}
        isMulti
        classNamePrefix="select"
        menuPortalTarget={document.body}
        onChange={onChange}
        options={options}
      />
      {
        error && (
          <div className={styles.error}>
            {error}
          </div>
        )
      }
    </div>
  );
};

export default InputAutoCompleteMultiple;
