import React from 'react';
import ContentLoader from 'react-content-loader';

const Skeleton = ({
  width = 100, height = 16,
}) => {

  return (
    <ContentLoader
      width={width}
      height={height}
      backgroundColor="#c9b7fb"
      foregroundColor="#4a0cfb"
    >
      <rect x="4" y="4" rx="3" ry="3" width={width - 10} height={height - 4} />
    </ContentLoader>
  );
};

export default Skeleton;
