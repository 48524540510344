import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty, not } from 'ramda';
import { toast } from 'react-toastify';
import { useAppRoutes } from '../app/appSlice';
import {
  getInsurance, getTaxiLicense, getTechPassport, getTinting, getVehicleInspection,
  resetTransport, searchTexPassByChip, selectTexPass,
  useArrest,
  useSelectedTexPass,
  useTransportData,
  useTransportInfoState,
} from './transportSlice';
import { getWantedInfo, resetWanted } from '../wanted/wantedSlice';
import { getAttorneys, resetAttorneys } from '../attorneys/attorneysSlice';
import { ReactComponent as BadgeIcon } from '../../icons/badge.svg';
import { ReactComponent as SourceIcon } from '../../icons/source.svg';
import { ReactComponent as CarIcon } from '../../icons/car-purple.svg';
import { ReactComponent as CarTruckIcon } from '../../icons/carTruck.svg';
import { ReactComponent as Edit2Icon } from '../../icons/edit2.svg';
import { ReactComponent as PersonSearchIcon } from '../../icons/person-search.svg';
import TopBar from '../../components/TopBar';
import Button from '../../components/Button';
import { ReactComponent as Trash } from '../../icons/trash.svg';
import Card from '../../components/Card';
import SearchActions from '../../components/SearchActions';
import Divider from '../../components/Divider';
import WantedCard from '../wanted/WantedCard';
import VehicleInfoContainer from './VehicleInfoContainer';
import CardHeader from '../../components/CardHeader';
import { ReactComponent as Gavel1Icon } from '../../icons/gavel.svg';
import DialogAttorneyCreate from '../attorneys/DialogAttorneyCreate';
import AttorneysSelects from '../attorneys/AttorneysSelects';
import styles from './TransportRouter.module.scss';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import TransportByNumberDialogForm from './TransportByNumberDialogForm';
import useToggler from '../../hooks/useToggler';
import ConfirmDialog from '../../components/ConfirmDialog';
import SetTexPassDialog from './SetTexPassDialog';
import { hideLoader, showLoader } from '../loaderSlice';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { searchOnePersonByIdChip } from '../person/personSlice';

const TransportForm = ({ onChange }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const dispatch = useDispatch();
  const transportInfoState = useTransportInfoState();
  const transportInfo = useTransportData();
  const techPassport = useSelectedTexPass();
  const arrest = useArrest();
  const [open, handleOpen, handleClose] = useToggler();
  const [openTexpass, handleOpenTexpass, handleCloseTexPass] = useToggler();
  const notEmpty = not(isEmpty(techPassport));

  const searchVehicleByTexPass = async (texPass) => {
    if (texPass.vehicleNumber) {
      console.log('work');
      const promises = [
        dispatch(getInsurance({ vehicleNumber: texPass.vehicleNumber })),
        dispatch(getTinting({ vehicleNumber: texPass.vehicleNumber })),
        dispatch(getTaxiLicense({ vehicleNumber: texPass.vehicleNumber })),
        dispatch(getAttorneys({ vehicleNumber: texPass.vehicleNumber })),
        dispatch(getWantedInfo({ vehicleNumber: texPass.vehicleNumber, isClosed: false, isDeleted: false })),
        dispatch(getVehicleInspection({ vehicleNumber: texPass.vehicleNumber })),
      ];
      await Promise.all(promises);
    }
    handleClose();
  };

  const setAutoLicenseDecorated = useDecoratedHandle(async (serial, nfcDocType) => {
    if (!serial || serial === 'null') {
      toast.error(t('Не удалось прочитать данные с чипа'));
      return;
    }
    if (nfcDocType === 'idCard') {
      const { pinpp } = await dispatch(searchOnePersonByIdChip({ number: serial })).unwrap();
      await dispatch(getTechPassport({ pinpp })).unwrap();
      handleOpenTexpass();
    }
    if (nfcDocType === 'texPass') {
      const texPass = await dispatch(searchTexPassByChip({ number: serial })).unwrap();
      await searchVehicleByTexPass(texPass);
    }
  }, true);

  const handleReadChipNumber = (nfcDocType) => () => {
    window.setAutoLicense = (serial) => setAutoLicenseDecorated(serial, nfcDocType);
    if (!window.EmiInterface) {
      alert('Нет подходящего оборудования');
      return;
    }
    window.EmiInterface.readAutoLicense();
  };

  const handleSearchByVehicleNumber = () => {
    handleOpen();
  };

  const handleTransportHandy = () => {
    dispatch(resetWanted());
    dispatch(resetTransport());
    dispatch(resetAttorneys());
    navigate('handy');
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleReset = () => {
    dispatch(resetTransport());
    dispatch(resetAttorneys());
    dispatch(resetWanted());
  };

  const handleClickDangerSticker = () => {
    console.log('DANGER STICKER');
  };

  const handleClickParkingFine = () => {
    navigate('parking-fine');
  };

  const handleClickSubmitTransport = () => {
    navigate(appRoutes.ProtocolCreationForm, { replace: true });
    onChange(transportInfo);
  };

  const actions = useMemo(() => ([
    {
      id: 'id-card',
      label: t('ID КАРТА (NFC)'),
      onClick: handleReadChipNumber('idCard'),
      Icon: BadgeIcon,
      loading: (transportInfoState === 'pending'),
      disabled: false,
      type: 'button',
    },
    {
      id: 'tech-pass',
      label: t('ТЕХ ПАСПОРТ (NFC)'),
      onClick: handleReadChipNumber('texPass'),
      Icon: SourceIcon,
      loading: false,
      disabled: false,
      type: 'button',
    },
    {
      id: 'plate',
      label: t('НОМЕР АВТО'),
      onClick: handleSearchByVehicleNumber,
      Icon: CarIcon,
      loading: false,
      disabled: false,
      type: 'button',
    },
    {
      id: 'handy',
      label: t('РУЧНОЙ ВВОД'),
      onClick: handleTransportHandy,
      Icon: Edit2Icon,
      loading: false,
      disabled: false,
      type: 'button',
    },
    // {
    //   id: 'photo',
    //   label: t('ПОИСК ПО ФОТО'),
    //   onClick: () => {
    //
    //   },
    //   Icon: PersonSearchIcon,
    //   loading: false,
    //   disabled: true,
    //   type: 'button',
    // },
  ]), [t, transportInfoState]);

  return (
    <>
      <TopBar
        title={t('Транспорт')}
        onBack={handleBack}
        extra={() => (
          <ConfirmDialog
            confirmText={t('Вы действительно хотите очистить?')}
            onConfirm={handleReset}
            renderButton={(onClick) => (
              <Button
                type="button"
                variant="textWhite"
                onClick={onClick}
                prependIcon={<Trash />}
              >
                {t('Очистить')}
              </Button>
            )}
          />
        )}
      />
      <Card>
        <SearchActions actions={actions} />
        <Divider mt={5} mb={5} />
        <WantedCard />
        <VehicleInfoContainer />
      </Card>
      {
        notEmpty && (
          <Card style={{ marginTop: 10 }}>
            <CardHeader
              label={t('Доверенность')}
              icon={<Gavel1Icon />}
              extra1={<DialogAttorneyCreate />}
            />
            <Divider mt={3} mb={3} color="#E1F0FF" />
            <AttorneysSelects />
          </Card>
        )
      }
      <div className={styles.viewActions}>
        <div className={styles.viewActionsItem}>
          <Button
            type="button"
            variant="action"
            fullWidth
            size='medium'
            onClick={handleClickParkingFine}
            appendIcon={<CarTruckIcon />}
            disabled={isEmpty(techPassport) || !isEmpty(arrest)}
            // disabled={false}
          >
            {t('Штраф площадка')}
          </Button>
        </div>
        <div className={styles.viewActionsItem}>
          <Button
            type="button"
            variant="purple"
            fullWidth
            size="medium"
            onClick={handleClickSubmitTransport}
            appendIcon={<DownloadIcon />}
            // disabled={isEmpty(transportInfo)}
            disabled={false}
          >
            {t('Составить протокол')}
          </Button>
        </div>
      </div>
      <TransportByNumberDialogForm
        open={open}
        handleClose={handleClose}
        onSubmit={handleOpenTexpass}
      />
      <SetTexPassDialog
        open={openTexpass}
        handleClose={handleCloseTexPass}
      />
    </>
  );
};

export default TransportForm;
