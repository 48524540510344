import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppRoutes } from '../../features/app/appSlice';

const RequireAuth = ({ children }) => {
  const token = localStorage.getItem('TOKEN');
  const appRoutes = useAppRoutes();
  const location = useLocation();

  return token ? children : <Navigate to={appRoutes.Entry} state={{ from: location }} replace />;
};

export default RequireAuth;
