import React from 'react';
import cn from 'classnames';

import './TextInput.scss';

const TextInput = ({
  label = '',
  asterisk = false,
  error = null,
  ...rest
}) => {
  const wrapperClasses = cn(
    'input-wrapper',
    error && 'input-error',
  );
  return (
    <div className={wrapperClasses}>
      {label && (
        <div className="input-label">
          {label} {asterisk && <span className="input-asterisk">*</span>}
        </div>
      )}
      <input className="input" {...rest} />
      {
        error && (
          <div className="input-error-text">
            {error}
          </div>
        )
      }
    </div>
  );
};

export default TextInput;
