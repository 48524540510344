import React from 'react';
// import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Spinner from 'react-svg-spinner';
import { isEmpty } from 'ramda';
import styles from './PunishmentDialog.module.scss';
import Dialog from '../../../components/Dialog';
import {
  clearPunishmentErrorByPinpp,
  clearPunishmentErrorByPlateNumber,
  clearPunishmentErrorByRadar,
  closePunishmentDialog,
  getPunishmentByPinpp,
  getPunishmentByPlateNumber,
  getRadarPunishments,
  useErrorPunishmentByPinpp,
  useErrorPunishmentByPlateNumber,
  useErrorPunishmentByRadar,
  usePunishmentDialogState,
  usePunishmentQueryType,
  usePunishments,
  usePunishmentsByPinppState,
  usePunishmentsState,
  useRadarPunishments,
  useRadarPunishmentsState,
} from '../punishmentsSlice';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import { downloadFileGeneratedPDF, useFileRequestState } from '../../files/filesSlice';
import useDecoratedHandle from '../../../hooks/useDecoratedHandle';
import PunishmentCard from '../../../components/PunishmentCard';
import RadarPunishmentCard from '../../../components/RadarPunishmentCard';
import { useSelectedTexPass } from '../../transport/transportSlice';
import PunishmentErrorContainer from '../PunishmentErrorContainer';
import { useSelectedPerson } from '../../person/personSlice';

const PunishmentDialog = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isOpen = usePunishmentDialogState();
  const punishments = usePunishments();
  const radarPunishments = useRadarPunishments();
  const radarPunishmentsState = useRadarPunishmentsState();
  const punishmentRequestState = usePunishmentsState();
  const fileDownloadState = useFileRequestState();
  const errorByPlateNumber = useErrorPunishmentByPlateNumber();
  const errorByPinpp = useErrorPunishmentByPinpp();
  const errorByRadar = useErrorPunishmentByRadar();
  const punishmentsByPinppState = usePunishmentsByPinppState();
  const punishmentQueryType = usePunishmentQueryType();
  const texPass = useSelectedTexPass();
  const selectedPerson = useSelectedPerson();
  const handleClose = () => {
    dispatch(closePunishmentDialog());
  };

  const errorTextByQueryType = {
    vehicleNumber: t('Нарушения по номеру авто'),
    pinpp: t('Нарушения по PINPP'),
  };

  const handleDownloadProtocol = useDecoratedHandle(async (decisionPdf) => {
    await dispatch(downloadFileGeneratedPDF({ src: decisionPdf })).unwrap();
  });
  const punishmentTypes = {
    PENALTY: 'Штраф',
    LICENSE_REVOCATION: 'Лишение',
  };

  const refreshViolationHistoryByPlateNumber = useDecoratedHandle(async () => {
    dispatch(clearPunishmentErrorByPlateNumber());
    await dispatch(getPunishmentByPlateNumber(texPass.vehicleNumber));
  });

  const refreshViolationHistoryByPinpp = useDecoratedHandle(async () => {
    dispatch(clearPunishmentErrorByPinpp());
    await dispatch(getPunishmentByPinpp(selectedPerson.pinpp));
  });

  const refreshViolationHistoryByRadar = useDecoratedHandle(async () => {
    const normalizedValueByQueryType = {
      vehicleNumber: {
        vehicleNumber: texPass.vehicleNumber,
        texPassSeries: texPass.texPassSeries,
        texPassNumber: texPass.texPassNumber,
      },
      pinpp: {
        pinpp: selectedPerson.pinpp,
      },
    };
    dispatch(clearPunishmentErrorByRadar());
    await dispatch(getRadarPunishments(normalizedValueByQueryType[punishmentQueryType]));
  });

  const punishmentErrorByQueryType = {
    vehicleNumber: errorByPlateNumber,
    pinpp: errorByPinpp,
  };

  const handleRefreshByQueryType = {
    vehicleNumber: refreshViolationHistoryByPlateNumber,
    pinpp: refreshViolationHistoryByPinpp,
  };

  const punishmentStateByQueryType = {
    vehicleNumber: punishmentRequestState,
    pinpp: punishmentsByPinppState,
  };

  return (
    <Dialog
      open={isOpen}
      title={t('История правонарушений')}
      onClose={handleClose}
    >
      {
        (punishmentStateByQueryType[punishmentQueryType] === 'pending' || radarPunishmentsState === 'pending')
          ? (
            <div className={styles.spinner}>
              <Spinner size="40px" thickness={3} color="#404045" />
            </div>
          )
          : (
            <div className={styles.punishmentDialog}>
              <div className={styles.punishmentDialogBody}>
                <div>
                  <Text type="h2" className={styles.headerText}>
                    {errorTextByQueryType[punishmentQueryType]}
                  </Text>
                  {
                    isEmpty(punishmentErrorByQueryType[punishmentQueryType]) ? (
                      <>
                        {
                          punishments.length > 0
                            ? punishments.map((punishment) => (
                              <PunishmentCard
                                type={punishmentTypes[punishment.punishmentType]}
                                key={punishment.punishmentId}
                                loading={fileDownloadState === 'pending'}
                                handleDownload={handleDownloadProtocol}
                                punishment={punishment}
                                isOverdue={punishment.isOverdue}
                              />
                            )) : (
                              <Text type='h3'>{t('Нарушений нет')}</Text>
                            )
                        }
                      </>
                    ) : (
                      <PunishmentErrorContainer
                        errorText={t('При проверке нарушений возникла ошибка')}
                        onRefresh={handleRefreshByQueryType[punishmentQueryType]}
                        loading={punishmentStateByQueryType[punishmentQueryType] === 'pending'}
                      />
                    )
                  }
                </div>
                <div>
                  <Text type="h2" className={styles.headerText}>
                    {t('Нарушения по радарам')}
                  </Text>
                  {
                    isEmpty(errorByRadar) ? (
                      <>
                        {
                          radarPunishments.length > 0
                            ? radarPunishments.map((punishment) => (
                              <RadarPunishmentCard
                                punishment={punishment}
                                key={punishment.number}
                                loading={fileDownloadState === 'pending'}
                                handleDownload={handleDownloadProtocol}
                              />
                            )) : (
                              <Text type='h3'>{t('Нарушений нет')}</Text>
                            )
                        }
                      </>
                    ) : (
                      <PunishmentErrorContainer
                        errorText={t('При проверке нарушений возникла ошибка')}
                        onRefresh={refreshViolationHistoryByRadar}
                        loading={radarPunishmentsState === 'pending'}
                      />
                    )
                  }
                </div>
              </div>
              <div className={styles.punishmentDialogFooter}>
                <div className={styles.punishmentDialogFooterItem}>
                  <Button
                    type="button"
                    onClick={handleClose}
                    variant="purple"
                    size="medium"
                    fullWidth
                  >
                    {t('Закрыть')}
                  </Button>
                </div>
              </div>
            </div>
          )
      }
    </Dialog>
  );
};

export default PunishmentDialog;
