import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ViolatorForm from './ViolatorForm';
import ViolatorContainer from './ViolatorContainer';
import PersonSearch from './PersonSearch';
import ViolatorHandy from './ViolatorHandy';
import SearchViolatorByPhoto from './SearchPersonByPhoto';

const ViolatorRouter = ({ onChange }) => (
    <Routes>
      <Route path='/' element={ <ViolatorContainer /> } />
      <Route path='violator' element={ <ViolatorForm onChange={onChange} /> } />
      <Route path='violator/form1' element={ <PersonSearch /> } />
      <Route path='violator/handy' element={ <ViolatorHandy /> } />
      <Route path='violator/search-person-by-photo' element={ <SearchViolatorByPhoto /> } />
    </Routes>
);

export default ViolatorRouter;
