import React from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

const Text = ({
  type = 'h1',
  center = false,
  inline = false,
  className,
  children,
}) => {
  const classes = cn({
    [styles.text]: true,
    [styles.h1]: type === 'h1',
    [styles.h2]: type === 'h2',
    [styles.h3]: type === 'h3',
    [styles.body1]: type === 'body1',
    [styles.body2]: type === 'body2',
    [styles.subtitle1]: type === 'subtitle1',
    [styles.subtitle2]: type === 'subtitle2',
    [styles.centered]: center,
    [styles.inline]: inline,
    [className]: true,
  });

  return (
    <div className={classes}>{children}</div>
  );
};

export default Text;
