import React from 'react';

import { Field } from 'react-final-form';
import { identity } from 'ramda';
import InputAutoCompleteMultiple from './index';

const Adapter = ({
  name,
  label,
  asterisk,
  placeholder,
  options = [],
  onChange = identity,
  disabled,
}) => (
  <Field name={name}>
    {({ input, meta }) => (
      <InputAutoCompleteMultiple
        label={label}
        asterisk={asterisk}
        error={meta.touched && meta.error}
        value={input.value}
        options={options}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(value) => {
          if (value) {
            input.onChange(value);
            onChange(value);
            return;
          }
          input.onChange(undefined);
          onChange(undefined);
        }}
      />
    )}
  </Field>
);

export default Adapter;
