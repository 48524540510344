import React, { useEffect } from 'react'
// import cn from 'classnames';

import { useLocation, useNavigate } from 'react-router'
import queryString from 'query-string'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toUpper } from 'ramda'
import styles from './MainForm.module.scss'
import Text from '../../../components/Text'
import InputText from '../../../components/InputText'
import InputDate from '../../../components/InputDate'
import Button from '../../../components/Button'
import { ReactComponent as SearchIcon } from '../../../icons/search.svg'
import useDecoratedHandle from '../../../hooks/useDecoratedHandle'
import { searchByForm1, useForm1State } from '../personSlice'
import { kirToLat } from '../../../utils'

const MainForm = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const form1State = useForm1State()
  const location = useLocation()
  const navigate = useNavigate()

  const handleSubmit = useDecoratedHandle(async (values) => {
    const normalizedValues = {
      ...values,
      firstName: kirToLat(values.firstName) || null,
      secondName: kirToLat(values.secondName) || null,
      lastName: kirToLat(values.lastName) || null,
      series: kirToLat(values.series) || null,
    }

    navigate({
      pathname: location.pathname,
      search: queryString.stringify({ ...normalizedValues }),
    })

    await dispatch(searchByForm1(normalizedValues)).unwrap()
  })

  const validate = () => ({})

  return (
    <Form onSubmit={handleSubmit} validate={validate}>
      {({ handleSubmit: submit }) => (
        <form className={styles.form1} onSubmit={submit}>
          <Text type="h1" center>
            {t('Поиск нарушителя по ФИО')}
          </Text>
          <div className={styles.form1Wrapper}>
            <div className={styles.form1WrapperItem}>
              <InputText
                name="lastName"
                label={t('Фамилия')}
                disabled={form1State === 'pending'}
                parse={(value) => toUpper(value)}
              />
            </div>
            <div className={styles.form1WrapperItem}>
              <InputText
                name="firstName"
                label={t('Имя')}
                disabled={form1State === 'pending'}
                parse={(value) => toUpper(value)}
              />
            </div>
            <div className={styles.form1WrapperItem}>
              <InputText
                name="secondName"
                label={t('Отчество')}
                disabled={form1State === 'pending'}
                parse={(value) => toUpper(value)}
              />
            </div>
            <div className={styles.form1WrapperItem}>
              <div className={styles.doubleInput}>
                <div className={styles.doubleInputLabel}>
                  <div className="input-label">
                    {t('Серия и номер паспорта')}
                  </div>
                </div>
                <div className={styles.doubleInputForm}>
                  <div className={styles.doubleInputFormSerial}>
                    <InputText
                      name="series"
                      disabled={form1State === 'pending'}
                      parse={(value) => toUpper(value)}
                    />
                  </div>
                  <div className={styles.doubleInputFormNumber}>
                    <InputText
                      name="number"
                      disabled={form1State === 'pending'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.form1WrapperItem}>
              <InputDate
                name="birthFrom"
                label={t('Дата рождения')}
                disabled={form1State === 'pending'}
              />
            </div>
            <div className={styles.form1WrapperItem}>
              <Button
                type="submit"
                variant="purple"
                fullWidth
                loading={form1State === 'pending'}
                appendIcon={<SearchIcon />}
                style={{ marginBottom: '16px' }}
              >
                {t('Поиск')}
              </Button>
            </div>
          </div>
        </form>
      )}
    </Form>
  )
}

export default MainForm
