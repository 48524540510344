import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './TransportRouter.module.scss';
import Card from '../../components/Card';
import Text from '../../components/Text';
import { ReactComponent as RightArrow } from '../../icons/right-arrow.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import InputAutocomplete from '../../components/InputAutocomplete';
import Button from '../../components/Button';
import InputText from '../../components/InputText';
import { createArrest, useSelectedTexPass } from './transportSlice';
import { useAutocompleteItems } from '../catalogs/catalogsSlice';
import useToggler from '../../hooks/useToggler';
import UploadScanDialog from '../../components/UploadScanDialog';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import InputAutoCompleteMultiple from '../../components/InputAutoCompleteMultiple/Adapter';
import { composeValidators, required, validateIf } from '../../validators';
import RFFValidationError from '../../components/RFFValidationError';

const ParkingFineForm = () => {
  const [t] = useTranslation();
  const transport = useSelectedTexPass();
  const dispatch = useDispatch();
  const getVehicleArrestPlaces = useAutocompleteItems('vehicleArrestPlaces');
  const getVehicleArrestReasons = useAutocompleteItems('vehicleArrestReasons');
  const [open, handleOpen, handleClose] = useToggler();
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = useDecoratedHandle(async (values) => {
    const normalizer = {
      ...values,
      vehicleNumber: transport.vehicleNumber,
      vehicleBrand: transport.vehicleBrand,
      vehicleColor: transport.vehicleColor,
      files,
      arrestReasons: values.arrestReasons.map((value) => value.id),
    };
    await dispatch(createArrest(normalizer)).unwrap();
    toast.success(t('Арест добавлен'));
    navigate(-1);
  });

  const handleClickDownloadScan = () => {
    handleOpen();
  };

  const handleAddScan = (values) => {
    setFiles([
      ...files,
      {
        documentTypeId: values.documentTypeId,
        path: values.document.path,
      },
    ]);
    handleClose();
  };

  const initialValues = {
    vehicleOwnerName: transport?.vehicleOwnerFirstName ? [transport.vehicleOwnerLastName, transport.vehicleOwnerFirstName, transport.vehicleOwnerSecondName].join(' ') : null,
  };

  const validate = (values) => ({
    vehicleOwnerName: composeValidators(required)(values.vehicleOwnerName),
    arrestPlaceId: composeValidators(required)(values.arrestPlaceId),
    arrestReasons: composeValidators(validateIf(!values.arrestReasons || values.arrestReasons.length === 0))(required)(undefined),
    files: composeValidators(validateIf(files.length === 0))(required)(undefined),
  });

  return (
    <>
      <Form onSubmit={handleSubmit} validate={validate} initialValues={initialValues}>
        {({ handleSubmit: submit, form, values }) => (
          <form onSubmit={submit}>
            <Card>
              <Text type="h1" center>{t('Штраф стоянка')}</Text>
              <div>
                <InputText
                  label={t('Имя владельца')}
                  name="vehicleOwnerName"
                  placeholder={t('Введите имя владельца')}
                />
              </div>
              <div>
                <InputAutocomplete
                  label={t('Штраф стоянка')}
                  name="arrestPlaceId"
                  placeholder={t('Выберите куда')}
                  options={getVehicleArrestPlaces()}
                />
              </div>
              <div>
                <InputAutoCompleteMultiple
                  label={t('Причина')}
                  name="arrestReasons"
                  placeholder={t('Выберите причину')}
                  options={getVehicleArrestReasons()}
                />
              </div>
              {/* <div> */}
              {/*  <InputDate name="registrationDate" label={t('Дата научет')} /> */}
              {/* </div> */}
              <div className={styles.scanBlock}>
                <Text type="h3">{t('Файл документа')}</Text>
                <Button
                  type="button"
                  variant="action"
                  size='medium'
                  onClick={handleClickDownloadScan}
                  appendIcon={<DownloadIcon />}
                  style={{ marginLeft: '20px' }}
                >
                  {t('Загрузить скан')}
                </Button>
              </div>
              <div className={styles.fileValidateText}>
                <RFFValidationError name='files' />
              </div>
              {
                files.length > 0 && (
                  <div className={styles.filesView}>
                    {
                      files.map((file, id) => (
                        <div key={id} className={styles.fileItem}>
                          - {file.path}
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </Card>
            <div className={styles.footer}>
              <Button
                type="submit"
                variant="purple"
                size="medium"
                fullWidth
                appendIcon={<RightArrow />}
              >
                {t('Сохранить')}
              </Button>
            </div>
          </form>
        )}
      </Form>
      <UploadScanDialog
        open={open}
        handleClose={handleClose}
        onAddScan={handleAddScan}
      />
    </>
  );
};

export default ParkingFineForm;
