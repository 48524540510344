import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './PunishmentCard.module.scss';
import LabeledText from '../LabeledText';
import StatusLabel from '../StatusLabel';
import { formatDate, toSom } from '../../utils';
import Button from '../Button';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { useCatalogsAlias, useCatalogsName } from '../../features/catalogs/catalogsSlice';
import { ReactComponent as WarningIcon } from '../../icons/warning.svg';
import Text from '../Text';
import useAdmStatusColors from '../../hooks/useAdmStatusColors';

const PunishmentCard = ({
  type,
  isOverdue,
  punishment,
  handleDownload,
  loading,
}) => {
  const [t] = useTranslation();
  const getAdmStatusName = useCatalogsName('admStatuses');
  const getArticlePartName = useCatalogsName('articleParts');
  const getViolationTypeName = useCatalogsName('violationTypes');
  const getRegionName = useCatalogsName('regions');
  const getDistrictName = useCatalogsName('districts');
  const getAdmStatusAlias = useCatalogsAlias('admStatuses');
  const getChipColor = useAdmStatusColors();
  const cardClasses = cn({
    [styles.card]: true,
    [styles.overdue]: isOverdue,
  });
  return (
    <div className={cardClasses}>
      <div className={styles.cardHeader}>
        <div className={styles.cardHeaderItem}>
          <Text type="body1" className={styles.dangerText}>{type}</Text>
        </div>
        <div className={styles.cardHeaderItem}>
          {
            isOverdue && (
              <div className={styles.overdueLabel}>
                <div className={styles.warningIcon}>
                  <WarningIcon />
                </div>
                {t('Истек срок')}
              </div>
            )
          }
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cardBodyBetween}>
          {
            type === 'Штраф' && (
              <div>
                <Text type="subtitle1">{punishment.isInMib ? t('Номер оплаты в MIB') : t('Номер квитанции')}</Text>
                <Text type="body1" className={styles.dangerText}>{(punishment.isInMib ? punishment.mibCaseNumber : punishment.invoiceSerial) || '——'}</Text>
              </div>
            )
          }
          {
            type === 'Лишение' && (
              <div>
                <Text type="subtitle1">{t('Срок')}</Text>
                <Text type="body1" className={styles.dangerText}>{punishment.mainPunishmentAmountText || '——'}</Text>
              </div>
            )
          }
          <StatusLabel text={getAdmStatusName(punishment.decisionStatusId)} type={getChipColor(getAdmStatusAlias(punishment.decisionStatusId))} />
        </div>
      </div>
      {
        type === 'Штраф' && (
          <>
            <div className={styles.cardBody}>
              <div className={styles.cardBodyPenalty}>
                <LabeledText label={t('Сумма штрафа')} text={toSom(punishment.mainPunishmentAmount)} />
              </div>
              <div className={styles.cardBodyPenalty}>
                <LabeledText label={t('Сумма со скидкой 70%')} text={toSom(punishment.punishmentDiscountAmount)} />
              </div>
              <div className={styles.cardBodyPenalty}>
                <LabeledText label={t('Скидка действительна до')} text={formatDate(punishment.punishmentDiscountForDate)} />
              </div>
            </div>
            <div className={styles.cardBody}>
              <div className={styles.cardBodyItem}>
                <LabeledText label={t('Сумма оплаты')} text={toSom(punishment.punishmentPaidAmount)} />
              </div>
              <div className={styles.cardBodyItem}>
                <LabeledText label={t('Дата оплаты')} text={formatDate(punishment.punishmentLastPayTime, true)} />
              </div>
            </div>
          </>
        )
      }
      {
        type === 'Лишение' && (
          <div className={styles.cardBody}>
            <div className={styles.cardBodyItem}>
              <LabeledText label={t('Дата начала')} text={formatDate(punishment.executionFromDate)} />
            </div>
            <div className={styles.cardBodyItem}>
              <LabeledText label={t('Дата окончания')} text={formatDate(punishment.licenseRevocationEndDate)} />
            </div>
          </div>
        )
      }
      <div className={styles.cardBody}>
        <div className={styles.cardBodyFullItem}>
          <LabeledText
            label={t('По нарушителю')}
            text={`${[punishment.violatorLastNameLat, punishment.violatorFirstNameLat, punishment.violatorSecondNameLat].join(' ')} / ${'01B555GC'}`}
          />
        </div>
        <div className={styles.cardBodyFullItem}>
          <LabeledText label={t('Решение')} text={`${[punishment.series, punishment.number].join('-')} от ${formatDate(punishment.resolutionTime, true)}`} />
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cardBodyItem}>
          <LabeledText label={t('Основная статья')} text={[getArticlePartName(punishment.articlePartId), getViolationTypeName(punishment.articleViolationTypeId)].join(' / ')} />
        </div>
        <div className={styles.cardBodyItem}>
          <LabeledText label={t('Область/Район')} text={[getRegionName(punishment.resolutionRegionId), getDistrictName(punishment.resolutionDistrictId)].join(' / ')} />
        </div>
      </div>
      <div className={styles.cardFooter}>
        <Button
          type="button"
          variant="action"
          size="medium"
          appendIcon={<DownloadIcon />}
          loading={loading}
          onClick={() => { handleDownload(punishment.decisionPdf); }}
        >
          {t('Скачать решение')}
        </Button>
      </div>
    </div>
  );
};

export default PunishmentCard;
