import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, not } from 'ramda';
import DangerSticker from '../../components/DangerSticker';
import { useWantedInfo, useWantedState } from './wantedSlice';
import Skeleton from '../../components/Skeleton';
// import cn from 'classnames';
import styles from './WantedCard.module.scss';
import LabeledText from '../../components/LabeledText';
import { useCatalogsName } from '../catalogs/catalogsSlice';
import Dialog from '../../components/Dialog';
import { formatDate } from '../../utils';

const WantedCard = () => {
  const [t] = useTranslation();
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const wantedInfo = useWantedInfo();
  const wantedState = useWantedState();
  const getWantedOrganName = useCatalogsName('wantedOrgans');
  const getRegionName = useCatalogsName('regions');
  const getDistrictName = useCatalogsName('districts');
  const getWantedReasonName = useCatalogsName('wantedReasons');
  const handleClickDangerSticker = () => {
    setDetailsVisible(!isDetailsVisible);
  };

  if (wantedState === 'pending') return <Skeleton width={452} height={55} />;

  return not(isEmpty(wantedInfo)) && (
    <>
      <DangerSticker
        text={t('Числится в базе угона')}
        onClick={handleClickDangerSticker}
        buttonText={isDetailsVisible ? t('Скрыть') : t('Подробнее')}
      />
      <Dialog
        open={isDetailsVisible}
        title={
          <span style={{ color: 'red', fontWeight: '700' }}>{t('Внимание Розыск')}</span>
        }
        onClose={handleClickDangerSticker}
      >
        <div className={styles.wantedContainer}>
          {wantedInfo.map((card) => (
            <div className={styles.wantedCard} key={card.id}>
              <div className={styles.wantedCardItem}>
                <LabeledText label={t('Орган объявивший розыск')} text={getWantedOrganName(card.wantedOrganId)} />
              </div>
              <div className={styles.wantedCardItem}>
                <LabeledText label={t('Дата объяв розыска')} text={formatDate(card.openDocumentDate)} />
              </div>
              <div className={styles.wantedCardItem}>
                <LabeledText label={t('Область')} text={getRegionName(card.regionId)} />
              </div>
              <div className={styles.wantedCardItem}>
                <LabeledText label={t('Район')} text={getDistrictName(card.districtId)} />
              </div>
              <div className={styles.wantedCardItem}>
                <LabeledText label={t('Причина розыска')} text={getWantedReasonName(card.wantedReasonId)} />
              </div>
              <div className={styles.wantedCardItem}>
                <LabeledText label={t('Дата прекращения розыска')} text={formatDate(card.closeDocumentDate)} />
              </div>
            </div>
          ))}
        </div>
      </Dialog>
    </>
  );
};

export default WantedCard;
