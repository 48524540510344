import React, { useCallback, useEffect, useState } from 'react';
// import cn from 'classnames';
import { useDispatch } from 'react-redux';
import logo from '../../assets/logo-emi.png';
import styles from './Entry.module.scss';
import AuthForm from '../../features/authData/AuthForm';
import { removeMemoFileUrl } from '../../features/files/filesSlice';
import { removeUserData } from '../../features/authData/authDataSlice';
import Tabs from '../../components/Tabs';
import AuthFormByUsername from '../../features/authData/AuthFormByUsername';

const loginBy = localStorage.getItem('loginBy');

const Entry = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(loginBy || 'ubdd');

  useEffect(() => {
    document.body.classList.add('no-bg');
  }, []);

  useEffect(() => {
    localStorage.removeItem('TOKEN');
    localStorage.removeItem('ECPPAS');
    dispatch(removeMemoFileUrl());
    dispatch(removeUserData());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <img src={logo} alt="" className={styles.image} />
          <div className={styles.appName}>
            E-protokol Evolution
          </div>
        </div>
        <div className={styles.body}>
          <Tabs
            tabs={[
              { key: 'ubdd', title: 'TN' },
              { key: 'rest', title: 'ADM' },
            ]}
            activeTab={activeTab}
            onTabClick={setActiveTab}
          />
          {
            activeTab === 'ubdd'
              ? <AuthForm />
              : null
          }
          {
            activeTab === 'rest'
              ? <AuthFormByUsername />
              : null
          }
        </div>
      </div>
    </div>
  );
};

export default Entry;
