import React from 'react';
import cn from 'classnames';
import ContentLoader from 'react-content-loader';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './UserInfoCard.module.scss';
import photo from '../../../assets/profile.png';
import Text from '../../../components/Text';
import LabeledText from '../../../components/LabeledText';
import Button from '../../../components/Button';
import { ReactComponent as ChartIcon } from '../../../icons/chart.svg';
import { ReactComponent as LayersIcon } from '../../../icons/layers.svg';
import LanguageSelect from '../../../components/LanguageSelect';
import Card from '../../../components/Card';
import { useAppRoutes } from '../../app/appSlice';
import {
  setRaidActive, setRaidInactive, useGetMe, useRaid,
} from '../authDataSlice';
import { formatDate } from '../../../utils';
import Skeleton from '../../../components/Skeleton';
import { useCatalogsName } from '../../catalogs/catalogsSlice';
import { ReactComponent as Trash } from '../../../icons/trash.svg';
import ConfirmDialog from '../../../components/ConfirmDialog';
import DangerSticker from '../../../components/DangerSticker';
import RaidDialog from '../RaidDialog';
import { ReactComponent as Privacy } from '../../../icons/privacy.svg';
import Img from '../../files/Img';

const UserInfoCard = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const dispatch = useDispatch();
  const getOrganName = useCatalogsName('organs');
  const getRegionName = useCatalogsName('regions');
  const getDistrictName = useCatalogsName('districts');
  const getDepartmentName = useCatalogsName('departments');

  const [userInfo, isFetching] = useGetMe();
  const raid = useRaid();

  const handleClickProtocolsAndResolutions = () => {
    navigate(appRoutes.ProtocolsAndResolutions);
  };

  const handleRaidModeInactive = () => {
    dispatch(setRaidInactive());
  };

  const cardClasses = cn({
    [styles.raidCard]: raid.isActive,
  });

  return (
    <>
      {
        raid.isActive && (
          <div className={styles.raidAlertContainer}>
            <div className={styles.raidAlertIcon}>
              <div className={styles.icon}>
                <Privacy />
              </div>
              {t('РЕЙД')}
            </div>
            <div className={styles.raidAlertItem}>{t(`Область: ${getRegionName(raid.regionId)}`)}</div>
            <div className={styles.raidAlertItem}>{t(`Район: ${getDistrictName(raid.districtId)}`)}</div>
          </div>
        )
      }
      <Card className={cardClasses}>
        <div className={styles.profile}>
          <div className={styles.photo}>
            <Img
              src={userInfo.photoUrl}
              className={styles.photoImg}
              skeleton={{ width: 50, height: 50 }}
              memo
            />
          </div>
          <div className={styles.name}>
            <Text type="body1">
              {
                isFetching
                  ? <Skeleton width={300} height={14} />
                  : [userInfo.person?.lastNameLat, userInfo.person?.firstNameLat, userInfo.person?.secondNameLat].join(' ')
              }
            </Text>
            <Text type="body1">
              {
                isFetching
                  ? <Skeleton width={100} height={14} />
                  : formatDate(userInfo.person?.birthDate)
              }
            </Text>
          </div>
          <div>
            {
              raid.isActive ? (
                <ConfirmDialog
                  confirmText={t('Вы действительно хотите выйти с режима РЕЙД?')}
                  onConfirm={handleRaidModeInactive}
                  renderButton={(onClick) => (
                    <Button
                      size='small'
                      type="button"
                      variant="transparent"
                      onClick={onClick}
                    >
                  {t('Выключить режим РЕЙД')}
                    </Button>
                  )}
                />
              ) : (
                <RaidDialog />
              )
            }
          </div>
        </div>
        <div className={styles.organ}>
          <div className={styles.organItem}>
            <LabeledText
              label={t('Орган')}
              text={
                isFetching
                  ? <Skeleton width={100} height={14} />
                  : getOrganName(userInfo.organId)
              }
            />
          </div>
          <div className={styles.organItem}>
            <LabeledText
              label={t('Область')}
              text={
                isFetching
                  ? <Skeleton width={100} height={14} />
                  : getRegionName(userInfo.regionId)
              }
            />
          </div>
          <div className={styles.organItem}>
            <LabeledText
              label={t('Район')}
              text={
                isFetching
                  ? <Skeleton width={100} height={14} />
                  : getDistrictName(userInfo.districtId)
              }
            />
          </div>
          <div className={styles.organItem}>
            <LabeledText
              label={t('Подразделение')}
              text={
                isFetching
                  ? <Skeleton width={100} height={14} />
                  : getDepartmentName(userInfo.departmentId)
              }
            />
          </div>
          <div className={styles.organItem}>
            <LabeledText
              label={t('Номер жетона')}
              text={isFetching ? <Skeleton width={100} height={14} /> : userInfo.workCertificate}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <div className={styles.actionButton}>
            <Button
              type="button"
              variant="transparent"
              prependIcon={<ChartIcon />}
              loading={isFetching}
              size="small"
              disabled
            >
              {t('Отчет')}
            </Button>
          </div>
          <div className={styles.actionButton}>
            <Button
              type="button"
              variant="transparent"
              prependIcon={<LayersIcon />}
              onClick={handleClickProtocolsAndResolutions}
              size="small"
              loading={isFetching}
            >
              {t('Мои протоколы')}
            </Button>
          </div>
          <div className={styles.actionButton}>
            <LanguageSelect disabled={isFetching} />
          </div>
        </div>
      </Card>
    </>
  );
};

export default UserInfoCard;
