import React, { useState } from 'react';
// import cn from 'classnames';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import {
  identity, includes, isEmpty, isNil, not, values as RValues,
} from 'ramda';
import { format, subMinutes } from 'date-fns';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import ProtocolCreation from '../../features/protocolCreations/ProtocolCreation';
import { useGetMe, useRaid } from '../../features/authData/authDataSlice';
import { useGPSData } from '../../features/services/servicesSlice';
import {
  formatDate, generateFabula, kirToLat, normalizeNumber,
} from '../../utils';
import { useCatalogsName } from '../../features/catalogs/catalogsSlice';
import { useRegisteredArticlePartViolationsSplitById } from '../../features/dependentCatalogs/dependentCatalogsSlice';
import { useAppRoutes } from '../../features/app/appSlice';
import { resetTransport, useArrest, useSelectedTexPass } from '../../features/transport/transportSlice';
import {
  appendDocumentsToAdmCase,
  appendExplanatoryToAdmCase,
  appendWantedInfoToProtocol,
  calculateCaseMovement, createAttorney,
  createDriverLicense,
  createInsurance,
  createMainProtocol,
  createMainRaidProtocol,
  createTexPass,
  createTinting, createVehicleInspection,
  resetQualification,
  setGaiDataService,
  useCaseMovement,
} from '../../features/protocolCreations/protocolCreationsSlice';
import { resetAttorneys, useSelectedAttorney } from '../../features/attorneys/attorneysSlice';
import {
  getDriverLicense,
  resetAdditionalPersonInfo,
  resetSelectedPerson,
  useAdditionalPersonInfo,
} from '../../features/person/personSlice';
import { resetWanted, useWantedInfo } from '../../features/wanted/wantedSlice';
import PedestrianProtocolCreation from '../../features/protocolCreations/PedestrianProtocolCreation';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { composeValidators, required, validateIf } from '../../validators';
import MakeDecisionConfirmDialog from '../../features/protocolCreations/MakeDecisionConfirmDialog';
import useToggler from '../../hooks/useToggler';

const ProtocolCreationForm = () => {
  let submit;
  const [t] = useTranslation();
  const [user] = useGetMe();
  const raid = useRaid();
  const gpsData = useGPSData();
  const dispatch = useDispatch();
  const getRegionName = useCatalogsName('regions');
  const getDistrictName = useCatalogsName('districts');
  const getArticlePartName = useCatalogsName('articleParts');
  const getViolationTypeName = useCatalogsName('violationTypes');
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const attorney = useSelectedAttorney();
  const techPassport = useSelectedTexPass();
  const arrest = useArrest();
  const personAdditionalData = useAdditionalPersonInfo();
  const getArticleViolationTypes = useRegisteredArticlePartViolationsSplitById();
  const [open, handleOpen, handleClose] = useToggler();
  const location = useLocation();

  const handleSubmit = useDecoratedHandle(async (values) => {
    const [articlePartId, violationTypeId] = getArticleViolationTypes(values.violationType);
    const regionId = raid.isActive ? values.regionId : user.regionId;
    const districtId = raid.isActive ? raid.districtId : values.district;
    const { person, driverLicenses } = values.violator;
    const { violator, transport } = values;
    const tinting = transport?.tintings[0];
    const insurances = transport?.insurances[0];
    const driverLicense = driverLicenses[0];
    const vehicleInspection = transport?.vehicleInspection[0];

    const additionArticles = values.additionalArticles
      ? values.additionalArticles
        .filter(identity)
        .map(({ violationType }) => ({
          articlePartId: getArticleViolationTypes(violationType)[0],
          articleViolationTypeId: getArticleViolationTypes(violationType)[1],
        }))
      : null;

    const isAgreeByAnswer = {
      yes: true,
      no: false,
    };
    const violationTime = subMinutes(new Date(), 5);

    const fabula = generateFabula({
      violationDateParsed: format(violationTime, 'dd.MM.yyyy'),
      violationTimeParsed: format(violationTime, 'HH:mm'),
      region: getRegionName(regionId),
      district: getDistrictName(districtId),
      violatorFullName: [person.lastNameKir, person.firstNameKir, person.secondNameKir].join(' '),
      violatorBirthDate: formatDate(person.birthDate),
      violationPart: getArticlePartName(articlePartId),
      violationType: getViolationTypeName(violationTypeId),
      vehicleNumber: techPassport?.vehicleNumber,
      vehicleBrand: techPassport?.vehicleBrand,
    });
    const normalizedValues = {
      articlePartId,
      articleViolationTypeId: violationTypeId,
      additionArticles,
      regionId,
      districtId,
      address: values.address,
      violationTime: format(violationTime, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS'),
      isFamiliarize: Boolean(personAdditionalData.sign),
      isAgree: isAgreeByAnswer[values.isAgree],
      fabula,
      fabulaAdditional: `${fabula}\n${values.fabulaAdditional}`,
      isTablet: true,
      audioUri: null, // ???????
      videoUri: null, // ???????
      latitude: (window.EmiInterface && JSON.parse(window.EmiInterface.getLocation()))
        ? JSON.parse(window.EmiInterface.getLocation()).latitude
        : null,
      longitude: (window.EmiInterface && JSON.parse(window.EmiInterface.getLocation()))
        ? JSON.parse(window.EmiInterface.getLocation()).longitude
        : null,
      inspectorSignature: personAdditionalData.inspectorSign || null, // ???????
      ubddGroupId: null, // ???????
      violator: {
        pinpp: person.pinpp || null,
        // document: includes(Number(person.personDocumentTypeId), [1, 2, 3, 4, 5, 9, 8, 21, 22, 23, 18, 19]) ? {
        document: {
          personDocumentTypeId: person.personDocumentTypeId,
          documentSeries: person.series,
          documentNumber: person.number,
          documentGivenDate: person.givenDate,
          documentExpireDate: person.expireDate,
          documentGivenAddress: person.givenAddress,
        },
        person: person.pinpp ? null : {
          firstNameKir: person.firstNameKir,
          secondNameKir: person.secondNameKir,
          lastNameKir: person.lastNameKir,
          firstNameLat: kirToLat(person.firstNameKir),
          secondNameLat: kirToLat(person.secondNameKir),
          lastNameLat: kirToLat(person.lastNameKir),
          birthDate: person.birthDate,
          citizenshipTypeId: person.citizenshipTypeId,
          genderId: person.genderId,
          nationalityId: person.nationalityId,
          birthAddress: {
            countryId: person.birthCountryId,
            regionId: person.birthRegionId,
            districtId: person.birthDistrictId,
            address: person.birthAddress,
          },
        },
        mobile: normalizeNumber(violator.mobile),
        landline: null, // ???????
        notificationViaSms: true, // ???????
        notificationViaMail: true, // ???????
        dependentAmount: null, // ???????
        inn: null, // ???????
        healthStatus: null, // ???????
        educationLevel: null, // ???????
        maritalStatus: null, // ???????
        childrenAmount: null, // ???????
        violationRepeatabilityStatus: null, // ???????
        earlierViolatedArticleParts: null, // ??????? // { articlePartId: 1738, articleViolationTypeId: 2 },
        violatorDetail: {
          occupationId: violator.occupationId,
          employmentPlace: violator.occupationPlace,
          employmentPosition: 'Нет данных',
          intoxicationTypeId: violator.intoxication,
          additionally: null, // ???????
          signature: personAdditionalData.sign,
        },
        actualAddress: {
          countryId: violator.countryId,
          regionId: violator.regionId,
          districtId: violator.districtId,
          address: violator.address,
        },
        postAddress: {
          countryId: violator.countryId,
          regionId: violator.regionId,
          districtId: violator.districtId,
          address: violator.address,
        },
      },
      additional: not(isNil(transport)) && not(isEmpty(transport)) ? {
        ubdd: {
          vehicleNumber: techPassport.vehicleNumber,
          vehiclePassSeries: techPassport.texPassSeries,
          vehiclePassNumber: techPassport.texPassNumber,
          vehicleOwnerId: techPassport.vehicleOwnerTypeId,
          vehicleBrand: techPassport.vehicleBrand,
          vehicleBodyTypeId: techPassport.vehicleBodyCode,
          vehicleColor: techPassport.vehicleColor,
          vehicleOwnerLastName: techPassport.vehicleOwnerLastName,
          vehicleOwnerFirstName: techPassport.vehicleOwnerFirstName,
          vehicleOwnerSecondName: techPassport.vehicleOwnerSecondName,
          vehicleOwnerBirthdate: techPassport.vehicleOwnerBirthDate,
          confiscatedCategoriesId: values.confiscatedCategoriesId?.map((value) => value.id),
          isAttorney: Boolean(attorney),
          // attorneySeries: attorney?.series,
          attorneyNumber: attorney?.number,
        },
      } : null,
      isJuridic: false, // ???????
    };
    const promiseTexPass = new Promise((resolve, reject) => {
      if (techPassport) {
        dispatch(createTexPass(techPassport)).then(resolve).catch(reject);
      } else {
        resolve(null);
      }
    });
    const promiseTinting = new Promise((resolve, reject) => {
      if (tinting) {
        dispatch(createTinting(tinting)).then(resolve).catch(reject);
      } else {
        resolve(null);
      }
    });
    const promiseVehicleInspection = new Promise((resolve, reject) => {
      if (vehicleInspection) {
        dispatch(createVehicleInspection(vehicleInspection)).then(resolve).catch(reject);
      } else {
        resolve(null);
      }
    });
    const promiseInsurance = new Promise((resolve, reject) => {
      if (insurances) {
        dispatch(createInsurance(insurances)).then(resolve).catch(reject);
      } else {
        resolve(null);
      }
    });
    const promiseDriverLicense = new Promise((resolve, reject) => {
      if (driverLicense) {
        dispatch(createDriverLicense(driverLicense)).then(resolve).catch(reject);
      } else {
        resolve(null);
      }
    });
    const promiseAttorney = new Promise((resolve, reject) => {
      if (attorney) {
        dispatch(createAttorney(attorney)).then(resolve).catch(reject);
      } else {
        resolve(null);
      }
    });

    // const promiseTexPass = dispatch(createTexPass(techPassport));
    // const promiseTinting = dispatch(createTinting(tinting));
    // const promiseInsurance = dispatch(createInsurance(insurances));
    // const promiseDriverLicense = dispatch(createDriverLicense(driverLicense));

    const protocol = await Promise.all([promiseTexPass, promiseTinting, promiseDriverLicense, promiseInsurance, promiseAttorney, promiseVehicleInspection])
      .then((promValues) => {
        const services = promValues.map((value) => {
          if (!value) return null;
          return value.payload.id;
        });
        const normalizer = {
          ...normalizedValues,
          ubddDataBind: {
            ubddTexPassDataId: services[0],
            ubddTintingDataId: services[1],
            ubddDrivingLicenseDataId: services[2],
            ubddInsuranceDataId: services[3],
            ubddAttorneyLetterDataId: services[4],
            ubddVehicleInspectionDataId: services[5],
            vehicleArrestId: arrest.id || null,
          },
        };
        return raid.isActive
          ? dispatch(createMainRaidProtocol(normalizer)).unwrap()
          : dispatch(createMainProtocol(normalizer)).unwrap();
      });
    const admCaseId = protocol.admCase?.id;
    await dispatch(appendDocumentsToAdmCase({ admCase: admCaseId, files: RValues(values.qualificationFiles) }));
    if (values.explanatory) {
      await dispatch(appendExplanatoryToAdmCase({ admCase: admCaseId, file: values.explanatory }));
    }
    const normalizer = {
      params: {
        isClosed: false,
        vehicleNumber: techPassport?.vehicleNumber,
      },
      body: {
        protocolId: protocol.id,
      },
    };
    await dispatch(appendWantedInfoToProtocol(normalizer));
    const caseMovement = await dispatch(calculateCaseMovement({ admCase: admCaseId }));
    if (caseMovement.type === 'SIMPLIFIED') {
      handleOpen();
      return;
    }
    toast.success(t('Протокол составлен'));
    dispatch(resetQualification());
    dispatch(resetAttorneys());
    dispatch(resetTransport());
    dispatch(resetWanted());
    dispatch(resetSelectedPerson());
    dispatch(resetAdditionalPersonInfo());
    navigate(appRoutes.Main, { replace: true });
  }, true);

  const getActualDistrict = () => {
    if (raid.isActive) return raid.districtId;
    return user.districtId ? String(user.districtId) : null;
  };
  const getActualRegion = () => {
    if (raid.isActive) return raid.regionId;
    return user.regionId ? String(user.regionId) : null;
  };

  const initialValues = {
    regionId: getActualRegion(),
    district: getActualDistrict(),
    isAgree: 'yes',
  };
  const validate = (values) => {
    const routes = {
      '/protocol-creation-form/pedestrian': false,
      '/protocol-creation-form': true,
    };
    return ({
      violationType: composeValidators(required)(values.violationType),
      district: composeValidators(required)(values.district),
      address: composeValidators(required)(values.address),
      transport: composeValidators(validateIf(routes[location.pathname])(required))(values.transport),
      violator: composeValidators(required)(values.violator),
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      validate={validate}
      subscription={{ pristine: true, submitting: true }}
    >
      {({ handleSubmit: onSubmit }) => {
        submit = onSubmit;
        return (
          <>
            <Routes>
              <Route path="/*" element={ <ProtocolCreation submit={submit} /> } />
              <Route path="/pedestrian/*" element={ <PedestrianProtocolCreation submit={submit} /> } />
            </Routes>
            <form onSubmit={onSubmit} />
            <MakeDecisionConfirmDialog
              isOpen={open}
              handleClose={handleClose}
            />
             {/* <FormSpy subscription={{ values: true }}> */}
             {/* {({ values }) => ( */}
             {/*   <pre>{JSON.stringify(values, 0, 2)}</pre> */}
             {/* )} */}
             {/* </FormSpy> */}
          </>
        );
      }}
    </Form>
  );
};

export default ProtocolCreationForm;
