import React from 'react';
// import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './AttorneysSelects.module.scss';
import SelectContent from '../../../components/SelectContent';
import AttorneysExtra from './parts/AttorneysExtra';
import AttorneysContent from './parts/AttorneysContent';
import {
  selectAttorney, useAttorneys, useAttorneysState, useSelectedAttorney,
} from '../attorneysSlice';
import Skeleton from '../../../components/Skeleton';
import { formatDate, normalizeAttorneys } from '../../../utils';
import { useSelectedTexPass } from '../../transport/transportSlice';
import Text from '../../../components/Text';

const AttorneysSelects = () => {
  const dispatch = useDispatch();
  const attorneys = useAttorneys();
  const attorneysState = useAttorneysState();
  const selectedAttorney = useSelectedAttorney();
  const techPassport = useSelectedTexPass();
  const [t] = useTranslation();
  const normalizedAttorneys = normalizeAttorneys(attorneys, techPassport?.vehicleNumber);

  const handleSelectAttorney = (attorney) => () => {
    dispatch(selectAttorney(attorney));
  };
  return attorneysState === 'pending'
    ? <Skeleton width={455} height={200} />
    : (
      <div className={styles.attorneys}>
        {
          normalizedAttorneys.length > 0
            ? normalizedAttorneys.map((attorney, i) => (
              <div className={styles.attorneysSelect} key={i}>
                <SelectContent
                  selected={selectedAttorney?.id === attorney?.id}
                  onClick={handleSelectAttorney(attorney)}
                  extra={() => (
                    <AttorneysExtra attorney={attorney} />
                  )}
                >
                  <AttorneysContent
                    fullName={[attorney.member?.lastName, attorney.member?.firstName, attorney.member?.secondName].join(' ')}
                    birthDate={formatDate(attorney.member?.birthDate)}
                    attorneySn={attorney.number}
                    attorneyGivenDate={formatDate(attorney.fromDate)}
                    attorneyExpiresDate={formatDate(attorney.toDate)}
                  />
                </SelectContent>
              </div>
            )) : (
              <Text type="body1">{t('Доверенности не найдено')}</Text>
            )
        }
      </div>
    );
};

export default AttorneysSelects;
