import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import './Fingerprint.scss';
import { useTranslation } from 'react-i18next';
import { identity } from 'ramda';

const FingerprintScanner = ({
  onScan = identity,
  finger = null,
}) => {
  const [t] = useTranslation();
  const fingerprintClasses = cn('fingerprint');

  useEffect(() => {
    window.setFinger = (base64) => {
      onScan(base64);
      // setFinger(base64);
    };
  }, []);

  const getFingerprint = () => {
    if (window.EmiInterface) {
      window.EmiInterface.scanFinger();
      return;
    }
    alert('Нет подходящего оборудования!');
  };

  return (
    <div className={fingerprintClasses} onClick={getFingerprint}>
      {
        finger
          ? <img src={finger} alt="" />
          : <p className="finger-title">{t('Нажмите для сканирования отпечатка')}</p>
      }
    </div>
  );
};

export default FingerprintScanner;
