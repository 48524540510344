import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import httpClient from '../../app/api/httpClient';
import getError from '../../app/api/getError';
import buildRequestStates from '../../app/buildRequestStates';
import routes from '../../app/api/routes';

const initialState = {
  ui: {},
  memoFileUrls: {},
};

export const downloadFileGeneratedPDF = createAsyncThunk(
  'filesSlice/downloadFileGeneratedPDF',
  async ({ src }, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(src, {
        responseType: 'blob',
      });
      const blob = response.blob || null;
      if (!blob) return null;

      const fileUrl = window.URL.createObjectURL(
        new Blob(
          [response.blob],
          { type: 'application/pdf' },
        ),
      );

      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
      link.click();

      return fileUrl;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const downloadFile = createAsyncThunk(
  'filesSlice/downloadFile',
  async ({ src }, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(src, {
        responseType: 'blob',
      });
      const blob = response.blob || null;
      if (!blob) return null;

      const fileUrl = window.URL.createObjectURL(new Blob([response.blob]));

      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
      link.click();

      return fileUrl;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const setProgress = createAction('filesSlice/setProgress');

export const getImageURL = createAsyncThunk(
  'filesSlice/getImageURL',
  async ({ src }, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(src, {
        responseType: 'blob',
      });
      const blob = response.blob || null;
      if (!blob) return null;

      return window.URL.createObjectURL(new Blob([response.blob]));
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

export const uploadFile = createAsyncThunk(
  'filesSlice/getImageURL',
  async ({ file }, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await httpClient.post(routes.saveFile(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // onUploadProgress: ((progressEvent) => {
        //   const totalLength = progressEvent.lengthComputable
        //     ? progressEvent.total
        //     : progressEvent.target.getResponseHeader('content-length')
        //       || progressEvent.target.getResponseHeader('x-decompressed-content-length');
        //   if (totalLength !== null) {
        //     dispatch(setProgress(
        //       {
        //         progress: (progressEvent.loaded * 100) / totalLength,
        //         id: containerId,
        //       },
        //     ));
        //   }
        // }),
      });
      return { data: response.data.object };
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setMemoFileUrl: (state, { payload: { src, fileUrl } }) => {
      state.memoFileUrls[src] = fileUrl;
    },
    removeMemoFileUrl: (state) => {
      state.memoFileUrls = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setProgress, (state, { payload: { id, progress } }) => {
        state.ui.progress = {
          [id]: progress,
        };
      });
    buildRequestStates(builder, downloadFileGeneratedPDF);
    buildRequestStates(builder, downloadFile);
    buildRequestStates(builder, getImageURL);
    buildRequestStates(builder, uploadFile);
  },
});

export const {
  setMemoFileUrl,
  removeMemoFileUrl,
} = filesSlice.actions;

export const useFileUploadState = () => useSelector(
  (state) => state.files.ui[uploadFile.typePrefix],
);

export const useFileRequestState = () => useSelector(
  (state) => state.files.ui[downloadFileGeneratedPDF.typePrefix],
);

export const useImageLoadingState = () => useSelector(
  (state) => state.files.ui[getImageURL.typePrefix],
);

export const useFileUploadProgress = () => useSelector(
  (state) => state.files.ui.progress,
);

export const useMemoFileUrls = (src) => useSelector(
  (state) => state.files.memoFileUrls[src],
);

export default filesSlice.reducer;
