import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ReactComponent as BadgeIcon } from '../../icons/badge2.svg';
import styles from './DriveLicenseCard.module.scss';

const DriverLicenseCard = ({
  status = '',
  type,
  serial,
  issuedBy,
  categories,
  dateFrom,
  dateTo,
}) => {
  const [t] = useTranslation();

  const statusClasses = cn({
    [styles.licenseCard]: true,
    [styles.active]: status === 'active',
    [styles.overdue]: status === 'overdue',
    [styles.absent]: status === 'absent',
  });

  const getCategories = () => {
    const result = categories.map((item) => item.category);
    return result.join(',');
  };

  return (
    <div className={statusClasses}>
      <div className={styles.header}>
        <div className={styles.licenseType}>
          <BadgeIcon />
          <span style={{ marginLeft: '4px' }}>{type}</span>
        </div>
        <div className={styles.licenseStatus}>{status}</div>
      </div>
      {
        status !== 'absent'
          ? (
            <>
              <div className={styles.serials}>
                <div className={styles.serial}>{serial}</div>
                <div className={styles.issuedBy}>{issuedBy}</div>
              </div>
              <div className={styles.dates}>
                <div className={styles.dateFrom}>
                  <span className={styles.dateToLabel}>{t('От')}:</span>
                  {' '}
                  <span className={styles.dateToBody}>{dateFrom}</span>
                </div>
                <div className={styles.dateTo}>
                  <span className={styles.dateToLabel}>{t('До')}:</span>
                  {' '}
                  <span className={styles.dateToBody}>{dateTo}</span>
                </div>
                <div>
                  <span className={styles.dateToLabel}>{t('Категория')}:</span>
                  {' '}
                  <span className={styles.dateToBody}>
                    {getCategories()}
                  </span>
                </div>
              </div>
            </>
          ) : null
      }
    </div>
  );
};

export default DriverLicenseCard;
