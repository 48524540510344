import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import mock from './mock';
import getError from '../../app/api/getError';
import httpClient from '../../app/api/httpClient';
import routes from '../../app/api/routes';
import buildRequestStates from '../../app/buildRequestStates';
import useDecoratedHandle from '../../hooks/useDecoratedHandle';
import { getMyProtocolList } from '../protocolList/protocolListSlice';

const initialState = {
  myDecisions: {
    list: [],
    pagination: {},
  },
  ui: {},
};

const getMyDecisionList = createAsyncThunk(
  'decisionList/getMyDecisionList',
  async (params, { rejectWithValue }) => {
    try {
      const response = await httpClient.get(routes.myDecisions(), { params });
      return response.data;
    } catch (e) {
      return rejectWithValue(getError(e));
    }
  },
);

const decisionListSlice = createSlice({
  name: 'decisionList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyDecisionList.fulfilled, (state, { payload: data }) => {
      state.myDecisions.list = data.objects;
      state.myDecisions.pagination = data.pagination;
    });
    buildRequestStates(builder, getMyDecisionList);
  },
});

export const useDecisionList = (params) => {
  const dispatch = useDispatch();

  const getMyDecisions = useDecoratedHandle(async (param) => {
    await dispatch(getMyDecisionList(param)).unwrap();
  });

  const list = useSelector((state) => state.decisionList.myDecisions);
  const requestState = useSelector((state) => state.decisionList.ui[getMyDecisionList.typePrefix]);

  useEffect(() => {
    getMyDecisions(params);
  }, [params.page, params.userId, params.size]);
  return [list, requestState === 'pending'];
};

export default decisionListSlice.reducer;
