import React from 'react';
// import cn from 'classnames';

import styles from './Divider.module.scss';

const Divider = ({
  mt = 1,
  mb = 1,
  color = 'rgba(24, 32, 63, 0.2)',
}) => {
  const margins = {
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
  };

  return (
    <div
      className={styles.divider}
      style={{
        marginTop: margins[mt],
        marginBottom: margins[mb],
        backgroundColor: color,
      }}
    />
  );
};

export default Divider;
