import React from 'react';
// import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import styles from './ProtocolCreation.module.scss';
import TopBar from '../../components/TopBar';
import { useAppRoutes } from '../app/appSlice';
import { ReactComponent as Trash } from '../../icons/trash.svg';
import Button from '../../components/Button';
import Body from '../../components/Body';
import ConfirmDialog from '../../components/ConfirmDialog';
import { resetWanted } from '../wanted/wantedSlice';
import { resetTransport } from '../transport/transportSlice';
import { resetAttorneys } from '../attorneys/attorneysSlice';
import {
  resetCreatedProtocol,
  resetQualification,
  useCreateMainProtocolState,
} from './protocolCreationsSlice';
import {
  resetAdditionalPersonInfo,
  resetForm1,
  resetForm1List,
  resetSelectedPerson,
} from '../person/personSlice';
import TransportRouter from '../transport/TransportRouter';
import ViolatorRouter from '../person/ViolatorRouter';
import QualificationContainer from './QualificationContainer';
import { ReactComponent as SaveIcon } from '../../icons/save.svg';

const ProtocolCreation = ({ submit }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const form = useForm();

  const handleClickReset = () => {
    dispatch(resetWanted());
    dispatch(resetTransport());
    dispatch(resetAttorneys());
    dispatch(resetQualification());
    dispatch(resetCreatedProtocol());
    dispatch(resetSelectedPerson());
    dispatch(resetForm1List());
    dispatch(resetForm1());
    dispatch(resetAdditionalPersonInfo());
    form.reset();
  };

  const handleClickBack = () => {
    navigate(appRoutes.Main);
  };

  const handleTransportCreation = (values) => {
    form.change('transport', values);
  };

  const handleViolatorCreation = (values) => {
    form.change('violator', values);
  };

  return (
    <>
      <TopBar
        title={t('Добавить протокол')}
        onBack={handleClickBack}
        extra={() => (
          <ConfirmDialog
            confirmText={t('Вы действительно хотите очистить?')}
            onConfirm={handleClickReset}
            renderButton={(onClick) => (
              <Button
                type="button"
                variant="textWhite"
                onClick={onClick}
                prependIcon={<Trash />}
              >
                {t('Очистить')}
              </Button>
            )}
          />
        )}
      />
      <Body>
        <div className={styles.protocol}>
           <TransportRouter
            onChange={handleTransportCreation}
           />
           <ViolatorRouter
            onChange={handleViolatorCreation}
           />
            <QualificationContainer
             submit={submit}
            />
        </div>
      </Body>
    </>
  );
};

export default ProtocolCreation;
